import { Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { FC } from 'react'

import { Step } from '~/model/account/step'

interface Props {
  step: Step
}

const StyledImg = styled('img')({
  width: '112px',
  height: '112px',
})

const StepDisplayItem: FC<Props> = ({ step }) => {
  return (
    <Stack>
      <Typography>hoge</Typography>
      <Typography>hoge</Typography>
      <Grid container spacing={1.5}>
        {step.imageUrlList.map((url, index) => (
          <Grid key={index.toString()} item>
            <StyledImg src={url} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

export default StepDisplayItem
