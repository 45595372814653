import { styled, TextField, TextFieldProps } from '@mui/material'
import { FC } from 'react'

const StyledInputText = styled(TextField)({
  ['& fieldset']: {
    borderRadius: '8px',
  },
})

const InputText: FC<TextFieldProps> = (props) => {
  return <StyledInputText {...props} variant="outlined" fullWidth />
}

export default InputText
