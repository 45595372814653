import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import PlanItem from '~/components/molecules/planItem'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Plan } from '~/model/plan/plan'
import { getPlans } from '~/repositories/planRepository'

const PlansBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [guidePlanList, setGuidePlanList] = useState<Plan[]>([])

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const plans = await getPlans(currentUser.user.uid)
          setGuidePlanList(plans)
          console.log(plans)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          console.log(e)
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  return (
    <Stack spacing={1.5} width="100%">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant={isDesktop ? 'h1' : 'h2'}>プラン一覧</Typography>
        <Link to="add">
          <Typography
            variant="button"
            component="div"
            bgcolor="primary.main"
            textAlign="center"
            height={isDesktop ? '40px' : '32px'}
            lineHeight={isDesktop ? '40px' : '32px'}
            width="140px"
            borderRadius="8px"
          >
            +プランを登録
          </Typography>
        </Link>
      </Stack>
      <Stack divider={<Divider />}>
        {guidePlanList
          .sort((a, b) => a.name > b.name ? 1 : -1)
          .map((plan) => (
            <PlanItem plan={plan} />
          ))}
      </Stack>
    </Stack>
  )
}

export default PlansBody
