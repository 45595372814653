import styled from '@emotion/styled'
import { Button, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'

import InputItem from '~/components/atoms/inputItem'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as PlusIcon } from '~/components/svgs/plus.svg'
import { Item } from '~/model/account/item'
import {
  updateGuideItemList,
  updateGuideRentalList,
} from '~/repositories/guideRepository'

interface OwnProps {
  items: Item[]
  type: 'rental' | 'sale'
  onUpdated?: (itemList: Item[]) => void
}

type Props = OwnProps & EditableSectionProps

const StyledPlusIcon = styled(PlusIcon)({
  ['path:first-child']: {
    fill: '#00A3FF',
  },
})

const SectionProfileItems: FC<Props> = ({
  items,
  type,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [currentItems, setCurrentItems] = useState(items)

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      if (type === 'rental') {
        await updateGuideRentalList(currentUser.user.uid, currentItems)
      }
      if (type === 'sale') {
        await updateGuideItemList(currentUser.user.uid, currentItems)
      }
      onUpdated?.(currentItems)
      toggleAlert(
        true,
        'success',
        type === 'rental'
          ? 'レンタル品を更新しました！'
          : '現地販売品を更新しました！'
      )
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  const handleNameChange = (index: number, name: string) => {
    setCurrentItems(
      currentItems.map((item, i) =>
        i === index ? { name: name, price: item.price } : item
      )
    )
  }
  const handlePriceChange = (index: number, price: number) => {
    setCurrentItems(
      currentItems.map((item, i) =>
        i === index ? { name: item.name, price: price } : item
      )
    )
  }
  const handleRemove = (index: number) => {
    setCurrentItems(currentItems.filter((_, i) => i !== index))
  }
  const handleAdd = () => {
    setCurrentItems([...currentItems, { name: '', price: 0 }])
  }
  const formatedPrice = (price?: number): string => {
    if (!price) return '無料'
    return `${price.toLocaleString()}円`
  }
  return (
    <EditableSectionContainer
      title={type === 'rental' ? 'レンタル品' : '現地販売品'}
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentItems(items)
        onClickMode?.()
      }}
    >
      {!editing &&
        items.map((item, index) => (
          <Typography
            key={index.toString()}
            variant="subtitle2"
            color="secondary.main"
            component="span"
          >
            {`${item.name} - ${formatedPrice(item.price)}`}
          </Typography>
        ))}
      {editing && (
        <Stack alignItems="flex-start" spacing={2}>
          {currentItems.map((item, index) => (
            <InputItem
              item={item}
              onChangeName={(name) => handleNameChange(index, name)}
              onChangePrice={(price) => handlePriceChange(index, price)}
              onRemove={() => handleRemove(index)}
            />
          ))}
          <Button startIcon={<StyledPlusIcon />} onClick={handleAdd}>
            <Typography variant="h5" color="primary.main">
              追加する
            </Typography>
          </Button>
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileItems
