import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'

import { Access } from '~/model/account/access'
import { DestinationType } from '~/model/account/destinationType'
import { GuestContactMethod } from '~/model/account/guestContactMethod'
import { FirestoreGuide, Guide } from '~/model/account/guide'
import { Item } from '~/model/account/item'
import { CollectionPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getPermitted = async (uid: string) => {
  const docRef = doc(firestore, CollectionPath.PermittedGuides, uid)
  const docSnap = await getDoc(docRef)
  return docSnap.exists()
}

export const getGuide = async (uid: string) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const guide: Guide = { ...snap.data(), documentId: snap.id }
    return guide
  } else {
    throw new Error('Document not found.')
  }
}

export const updateGuideName = async (uid: string, newName: string) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, { name: newName, updatedAt: serverTimestamp() })
}

export const updateGuideImageUrlList = async (
  uid: string,
  newImageUrlList: string[]
) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    imageUrlList: newImageUrlList,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuideAboutUs = async (uid: string, newAboutUs: string) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, { aboutUs: newAboutUs, updatedAt: serverTimestamp() })
}

export const updateGuideNotes = async (uid: string, notes: string) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, { notes: notes, updatedAt: serverTimestamp() })
}

export const updateGuideYouTubeVideoId = async (
  uid: string,
  videoId: string
) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    youtubeVideoId: videoId,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuidePhone = async (uid: string, phone: string) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    phone: phone,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuideDestinationType = async (
  uid: string,
  type: DestinationType,
  fax?: string
) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    destinationType: type,
    fax: fax,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuideGuestContactMethodList = async (
  uid: string,
  guestContactMethodList: GuestContactMethod[]
) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    guestContactMethodList: guestContactMethodList,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuideBookingQuestions = async (
  uid: string,
  bookingQuestions: string
) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    bookingQuestions: bookingQuestions,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuideRentalList = async (uid: string, rentals: Item[]) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    rentalList: rentals,
    updatedAt: serverTimestamp(),
  })
}

export const updateGuideItemList = async (uid: string, items: Item[]) => {
  const ref = doc(firestore, CollectionPath.Guides, uid).withConverter(
    converter<FirestoreGuide>()
  )

  await updateDoc(ref, {
    itemList: items,
    updatedAt: serverTimestamp(),
  })
}
