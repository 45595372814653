import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import SectionProfileAccess from '~/components/molecules/accessSections/sectionProfileAccess'
import SectionProfileBookingQuestions from '~/components/molecules/profileSections/sectionProfileBookingQuestions'
import SectionProfileImages from '~/components/molecules/profileSections/sectionProfileImages'
import SectionProfileIntroduction from '~/components/molecules/profileSections/sectionProfileIntroduction'
import SectionProfileItems from '~/components/molecules/profileSections/sectionProfileItems'
import SectionProfileName from '~/components/molecules/profileSections/sectionProfileName'
import SectionProfileNote from '~/components/molecules/profileSections/sectionProfileNote'
import SectionProfileUrl from '~/components/molecules/profileSections/sectionProfileUrl'
import SectionProfileYouTube from '~/components/molecules/profileSections/sectionProfileYouTube'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Guide } from '~/model/account/guide'
import { getGuide } from '~/repositories/guideRepository'

const AccountProfileBody: FC = () => {
  const [editMode, setEditMode] = useState<
    | 'name'
    | 'images'
    | 'bookingQuestions'
    | 'introduction'
    | 'note'
    | 'youtube'
    | 'equipment'
    | 'access'
    | 'rental'
    | 'sale'
  >()
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [guide, setGuide] = useState<Guide>()

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const guide = await getGuide(currentUser.user.uid)
          setGuide(guide)
          console.log(guide)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  if (guide) {
    return (
      <Stack
        flex={1}
        spacing={isDesktop ? '24px' : '16px'}
        divider={<Divider />}
        width="100%"
        overflow="hidden"
      >
        <SectionProfileUrl guideId={guide.documentId} />
        <SectionProfileName
          name={guide.name}
          editing={editMode === 'name'}
          disabled={editMode && editMode !== 'name'}
          onClickMode={() =>
            setEditMode(editMode === 'name' ? undefined : 'name')
          }
          onUpdated={(newName) => {
            setEditMode(undefined)
            setGuide({ ...guide, name: newName })
          }}
        />
        <SectionProfileImages
          images={guide.imageUrlList}
          limit={50}
          editing={editMode === 'images'}
          disabled={editMode && editMode !== 'images'}
          onClickMode={() =>
            setEditMode(editMode === 'images' ? undefined : 'images')
          }
          onUpdated={(newImages) => {
            setEditMode(undefined)
            setGuide({ ...guide, imageUrlList: newImages })
          }}
        />
        <SectionProfileBookingQuestions
          bookingQuestions={guide.bookingQuestions}
          editing={editMode === 'bookingQuestions'}
          disabled={editMode && editMode !== 'bookingQuestions'}
          onClickMode={() =>
            setEditMode(
              editMode === 'bookingQuestions' ? undefined : 'bookingQuestions'
            )
          }
          onUpdated={(newBookingQuestions) => {
            setEditMode(undefined)
            setGuide({ ...guide, bookingQuestions: newBookingQuestions })
          }}
        />
        <SectionProfileIntroduction
          aboutUs={guide.aboutUs}
          editing={editMode === 'introduction'}
          disabled={editMode && editMode !== 'introduction'}
          onClickMode={() =>
            setEditMode(
              editMode === 'introduction' ? undefined : 'introduction'
            )
          }
          onUpdated={(newAboutUs) => {
            setEditMode(undefined)
            setGuide({ ...guide, aboutUs: newAboutUs })
          }}
        />
        <SectionProfileNote
          note={guide.notes}
          editing={editMode === 'note'}
          disabled={editMode && editMode !== 'note'}
          onClickMode={() =>
            setEditMode(editMode === 'note' ? undefined : 'note')
          }
          onUpdated={(newNotes) => {
            setEditMode(undefined)
            setGuide({ ...guide, notes: newNotes })
          }}
        />
        <SectionProfileYouTube
          youtubeId={guide.youtubeVideoId}
          editing={editMode === 'youtube'}
          disabled={editMode && editMode !== 'youtube'}
          onClickMode={() =>
            setEditMode(editMode === 'youtube' ? undefined : 'youtube')
          }
          onUpdated={(newVideoId) => {
            setEditMode(undefined)
            setGuide({ ...guide, youtubeVideoId: newVideoId })
          }}
        />
        {/* <SectionProfileEquipments
          equipmentList={guide.equipmentList}
          editing={editMode === 'equipment'}
          disabled={editMode && editMode !== 'equipment'}
          onClickMode={() =>
            setEditMode(editMode === 'equipment' ? undefined : 'equipment')
          }
          onUpdated={(newEquipmentList) => {
            setEditMode(undefined)
            setGuide({ ...guide, equipmentList: newEquipmentList })
          }}
        /> */}
        <SectionProfileItems
          items={guide.rentalList}
          type="rental"
          editing={editMode === 'rental'}
          disabled={editMode && editMode !== 'rental'}
          onClickMode={() =>
            setEditMode(editMode === 'rental' ? undefined : 'rental')
          }
          onUpdated={(newRentalList) => {
            setEditMode(undefined)
            setGuide({ ...guide, rentalList: newRentalList })
          }}
        />
        <SectionProfileItems
          items={guide.itemList}
          type="sale"
          editing={editMode === 'sale'}
          disabled={editMode && editMode !== 'sale'}
          onClickMode={() =>
            setEditMode(editMode === 'sale' ? undefined : 'sale')
          }
          onUpdated={(newItemList) => {
            setEditMode(undefined)
            setGuide({ ...guide, itemList: newItemList })
          }}
        />
        {/* <SectionProfileAccess
          access={guide.access}
          editing={editMode === 'access'}
          disabled={editMode && editMode !== 'access'}
          onClickMode={() =>
            setEditMode(editMode === 'access' ? undefined : 'access')
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setGuide({ ...guide, access: newAccess })
          }}
        /> */}
      </Stack>
    )
  } else {
    return (
      <Typography
        variant="subtitle1"
        component="span"
        color="secondary.main"
        flex={1}
        textAlign="center"
      >
        このアカウントは現在準備中です
      </Typography>
    )
  }
}

export default AccountProfileBody
