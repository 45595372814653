import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import BoardingProcedureItem from '~/components/molecules/boardingProcedureItem'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { BoardingProcedure } from '~/model/account/boardingProcedure'
import { getBoardingProcedures } from '~/repositories/boardingProcedureRepository'

const BoardingProceduresBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [guideBoardingProcedureList, setGuideBoardingProcedureList] = useState<
    BoardingProcedure[]
  >([])

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const boardingProcedures = await getBoardingProcedures(
            currentUser.user.uid
          )
          setGuideBoardingProcedureList(boardingProcedures)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          console.log(e)
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  return (
    <Stack spacing={1.5} width="100%">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant={isDesktop ? 'h1' : 'h2'}>乗船手順</Typography>
        <Link to="add">
          <Typography
            variant="button"
            component="div"
            bgcolor="primary.main"
            textAlign="center"
            height={isDesktop ? '40px' : '32px'}
            lineHeight={isDesktop ? '40px' : '32px'}
            width="140px"
            borderRadius="8px"
          >
            +乗船手順を登録
          </Typography>
        </Link>
      </Stack>
      <Stack divider={<Divider />}>
        {guideBoardingProcedureList.map((boardingProcedure) => (
          <BoardingProcedureItem boardingProcedure={boardingProcedure} />
        ))}
      </Stack>
    </Stack>
  )
}

export default BoardingProceduresBody
