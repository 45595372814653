export const UPLOAD_TYPE = {
  icon: 'icon',
  profile: 'profile',
  plan: 'plan',
  access: 'access',
  boat: 'boat',
  tackle: 'tackle',
} as const

export type UploadType = typeof UPLOAD_TYPE[keyof typeof UPLOAD_TYPE]
