import {
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useMemo } from 'react'

import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as CopyLogo } from '~/components/svgs/content_copy.svg'

interface Props {
  guideId: string
}

const SectionProfileUrl: FC<Props> = ({ guideId }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const guideUrl = useMemo(
    () => `https://tsuritori.jp/guides/${guideId}`,
    [currentUser]
  )
  return (
    <Stack spacing={isDesktop ? 2 : 1.5}>
      <Typography variant={isDesktop ? 'h2' : 'h4'} component="h2">
        ページURL
      </Typography>
      <Stack spacing={2} direction="row" alignItems="center">
        <Link href={guideUrl} target="_blank">
          <Typography
            variant="subtitle2"
            component="div"
            color="primary.main"
            sx={{ wordBreak: 'break-all' }}
          >
            {guideUrl}
          </Typography>
        </Link>
        <IconButton>
          <CopyLogo />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default SectionProfileUrl
