export interface HelpSectionModel {
  id: string
  title: string
  articles: { id: string; title: string }[]
}

export const sections: HelpSectionModel[] = [
  {
    id: '1',
    title: '予約',
    articles: [
      { id: '1', title: 'リクエスト予約について' },
      { id: '2', title: '予約から当日までの流れ' },
      { id: '3', title: '予約確定後のゲストとの連絡方法について' },
      { id: '4', title: '予約制限について' },
    ],
  },
  {
    id: '2',
    title: 'ルール・ガイドライン',
    articles: [
      { id: '1', title: 'ガイド向けガイドライン' },
      { id: '3', title: '安全対策のためのガイドライン' },
      { id: '4', title: '車での送迎などについて' },
      { id: '5', title: '釣り体験開催における注意事項' },
      { id: '6', title: '法律上の禁止事項' },
    ],
  },
  {
    id: '3',
    title: '売上',
    articles: [
      { id: '1', title: '確定申告について' },
      { id: '2', title: '口座情報の変更方法' },
      { id: '3', title: '売上を確認する方法' },
      { id: '4', title: '売上金が反映されるタイミングについて' },
      { id: '6', title: '売上金の支払いについて' },
    ],
  },
  {
    id: '4',
    title: 'アカウント',
    articles: [
      { id: '2', title: 'プロフィール情報を更新する' },
      { id: '3', title: 'ログイン方法' },
      { id: '4', title: '退会について' },
    ],
  },
  {
    id: '5',
    title: '釣りプラン',
    articles: [
      { id: '1', title: '釣りプランの設定項目' },
      { id: '2', title: '釣りプランを更新する' },
      { id: '3', title: '釣りプランを削除する' },
      { id: '4', title: '釣りプランを追加する' },
      { id: '5', title: '料金設定について' },
    ],
  },
  {
    id: '6',
    title: 'キャンセル',
    articles: [
      { id: '1', title: 'ガイドの予約キャンセルについて' },
      { id: '2', title: 'キャンセルポリシーについて' },
      { id: '3', title: 'ゲストがキャンセルした場合' },
      { id: '4', title: '酌量すべき事情について' },
    ],
  },
  {
    id: '7',
    title: 'カレンダー',
    articles: [
      { id: '1', title: 'カレンダー情報を更新する' },
      { id: '2', title: '割引を適用する' },
    ],
  },
  {
    id: '8',
    title: 'その他',
    articles: [{ id: '1', title: 'メールなどが届かない場合' }],
  },
]
