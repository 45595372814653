import { FC } from 'react'

import Container from '~/components/organisms/container'
import ContentContainer from '~/components/organisms/contentContainer'
import Footer from '~/components/organisms/footer'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import { ReactComponent as Logo } from '~/components/svgs/tsuritori_404.svg'

const NotFound: FC = () => {
  return (
    <Container>
      <Head title="ページが見つかりませんでした" />
      <Header />
      <ContentContainer>
        <Logo width="100%" />
      </ContentContainer>
      <Footer />
    </Container>
  )
}

export default NotFound
