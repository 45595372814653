import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import FishingSeatProcedureItem from '~/components/molecules/fishingSeatProcedureItem'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { FishingSeatProcedure } from '~/model/account/fishingSeatProcedure'
import { getFishingSeatProcedures } from '~/repositories/fishingSeatProcedureRepository'

const FishingSeatProceduresBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [guideFishingSeatProcedureList, setGuideFishingSeatProcedureList] =
    useState<FishingSeatProcedure[]>([])

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const fishingSeatProcedures = await getFishingSeatProcedures(
            currentUser.user.uid
          )
          setGuideFishingSeatProcedureList(fishingSeatProcedures)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  return (
    <Stack spacing={1.5} width="100%">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant={isDesktop ? 'h1' : 'h2'}>
          釣り座決定一覧
        </Typography>
        <Link to="add">
          <Typography
            variant="button"
            component="div"
            bgcolor="primary.main"
            textAlign="center"
            height={isDesktop ? '40px' : '32px'}
            lineHeight={isDesktop ? '40px' : '32px'}
            width="140px"
            borderRadius="8px"
          >
            +釣り座決定を登録
          </Typography>
        </Link>
      </Stack>
      <Stack divider={<Divider />}>
        {guideFishingSeatProcedureList.map((fishingSeatProcedure) => (
          <FishingSeatProcedureItem
            fishingSeatProcedure={fishingSeatProcedure}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default FishingSeatProceduresBody
