import { TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'

import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { updateGuideBookingQuestions } from '~/repositories/guideRepository'

interface OwnProps {
  bookingQuestions?: string
  onUpdated?: (bookingQuestions: string) => void
}

type Props = OwnProps & EditableSectionProps

const SectionProfileBookingQuestions: FC<Props> = ({
  bookingQuestions,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [currentBookingQuestions, setCurrentBookingQuestions] =
    useState(bookingQuestions)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      await updateGuideBookingQuestions(
        currentUser.user.uid,
        currentBookingQuestions || ''
      )
      onUpdated?.(currentBookingQuestions || '')
      toggleAlert(true, 'success', '予約者への事前質問を更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  // 改行コード周りの知見
  // https://qiita.com/gkn/items/f031bab66899e5886ce0
  return (
    <EditableSectionContainer
      title="予約者への事前質問"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentBookingQuestions(bookingQuestions)
        onClickMode?.()
      }}
    >
      {!editing && (
        <Typography
          variant="subtitle2"
          component="div"
          color="secondary.main"
          whiteSpace="pre-wrap"
        >
          {bookingQuestions?.replaceAll('\\n', '\n')}
        </Typography>
      )}
      {editing && (
        <TextField
          value={currentBookingQuestions?.replaceAll('\\n', '\n')}
          placeholder={
            '例 ）\n1. 当船のご利用は初めてですか？\n2. 車でお越しになりますか？\n3. レンタルタックルは何本必要ですか？'
          }
          onChange={(e) =>
            setCurrentBookingQuestions(e.target.value.replaceAll('\n', '\\n'))
          }
          variant="outlined"
          multiline
          rows={5}
          sx={{
            ['& fieldset']: { borderRadius: '8px' },
            width: '100%',
          }}
        />
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileBookingQuestions
