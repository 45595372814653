import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const SelectCheckSection: FC<Props> = ({
  register,
  setValue,
  getValues,
  title,
}) => {
  const [isPublished, setIsPublished] = useState(getValues('isPublished'))
  const isPublishedReg = register('isPublished', { required: false })

  useEffect(() => {
    setValue('isPublished', isPublished)
  }, [isPublished])

  return (
    <SectionContainer title={title}>
      <FormControlLabel
        control={
          <Checkbox
            inputRef={isPublishedReg.ref}
            color="success"
            checked={isPublished}
            onChange={(e) => {
              setIsPublished(e.target.checked)
            }}
          />
        }
        label={
          <Typography variant="h5" component="span">
            公開する
          </Typography>
        }
      />
    </SectionContainer>
  )
}

export default SelectCheckSection
