import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'

import InputText from '~/components/atoms/inputText'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { DestinationType } from '~/model/account/destinationType'
import { updateGuideDestinationType } from '~/repositories/guideRepository'

interface OwnProps {
  destinationType: DestinationType
  email: string
  fax?: string
  onUpdated?: (destinationType: DestinationType, fax?: string) => void
}

type Props = OwnProps & EditableSectionProps

const SectionReservationAddress: FC<Props> = ({
  destinationType,
  email,
  fax,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [currentDestinationType, setCurrentDestinationType] =
    useState<DestinationType>(destinationType)
  const [currentFax, setCurrentFax] = useState(fax)

  const sendMethodText = useMemo<string>(() => {
    switch (destinationType) {
      case 'email':
        return 'メール'
      case 'fax':
        return 'ファックス'
    }
  }, [destinationType])

  const sendAddress = useMemo(() => {
    switch (destinationType) {
      case 'email':
        return email
      case 'fax':
        return fax
    }
  }, [destinationType])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDestinationType(
      (event.target as HTMLInputElement).value as DestinationType
    )
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (currentDestinationType) {
      case 'email':
        break
      case 'fax':
        setCurrentFax(event.target.value)
        break
    }
  }

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      // emailの場合は、fax情報を更新しない（削除も特にはしない）
      const newFax = currentDestinationType === 'email' ? fax : currentFax
      await updateGuideDestinationType(
        currentUser.user.uid,
        currentDestinationType,
        newFax
      )
      onUpdated?.(currentDestinationType, newFax)
      toggleAlert(true, 'success', 'ご予約情報の送信先を更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  return (
    <EditableSectionContainer
      title="ご予約情報の送信先"
      description="予約が確定した際に、メール又はFAX のいずれかで予約に関する詳細情報を確認のため送信いたします。"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentDestinationType(destinationType)
        setCurrentFax(fax)
        onClickMode?.()
      }}
    >
      {!editing && (
        <Typography
          variant="subtitle2"
          component="span"
          color="secondary.main"
          whiteSpace="pre-wrap"
        >
          {`送信方法:  ${sendMethodText}\n送信先:  ${sendAddress ?? '未設定'}`}
        </Typography>
      )}
      {editing && (
        <Stack spacing={3}>
          <RadioGroup
            defaultValue={currentDestinationType}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="email"
              control={<Radio color="success" />}
              label={
                <Typography
                  variant="subtitle2"
                  color="secondary.main"
                  component="span"
                >
                  {`メール（${email}）`}
                </Typography>
              }
            />
            <FormControlLabel
              value="fax"
              control={<Radio color="success" />}
              label={
                <Typography
                  variant="subtitle2"
                  color="secondary.main"
                  component="span"
                >
                  ファックス
                </Typography>
              }
            />
          </RadioGroup>
          {currentDestinationType === 'fax' && (
            <InputText
              label="ファックス番号"
              size="small"
              value={currentFax}
              onChange={handleAddressChange}
            />
          )}
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionReservationAddress
