import {
  Backdrop,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import BasicButton from '~/components/atoms/basicButton'
import Loading from '~/components/atoms/loading'
import InputEquipmentSection from '~/components/molecules/boatSections/inputEquipmentSection'
import InputBoatImagesSection from '~/components/molecules/boatSections/inputImageSection'
import InputBoatMultilineTextSection from '~/components/molecules/boatSections/inputMultilineTextSection'
import InputBoatNameSection from '~/components/molecules/boatSections/inputTextSection'
import BreadcrumbList from '~/components/molecules/breadcrumbList'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { BoatBase } from '~/model/account/boat'
import { addBoat, getBoat, updateBoat } from '~/repositories/boatRepository'

interface Props {
  // boatIdがある場合は編集モード
  boatId?: string
}

const BoatsWriteBody: FC<Props> = ({ boatId }) => {
  const [isWriting, setIsWriting] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const form = useForm<BoatBase>()
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const navigate = useNavigate()

  const title = useMemo(() => (boatId ? '船を編集' : '船を追加'), [boatId])

  useEffect(() => {
    if (!currentUser) return
    // 初期化処理
    if (boatId) {
      // 編集画面
      const cleanup = async () => {
        const boat = await getBoat(currentUser.user.uid, boatId)
        form.setValue('name', boat.name)
        form.setValue('description', boat.description)
        form.setValue('imageUrlList', boat.imageUrlList)
        form.setValue('equipmentList', boat.equipmentList)
        setInitialized(true)
      }
      cleanup()
    } else {
      // 追加画面
      form.setValue('name', '')
      form.setValue('imageUrlList', [])
      form.setValue('equipmentList', [])
      setInitialized(true)
    }
  }, [boatId])

  const onSubmit: SubmitHandler<BoatBase> = async (data) => {
    if (!currentUser) return
    setIsWriting(true)
    try {
      if (boatId) {
        await updateBoat(currentUser.user.uid, boatId, data)
      } else {
        await addBoat(currentUser.user.uid, data)
        navigate('/boats', { replace: true })
      }
      toggleAlert(
        true,
        'success',
        boatId ? '船を更新しました！' : '船を追加しました！'
      )
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsWriting(false)
    console.log(data)
  }

  return (
    <Stack width="100%" spacing={1.5}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWriting}
      >
        <Loading width={150} height={150} />
      </Backdrop>
      {initialized && (
        <Stack spacing={4}>
          <BreadcrumbList
            items={[{ name: '船一覧', to: '/boats' }, { name: '船を追加' }]}
          />
          <Stack component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Typography variant={isDesktop ? 'h1' : 'h2'}>{title}</Typography>
            <InputBoatNameSection
              {...form}
              title="船の名前"
              placeholder="第一山女魚丸"
            />
            <Divider />
            <InputBoatImagesSection {...form} title="船の画像" limit={20} />
            <Divider />
            <InputBoatMultilineTextSection
              {...form}
              title="船の説明/紹介"
              placeholder="船の特徴や説明などをご自由にご入力ください。"
            />
            <Divider />
            <InputEquipmentSection {...form} title="船の設備" />
            <Stack
              paddingTop="24px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <BasicButton
                type="reset"
                title="キャンセル"
                color="info"
                sx={{ width: '140px' }}
                onClick={() => navigate('/boats', { replace: true })}
              />
              <BasicButton
                type="submit"
                title={boatId ? '更新する' : '登録する'}
                sx={{ width: '220px' }}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default BoatsWriteBody
