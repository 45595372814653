import { Stack, Typography } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SelectedMenu from '~/components/atoms/selectedMenu'
import { BankAccountBase } from '~/model/bankAccount/bankAccount'
import {
  BANK_ACCOUNT_TYPE,
  BankAccountType,
} from '~/model/bankAccount/bankAccountType'

interface OwnProps {
  title: string
  bankAccountType: BankAccountType
}

type Props = UseFormReturn<BankAccountBase> & OwnProps

const options = ['普通預金', '当座預金']
const SectionSelectBankAccountType: FC<Props> = ({
  register,
  setValue,
  formState: { errors },
  clearErrors,
  title,
  bankAccountType,
}) => {
  const [accountType, setAccountType] = useState(bankAccountType)

  useEffect(() => {
    setValue('accountType', accountType)
    clearErrors('accountType')
  }, [accountType])
  useEffect(() => {
    register('accountType', { required: true, pattern: /saving|checking/ })
  }, [])

  const selectedIndex = useMemo(() => {
    return Object.values(BANK_ACCOUNT_TYPE).indexOf(accountType)
  }, [accountType])
  const handleChange = (index: number) => {
    const object = Object.values(BANK_ACCOUNT_TYPE)
    setAccountType(object[index])
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <SelectedMenu
        options={options}
        selectedIndex={selectedIndex}
        onChangeSelected={handleChange}
        error={Boolean(errors.accountType)}
      />
    </Stack>
  )
}

export default SectionSelectBankAccountType
