import { Stack } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import SelectedMenu from '~/components/atoms/selectedMenu'
import { BOARDING_TYPE, BoardingType } from '~/model/plan/boardingType'
import { PlanBase } from '~/model/plan/plan'
import {
  RESERVABLE_RANGE_TYPE,
  ReservableRangeType,
} from '~/model/plan/reservableRangeType'

interface OwnProps {
  type:
    | 'boarding_type'
    | 'reservable_range_type'
    | 'fishing_seat_procedure'
    | 'boarding_procedure'
  options: string[]
  values: string[]
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const SelectMenuSection: FC<Props> = ({
  register,
  setValue,
  getValues,
  formState: { errors },
  clearErrors,
  type,
  options,
  values,
  title,
}) => {
  const [boardingType, setBoardingType] = useState<BoardingType>(
    getValues('boardingType')
  )
  const [reservableRangeType, setReservableRangeType] =
    useState<ReservableRangeType>(getValues('reservableRangeType'))
  const [fishingSeatProcedureId, setFishingSeatProcedureId] = useState<
    string | undefined
  >(getValues('fishingSeatProcedureId'))
  const [boardingProcedureId, setBoardingProcedureId] = useState<
    string | undefined
  >(getValues('boardingProcedureId'))

  useEffect(() => {
    switch (type) {
      case 'boarding_type':
        register('boardingType', { required: true })
        break
      case 'reservable_range_type':
        register('reservableRangeType', { required: true })
        break
      case 'fishing_seat_procedure':
        register('fishingSeatProcedureId', { required: false })
        break
      case 'boarding_procedure':
        register('boardingProcedureId', { required: false })
        break
    }
  }, [])

  const handleChange = (index: number) => {
    switch (type) {
      case 'boarding_type': {
        const object = Object.values(BOARDING_TYPE)
        setBoardingType(object[index])
        break
      }
      case 'reservable_range_type': {
        const object = Object.values(RESERVABLE_RANGE_TYPE)
        setReservableRangeType(object[index])
        break
      }
      case 'fishing_seat_procedure': {
        setFishingSeatProcedureId(values[index])
        break
      }
      case 'boarding_procedure': {
        setBoardingProcedureId(values[index])
        break
      }
    }
  }

  useEffect(() => {
    switch (type) {
      case 'boarding_type':
        if (boardingType) {
          setValue('boardingType', boardingType)
          clearErrors('boardingType')
        }
        break
      case 'reservable_range_type':
        if (reservableRangeType) {
          setValue('reservableRangeType', reservableRangeType)
          clearErrors('reservableRangeType')
        }
        break
      case 'fishing_seat_procedure':
        setValue('fishingSeatProcedureId', fishingSeatProcedureId)
        clearErrors('fishingSeatProcedureId')
        break
      case 'boarding_procedure':
        setValue('boardingProcedureId', boardingProcedureId)
        clearErrors('boardingProcedureId')
        break
    }
  }, [
    boardingType,
    reservableRangeType,
    fishingSeatProcedureId,
    boardingProcedureId,
  ])

  const selectedIndex = useMemo<number | undefined>(() => {
    switch (type) {
      case 'boarding_type': {
        if (boardingType !== undefined) {
          return Object.values(BOARDING_TYPE).indexOf(boardingType)
        }
        break
      }
      case 'reservable_range_type': {
        if (reservableRangeType !== undefined) {
          return Object.values(RESERVABLE_RANGE_TYPE).indexOf(
            reservableRangeType
          )
        }
        break
      }
      case 'fishing_seat_procedure': {
        if (fishingSeatProcedureId != null) {
          return values.indexOf(fishingSeatProcedureId)
        }
        break
      }
      case 'boarding_procedure': {
        if (boardingProcedureId != null) {
          return values.indexOf(boardingProcedureId)
        }
        break
      }
    }
  }, [
    type,
    boardingType,
    reservableRangeType,
    fishingSeatProcedureId,
    boardingProcedureId,
  ])

  const hasError = useMemo<boolean>(() => {
    switch (type) {
      case 'boarding_type':
        return Boolean(errors.boardingType)
      case 'reservable_range_type':
        return Boolean(errors.reservableRangeType)
      case 'fishing_seat_procedure':
        return Boolean(errors.fishingSeatProcedureId)
      case 'boarding_procedure':
        return Boolean(errors.boardingProcedureId)
    }
  }, [type, errors])

  return (
    <SectionContainer title={title}>
      <Stack spacing={2} width="100%">
        <SelectedMenu
          options={options}
          error={hasError}
          selectedIndex={selectedIndex}
          onChangeSelected={handleChange}
        />
      </Stack>
    </SectionContainer>
  )
}

export default SelectMenuSection
