import {
  Divider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FC, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import SelectedMenu from '~/components/atoms/selectedMenu'
import { DEADLINE_TYPE, DeadlineType } from '~/model/plan/deadlineType'
import { PlanBase } from '~/model/plan/plan'
import { getTimeDate, getTimeNumber } from '~/utils/utils'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const deadlineTypeOptions = [
  '前日まで（締切時間も設定可）',
  '2日前まで',
  '3日前まで',
  '4日前まで',
  '7日前まで',
]

const SelectDeadlineSection: FC<Props> = ({
  register,
  setValue,
  getValues,
  formState: { errors },
  clearErrors,
  title,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const [deadline, setDeadline] = useState<DeadlineType>(
    getValues('deadlineType')
  )
  const [deadlineTime, setDeadlineTime] = useState<Date | null>(
    getValues('deadlineTime') == null
      ? null
      : getTimeDate(getValues('deadlineTime')!)
  )

  const isDayBeforeType = useMemo(() => deadline === 'day_before', [deadline])

  useEffect(() => {
    register('deadlineType', { required: true })
    register('deadlineTime', { required: false })
  }, [])

  useEffect(() => {
    if (deadline) {
      setValue('deadlineType', deadline)
      clearErrors('deadlineType')
    }
  }, [deadline])

  useEffect(() => {
    const deadlineTimeNumber = deadlineTime
      ? getTimeNumber(deadlineTime)
      : undefined
    setValue('deadlineTime', deadlineTimeNumber)
    clearErrors('deadlineTime')
  }, [deadlineTime])

  const handleChange = (index: number) => {
    const newDeadline = Object.values(DEADLINE_TYPE)[index]
    setDeadline(newDeadline)
    if (newDeadline !== 'day_before') setDeadlineTime(null)
  }

  return (
    <SectionContainer title={title}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={isDesktop ? 2.5 : 1.5}>
          <SelectedMenu
            onChangeSelected={handleChange}
            selectedIndex={
              deadline && Object.values(DEADLINE_TYPE).indexOf(deadline)
            }
            error={Boolean(errors.deadlineType)}
            options={deadlineTypeOptions}
          />
          {isDesktop && isDayBeforeType && <Divider />}
          {isDayBeforeType && (
            <>
              <Stack spacing={1}>
                <Typography variant="h5" component="span">
                  前日の予約締切時間
                </Typography>
                <Typography variant="h6" component="span" color="subtitle.main">
                  前日の予約締め切り時間は21:00よりも前であれば設定できます。
                </Typography>
              </Stack>
              <TimePicker
                value={deadlineTime}
                onChange={setDeadlineTime}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.deadlineTime)}
                    sx={{
                      ['& fieldset']: { borderRadius: '8px' },
                      width: '200px',
                    }}
                  />
                )}
              />
            </>
          )}
        </Stack>
      </LocalizationProvider>
    </SectionContainer>
  )
}

export default SelectDeadlineSection
