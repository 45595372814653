import { Button, styled, Typography, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/material'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import BordingTypeLabel from '~/components/atoms/bordingTypeLabel'
import PublicLabel from '~/components/atoms/publicLabel'
import NoImageSVG from '~/components/svgs/no_image.svg'
import { Plan } from '~/model/plan/plan'

const StyledImg = styled('img')({
  width: '76px',
  minWidth: '76px',
  height: '76px',
  minHeight: '76px',
})

interface Props {
  plan: Plan
}

const PlanItem: FC<Props> = ({ plan }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const navigate = useNavigate()

  const price = useMemo(() => {
    return plan.boardingType == 'shared'
      ? `${plan.shared?.categories[0]?.price.toLocaleString() || '0'}円`
      : `${plan.charter?.price.toLocaleString() || '0'}円〜`
  }, [plan.boardingType, plan.shared, plan.charter])

  const thumbnailImage = useMemo(() => {
    const thumbnail = plan.imageUrlList[0]
    return thumbnail ? thumbnail : NoImageSVG
  }, [plan.imageUrlList])

  return (
    <Button
      color="secondary"
      onClick={() => navigate(`/plans/edit/${plan.documentId}`)}
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        spacing={2}
        padding="12px 0"
      >
        <StyledImg src={thumbnailImage} />
        {/* desktop */}
        {isDesktop && (
          <>
            <Typography flex={1} variant="h4" component="div" textAlign="left">
              {plan.name}
            </Typography>
            <Stack
              direction="row"
              spacing={3}
              paddingLeft="20px"
              alignItems="center"
            >
              <Typography
                variant="subtitle2"
                component="span"
                color="dark.main"
                lineHeight="100%"
                noWrap
              >
                {price}
              </Typography>
              <BordingTypeLabel type={plan.boardingType} />
              <PublicLabel isPublic={plan.isPublished} />
            </Stack>
          </>
        )}
        {/* mobile */}
        {!isDesktop && (
          <Stack spacing={1}>
            <Typography variant="h4" component="div" textAlign="left">
              {plan.name}
            </Typography>
            <Typography
              variant="subtitle2"
              color="dark.main"
              component="span"
              noWrap
              textAlign="left"
            >
              {price}
            </Typography>
            <Stack direction="row" spacing={2}>
              <BordingTypeLabel type={plan.boardingType} />
              <PublicLabel isPublic={plan.isPublished} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Button>
  )
}

export default PlanItem
