import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { signOut } from 'firebase/auth'
import { FC, useState } from 'react'

import BasicButton from '~/components/atoms/basicButton'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import auth from '~/utils/auth'

const NotPermittedBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [loading, setIsLoading] = useState(false)

  const logout = async () => {
    setIsLoading(true)
    try {
      await signOut(auth)
    } catch (e) {
      const message = e instanceof Error ? e.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsLoading(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        alignItems: 'center',
        rowGap: '32px',
        ['@media screen and (min-width: 841px)']: {
          width: '566px',
          paddingTop: '48px',
          paddingBottom: '60px',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack
          width="100%"
          boxSizing="border-box"
          padding={isDesktop ? '0' : '38px 24px 0 24px'}
          spacing={isDesktop ? '60px' : '50px'}
          alignItems="center"
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="h4" component="span">
              {`「${
                currentUser?.user.phoneNumber ?? currentUser?.user.email
              }」でのログインをツリトリ運営事務局が許可すると、管理画面が使用できるようになります。`}
            </Typography>
          </Stack>
          <BasicButton
            title="ログアウト"
            loading={loading}
            color="secondary"
            onClick={logout}
            sx={{
              ['@media screen and (min-width: 841px)']: {
                width: '275px',
              },
            }}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default NotPermittedBody
