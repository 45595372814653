import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import { Boat, BoatBase, BoatWrite, FirestoreBoat } from '~/model/account/boat'
import { getGuideBoatsPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getBoats = async (uid: string) => {
  const boatsPath = getGuideBoatsPath(uid)
  const ref = collection(firestore, boatsPath).withConverter(
    converter<FirestoreBoat>()
  )
  const q = query(ref, limit(100))
  const snaps = await getDocs(q)

  return snaps.docs.map((doc) => {
    const boat: Boat = { ...doc.data(), documentId: doc.id }
    return boat
  })
}

export const getBoat = async (uid: string, boatId: string) => {
  const boatsPath = getGuideBoatsPath(uid)
  const ref = doc(firestore, boatsPath, boatId).withConverter(
    converter<FirestoreBoat>()
  )
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const boat: Boat = { ...snap.data(), documentId: snap.id }
    return boat
  } else {
    throw new Error('Document not found.')
  }
}

export const addBoat = async (uid: string, data: BoatBase) => {
  const boatsPath = getGuideBoatsPath(uid)
  const ref = doc(collection(firestore, boatsPath)).withConverter(
    converter<FirestoreBoat>()
  )

  const writeData: BoatWrite = {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  await setDoc(ref, writeData)
}

export const updateBoat = async (
  uid: string,
  boatId: string,
  data: BoatBase
) => {
  const boatsPath = getGuideBoatsPath(uid)
  const ref = doc(firestore, boatsPath, boatId).withConverter(
    converter<FirestoreBoat>()
  )

  const writeData = { ...data, updatedAt: serverTimestamp() }

  await setDoc(ref, writeData, { merge: true })
}
