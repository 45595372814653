import {
  Button,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useState } from 'react'

import BasicButton from '~/components/atoms/basicButton'
import InputImage from '~/components/atoms/inputImage'
import InputText from '~/components/atoms/inputText'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as PlusIcon } from '~/components/svgs/plus.svg'
import { Access } from '~/model/account/access'
import { UPLOAD_TYPE } from '~/model/uploadType'
import { updateAccess } from '~/repositories/accessRepository'

import StepDisplayItem from '../../step/stepDisplayItem'

interface OwnProps {
  access: Access
  onUpdated?: (access: Access) => void
}

type Props = OwnProps & EditableSectionProps

const StyledPlusIcon = styled(PlusIcon)({
  ['path:first-child']: {
    fill: '#00A3FF',
  },
})

const SectionProfileAccessCar: FC<Props> = ({
  access,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  // For Editing item.
  const [currentDescription, setCurrentDescription] = useState(
    access.directionsByCar.description
  )
  const [currentSteps, setCurrentSteps] = useState(access.directionsByCar.steps)

  const handleAddStep = () => {
    const lastStep = currentSteps.at(-1)
    setCurrentSteps([
      ...currentSteps,
      {
        step: lastStep != null ? lastStep.step + 1 : 1,
        title: 'タイトル',
        description: '説明',
        imageUrlList: [],
      },
    ])
  }

  const onSubmit = async () => {
    if (currentUser && currentDescription != null && currentSteps != null) {
      const newAccess: Access = {
        ...access,
        directionsByCar: {
          description: currentDescription,
          steps: currentSteps,
        },
      }
      setIsLoading(true)
      try {
        await updateAccess(access.documentId, newAccess)
        onUpdated?.(newAccess)
        toggleAlert(true, 'success', '車でのアクセス情報を更新しました！')
      } catch (e) {
        toggleAlert(true, 'error', e)
      }
      setIsLoading(false)
    }
  }

  return (
    <EditableSectionContainer
      title="お車でお越しの方へ"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentDescription(access.directionsByCar.description)
        setCurrentSteps(access.directionsByCar.steps)
        onClickMode?.()
      }}
    >
      {!editing && access && (
        <Stack spacing={2}>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.main"
          >
            {access.directionsByCar.description}
          </Typography>
          <Typography variant="h4" component="div" color="secondary.main">
            道順の詳細
          </Typography>
          <Stack spacing={2}>
            {access.directionsByCar.steps.map((step) => (
              <StepDisplayItem step={step} />
            ))}
          </Stack>
        </Stack>
      )}
      {editing && (
        <Stack spacing={3} paddingBottom="24px">
          <Stack spacing={1.5}>
            <Typography variant="h4" component="span">
              説明
            </Typography>
            <TextField
              value={currentDescription}
              placeholder={'お車でお越しの方へのご説明をご記載ください。'}
              onChange={(e) => setCurrentDescription(e.target.value)}
              variant="outlined"
              multiline
              rows={5}
              sx={{
                ['& fieldset']: { borderRadius: '8px' },
                width: '100%',
              }}
            />
          </Stack>
          <Stack spacing={2} alignItems="flex-start">
            <Typography variant="h4" component="span">
              道順の詳細
            </Typography>
            {currentSteps.map((step, index) => (
              <Stack spacing={1.5} width="100%">
                <Stack spacing={1}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="secondary.main"
                  >
                    {`STEP${step.step}: タイトル`}
                  </Typography>
                  <InputText
                    size="small"
                    name="タイトル"
                    placeholder="ステップのタイトル"
                    value={step.title}
                    onChange={(e) =>
                      setCurrentSteps((prev) =>
                        prev.map((s, i) =>
                          index === i ? { ...s, title: e.target.value } : s
                        )
                      )
                    }
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="secondary.main"
                  >
                    {`STEP${step.step}: 説明`}
                  </Typography>
                  <TextField
                    value={step.description}
                    placeholder={'ステップの説明'}
                    onChange={(e) =>
                      setCurrentSteps((prev) =>
                        prev.map((s, i) =>
                          index === i
                            ? { ...s, description: e.target.value }
                            : s
                        )
                      )
                    }
                    variant="outlined"
                    multiline
                    rows={5}
                    sx={{
                      ['& fieldset']: { borderRadius: '8px' },
                      width: '100%',
                    }}
                  />
                </Stack>
                <Grid container spacing={2}>
                  {step.imageUrlList.map((url, imageIndex) => (
                    <Grid key={index.toString()} item>
                      <InputImage
                        key={url}
                        type={UPLOAD_TYPE.access}
                        imageUrl={url}
                        onClose={() =>
                          setCurrentSteps((prev) =>
                            prev.map((s, i) =>
                              index === i
                                ? {
                                    ...s,
                                    imageUrlList: s.imageUrlList.filter(
                                      (_, i) => i !== imageIndex
                                    ),
                                  }
                                : s
                            )
                          )
                        }
                      />
                    </Grid>
                  ))}
                  {step.imageUrlList.length < 10 && (
                    <Grid item>
                      <InputImage
                        type={UPLOAD_TYPE.access}
                        onAddImageUrl={(url) => {
                          setCurrentSteps((prev) =>
                            prev.map((s, i) =>
                              index === i
                                ? {
                                    ...s,
                                    imageUrlList: [...s.imageUrlList, url],
                                  }
                                : s
                            )
                          )
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <BasicButton
                  title="削除する"
                  color="error"
                  type="submit"
                  sx={{ width: '120px', height: '40px' }}
                  onClick={() => {
                    const newSteps = currentSteps
                      .filter((_, i) => index !== i)
                      .sort((a, b) => a.step - b.step)
                      .map((s, i) => {
                        // STEP番号を更新
                        return { ...s, step: i + 1 }
                      })
                    setCurrentSteps(newSteps)
                  }}
                />
              </Stack>
            ))}
            <Button startIcon={<StyledPlusIcon />} onClick={handleAddStep}>
              <Typography variant="h5" color="primary.main">
                追加する
              </Typography>
            </Button>
          </Stack>
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileAccessCar
