import { Stack, Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  isPublic: boolean
}

const PublicLabel: FC<Props> = ({ isPublic }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center" minWidth="64px">
      <Stack
        width="12px"
        height="12px"
        borderRadius="6px"
        sx={{ backgroundColor: isPublic ? 'success.main' : 'e8.main' }}
      />
      <Typography variant="h5" component="span">
        {isPublic ? '公開中' : '非公開'}
      </Typography>
    </Stack>
  )
}

export default PublicLabel
