import { TextField } from '@mui/material'
import { FC } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { BoatBase } from '~/model/account/boat'

interface OwnProps {
  title: string
  placeholder?: string
}

type Props = UseFormReturn<BoatBase> & OwnProps

const InputBoatNameSection: FC<Props> = ({
  register,
  control,
  formState: { errors },
  title,
  placeholder,
}) => {
  const nameReg = register('name', {
    required: true,
    minLength: 1,
    maxLength: 100,
  })
  return (
    <SectionContainer title={title}>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            {...{ error: Boolean(errors.name) }}
            {...nameReg}
            inputRef={nameReg.ref}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            sx={{
              ['& fieldset']: { borderRadius: '8px' },
              width: '100%',
            }}
          />
        )}
      />
    </SectionContainer>
  )
}

export default InputBoatNameSection
