import { Button, Typography, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { BoardingProcedure } from '~/model/account/boardingProcedure'

interface Props {
  boardingProcedure: BoardingProcedure
}

const BoardingProcedureItem: FC<Props> = ({ boardingProcedure }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const navigate = useNavigate()

  return (
    <Button
      color="secondary"
      onClick={() =>
        navigate(`/boarding-procedures/edit/${boardingProcedure.documentId}`)
      }
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        spacing={2}
        padding="12px 0"
      >
        {/* desktop */}
        {isDesktop && (
          <>
            <Typography flex={1} variant="h4" component="div" textAlign="left">
              {boardingProcedure.title}
            </Typography>
          </>
        )}
        {/* mobile */}
        {!isDesktop && (
          <Stack spacing={1}>
            <Typography variant="h4" component="div" textAlign="left">
              {boardingProcedure.title}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Button>
  )
}

export default BoardingProcedureItem
