import { Divider, Stack } from '@mui/material'
import { FC } from 'react'

import AccountNavigationItem from '~/components/molecules/accountNavigationItem'

interface Props {
  query: string | null
}

const AccountSideNavigation: FC<Props> = ({ query }) => {
  const accessQuery = 'access'
  const contactMethodQuery = 'contact-method'
  const bankAccountQuery = 'bank-account'
  return (
    <Stack
      width="190px"
      border="1px solid #E4E4E4"
      borderRadius="8px"
      overflow="hidden"
      divider={<Divider />}
    >
      <AccountNavigationItem
        title="プロフィール情報"
        to={''}
        selected={!query}
      />
      <AccountNavigationItem
        title="アクセス"
        to={`?page=${accessQuery}`}
        selected={query === accessQuery}
      />
      <AccountNavigationItem
        title="連絡先情報"
        to={`?page=${contactMethodQuery}`}
        selected={query === contactMethodQuery}
      />
      <AccountNavigationItem
        title="口座情報"
        to={`?page=${bankAccountQuery}`}
        selected={query === bankAccountQuery}
      />
    </Stack>
  )
}

export default AccountSideNavigation
