import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'

import InputText from '~/components/atoms/inputText'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import {
  GUEST_CONTACT_METHOD_TYPE,
  GuestContactMethod,
  GuestContactMethodType,
} from '~/model/account/guestContactMethod'
import { updateGuideGuestContactMethodList } from '~/repositories/guideRepository'

interface OwnProps {
  contactMethodList: GuestContactMethod[]
  onUpdated?: (guestContactMethodList: GuestContactMethod[]) => void
}

type Props = OwnProps & EditableSectionProps

const SectionGuestContactMethod: FC<Props> = ({
  contactMethodList,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [currentContactMethodList, setCurrentContactMethodList] =
    useState<GuestContactMethod[]>(contactMethodList)

  const getTitle = (type: GuestContactMethodType) => {
    switch (type) {
      case 'phone':
        return '電話'
      case 'sms':
        return 'ショートメッセージ / SMS'
      case 'email':
        return 'メールアドレス'
    }
  }

  const getPlaceholder = (type: GuestContactMethodType) => {
    switch (type) {
      case 'phone':
        return '電話番号を入力'
      case 'sms':
        return 'SMSが利用可能な携帯番号を入力'
      case 'email':
        return 'メールアドレスを入力'
    }
  }

  const getTitleValue = (type: GuestContactMethodType) => {
    const optionalValue = contactMethodList.find((v) => v.name === type)?.value
    return optionalValue
      ? `${getTitle(type)}（${optionalValue}）`
      : getTitle(type)
  }

  const handleChange = (newValue: string, type: GuestContactMethodType) => {
    const included = currentContactMethodList.map((v) => v.name).includes(type)
    if (included) {
      if (newValue) {
        setCurrentContactMethodList(
          currentContactMethodList.map((e) =>
            e.name === type ? { name: type, value: newValue } : e
          )
        )
      } else {
        // 空文字になったら、連絡方法を削除
        setCurrentContactMethodList(
          currentContactMethodList.filter((v) => v.name !== type)
        )
      }
    } else if (newValue) {
      // 含まれていない&空文字でない場合は追加
      setCurrentContactMethodList([
        ...currentContactMethodList,
        { name: type, value: newValue },
      ])
    }
  }

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      await updateGuideGuestContactMethodList(
        currentUser.user.uid,
        currentContactMethodList
      )
      onUpdated?.(currentContactMethodList)
      toggleAlert(true, 'success', 'ゲストとの連絡手段を更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  return (
    <EditableSectionContainer
      title="ゲストとの連絡手段"
      description="予約確定後、ゲストとの個別でのやりとりに利用できる連絡手段（複数選択可）"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentContactMethodList(contactMethodList)
        onClickMode?.()
      }}
    >
      {!editing && (
        <FormGroup>
          {Object.values(GUEST_CONTACT_METHOD_TYPE).map((v, index) => (
            <FormControlLabel
              key={index.toString()}
              control={
                <Checkbox
                  checked={contactMethodList.map((e) => e.name).includes(v)}
                  color="success"
                  name={v}
                />
              }
              label={
                <Typography
                  variant="subtitle2"
                  color="secondary.main"
                  component="span"
                >
                  {getTitleValue(v)}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      )}
      {editing && (
        <Stack spacing={2}>
          {Object.values(GUEST_CONTACT_METHOD_TYPE).map((type) => (
            <Stack spacing={1}>
              <Typography
                variant="subtitle2"
                component="span"
                color="secondary.main"
              >
                {getTitle(type)}
              </Typography>
              <InputText
                size="small"
                name={getTitle(type)}
                placeholder={getPlaceholder(type)}
                value={
                  currentContactMethodList.find((e) => e.name === type)?.value
                }
                onChange={(e) => handleChange(e.target.value, type)}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionGuestContactMethod
