import {
  Backdrop,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import BasicButton from '~/components/atoms/basicButton'
import Loading from '~/components/atoms/loading'
import BreadcrumbList from '~/components/molecules/breadcrumbList'
import InputCharterPriceSection from '~/components/molecules/planSections/inputCharterPriceSection'
import InputFeaturesSection from '~/components/molecules/planSections/inputFeaturesSection'
import InputImagesSection from '~/components/molecules/planSections/inputImagesSection'
import InputMultilineTextSection from '~/components/molecules/planSections/inputMultilineTextSection'
import InputSeasonSection from '~/components/molecules/planSections/inputSeason'
import InputSharedPriceSection from '~/components/molecules/planSections/inputSharedPriceSection'
import InputTextSection from '~/components/molecules/planSections/inputTextSection'
import SelectCancellationPolicy from '~/components/molecules/planSections/selectCancellationPolicy'
import SelectCheckSection from '~/components/molecules/planSections/selectCheckSection'
import SelectDeadlineSection from '~/components/molecules/planSections/selectDeadlineSection'
import SelectFishes from '~/components/molecules/planSections/selectFishes'
import SelectFishingTypes from '~/components/molecules/planSections/selectFishingTypeSection'
import SelectMeetingTimeSection from '~/components/molecules/planSections/selectMeetingTimeSection'
import SelectMenuSection from '~/components/molecules/planSections/selectMenuSection'
import SelectStartAndEndTimeSection from '~/components/molecules/planSections/selectStartAndEndTimeSection'
import SelectTackleSection from '~/components/molecules/planSections/selectTackleSection'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { BoardingProcedure } from '~/model/account/boardingProcedure'
import { FishingSeatProcedure } from '~/model/account/fishingSeatProcedure'
import { Tackle } from '~/model/account/tackle'
import { BOARDING_TYPE } from '~/model/plan/boardingType'
import { PlanBase } from '~/model/plan/plan'
import { RESERVABLE_RANGE_TYPE } from '~/model/plan/reservableRangeType'
import { getBoardingProcedures } from '~/repositories/boardingProcedureRepository'
import { getFishingSeatProcedures } from '~/repositories/fishingSeatProcedureRepository'
import { addPlan, getPlan, updatePlan } from '~/repositories/planRepository'
import { getTackles } from '~/repositories/tackleRepository'

interface Props {
  // planIdがある場合は編集モード
  planId?: string
}

const PlansWriteBody: FC<Props> = ({ planId }) => {
  const [isWriting, setIsWriting] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [guideTackles, setGuideTackles] = useState<Tackle[]>([])
  const [fishingSeatProcedures, setFishingSeatProcedures] = useState<
    FishingSeatProcedure[]
  >([])
  const [boardingProcedures, setBoardingProcedures] = useState<
    BoardingProcedure[]
  >([])
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const form = useForm<PlanBase>()
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const navigate = useNavigate()

  const title = useMemo(() => (planId ? 'プラン編集' : 'プラン追加'), [planId])

  useEffect(() => {
    if (!currentUser) return
    // 初期化処理
    const cleanup = async () => {
      // TODO: get tackles.
      const tackles = await getTackles(currentUser.user.uid)
      const fishingSeatProcedures = await getFishingSeatProcedures(
        currentUser.user.uid
      )
      const boardingProcedures = await getBoardingProcedures(
        currentUser.user.uid
      )
      setGuideTackles(tackles)
      setFishingSeatProcedures(fishingSeatProcedures)
      setBoardingProcedures(boardingProcedures)
      if (planId) {
        // 編集画面
        const plan = await getPlan(planId)
        form.setValue('guideUid', currentUser.user.uid)
        form.setValue('name', plan.name)
        form.setValue('imageUrlList', plan.imageUrlList)
        form.setValue('season', plan.season)
        form.setValue('introduction', plan.introduction)
        form.setValue('notes', plan.notes)
        form.setValue('featureList', plan.featureList)
        form.setValue('boardingType', plan.boardingType)
        form.setValue('fishes', plan.fishes)
        form.setValue('fishingTypeList', plan.fishingTypeList)
        form.setValue('tackleList', plan.tackleList)
        form.setValue('fishingSeatProcedureId', plan.fishingSeatProcedureId)
        form.setValue('boardingProcedureId', plan.boardingProcedureId)
        form.setValue('meetingTime', plan.meetingTime)
        form.setValue('startTime', plan.startTime)
        form.setValue('endTime', plan.endTime)
        form.setValue('isPublished', plan.isPublished)
        form.setValue('deadlineType', plan.deadlineType)
        form.setValue('deadlineTime', plan.deadlineTime)
        form.setValue('reservableRangeType', plan.reservableRangeType)
        form.setValue('cancellationPolicyType', plan.cancellationPolicyType)
        if (plan.charter) {
          form.setValue('charter', plan.charter)
        }
        if (plan.shared) {
          form.setValue('shared', plan.shared)
        }
        setInitialized(true)
      } else {
        // 追加画面
        form.setValue('guideUid', currentUser.user.uid)
        form.setValue('imageUrlList', [])
        form.setValue('featureList', [])
        form.setValue('fishes', [])
        form.setValue('fishingTypeList', [])
        form.setValue('tackleList', [])
        form.setValue('isPublished', true)
        setInitialized(true)
      }
    }
    cleanup()
  }, [planId])

  useEffect(() => {
    if (form.getValues('boardingType') === 'charter') {
      form.setValue('shared', undefined)
    }
    if (form.getValues('boardingType') === 'shared') {
      form.setValue('charter', undefined)
    }
  }, [form.getValues('boardingType')])

  const boardingType = useMemo(() => {
    return form.getValues('boardingType')
  }, [form.getValues('boardingType')])

  const onSubmit: SubmitHandler<PlanBase> = async (data) => {
    setIsWriting(true)
    try {
      if (planId) {
        await updatePlan(planId, data)
      } else {
        await addPlan(data)
        navigate('/plans', { replace: true })
      }
      toggleAlert(
        true,
        'success',
        planId ? 'プランを更新しました！' : 'プランを追加しました！'
      )
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsWriting(false)
    console.log(data)
  }

  return (
    <Stack width="100%" spacing={1.5}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWriting}
      >
        <Loading width={150} height={150} />
      </Backdrop>
      {initialized && (
        <Stack spacing={4}>
          <BreadcrumbList
            items={[
              { name: 'プラン一覧', to: '/plans' },
              { name: 'プラン追加' },
            ]}
          />
          <Stack component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Typography variant={isDesktop ? 'h1' : 'h2'}>{title}</Typography>
            <InputTextSection
              {...form}
              title="プラン名"
              placeholder="青物ジギング午前便"
            />
            <Divider />
            <InputImagesSection {...form} title="プラン画像" limit={10} />
            <Divider />
            <InputMultilineTextSection
              {...form}
              type="introduction"
              title="プランの説明"
              placeholder="プランの特徴や説明などをご自由にご入力ください。"
            />
            <Divider />
            <InputMultilineTextSection
              {...form}
              type="notes"
              title="備考"
              placeholder="事前に用意しておくものや、当該釣りプランに関する注意事項などをご自由にご入力ください。"
            />
            <Divider />
            <InputFeaturesSection {...form} title="プランの特徴" />
            <Divider />
            <SelectMenuSection
              {...form}
              type="boarding_type"
              options={Object.values(BOARDING_TYPE)}
              values={Object.values(BOARDING_TYPE)}
              title="乗船タイプ"
            />
            <Divider />
            <SelectFishes {...form} title="釣れる魚" />
            <Divider />
            <SelectFishingTypes {...form} title="釣り方" />
            <Divider />
            <SelectTackleSection
              {...form}
              title="タックル"
              guideTackles={guideTackles}
            />
            <Divider />
            <SelectMenuSection
              {...form}
              type="fishing_seat_procedure"
              options={fishingSeatProcedures.map((v) => v.title)}
              values={fishingSeatProcedures.map((v) => v.documentId)}
              title="釣り座の決定方法"
            />
            <Divider />
            <SelectMenuSection
              {...form}
              type="boarding_procedure"
              options={boardingProcedures.map((v) => v.title)}
              values={boardingProcedures.map((v) => v.documentId)}
              title="乗船方法"
            />
            <Divider />
            <InputSeasonSection {...form} title="時期の目安" />
            <Divider />
            <SelectMeetingTimeSection {...form} title="集合時間" />
            <Divider />
            <SelectStartAndEndTimeSection {...form} title="出船・帰港時間" />
            <Divider />
            <SelectCheckSection {...form} title="公開" />
            <Divider />
            <SelectDeadlineSection {...form} title="予約締切日" />
            <Divider />
            <SelectMenuSection
              {...form}
              type="reservable_range_type"
              options={Object.values(RESERVABLE_RANGE_TYPE)}
              values={Object.values(RESERVABLE_RANGE_TYPE)}
              title="予約可能期間"
            />
            <Divider />
            <SelectCancellationPolicy {...form} title="キャンセルポリシー" />
            <Divider />
            {boardingType === 'shared' && (
              <InputSharedPriceSection {...form} title="乗合料金(税込)" />
            )}
            {boardingType === 'charter' && (
              <InputCharterPriceSection {...form} title="仕立て料金(税込)" />
            )}
            <Stack
              paddingTop="24px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <BasicButton
                type="reset"
                title="キャンセル"
                color="info"
                sx={{ width: '140px' }}
                onClick={() => navigate('/plans', { replace: true })}
              />
              <BasicButton
                type="submit"
                title={planId ? '更新する' : '登録する'}
                sx={{ width: '220px' }}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default PlansWriteBody
