import { FC, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import BoardingProceduresWriteBody from '~/components/organisms/boardingProceduresWriteBody'
import Container from '~/components/organisms/container'
import ContentContainer from '~/components/organisms/contentContainer'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import NotPermittedBody from '~/components/organisms/notPermittedBody'
import { useAuthContext } from '~/components/providers/auth'

const BoardingProcedureWriteTemplate: FC = () => {
  const { currentUser } = useAuthContext()
  const { pathname } = useLocation()
  const editMatch = useMemo(
    () => matchPath({ path: '/boarding-procedures/edit/:id' }, pathname),
    [pathname]
  )

  return (
    <Container>
      <Head />
      <Header />
      <ContentContainer>
        {currentUser && currentUser.isPermittedGuide && (
          <BoardingProceduresWriteBody
            boardingProcedureId={editMatch?.params.id}
          />
        )}
        {currentUser && !currentUser.isPermittedGuide && <NotPermittedBody />}
      </ContentContainer>
    </Container>
  )
}

export default BoardingProcedureWriteTemplate
