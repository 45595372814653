import { Stack, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { Control, useWatch } from 'react-hook-form'

import BasicButton from '~/components/atoms/basicButton'
import InputText from '~/components/atoms/inputText'
import { CharterCategory } from '~/model/plan/charter'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  index: number
  control: Control<PlanBase, any>
  onChangeCategory?: (category: CharterCategory) => void
  onRemoveCategory?: () => void
}

const InputCharterCategoryItem: FC<OwnProps> = ({
  index,
  control,
  onChangeCategory,
  onRemoveCategory,
}) => {
  const value = useWatch({
    control: control,
    name: `charter.categories.${index}`,
  })

  const onChangeName = (newName: string) => {
    const newCategory: CharterCategory = { ...value, name: newName }
    onChangeCategory?.(newCategory)
  }

  const onChangeCount = (newCount: number) => {
    const newCategory: CharterCategory = { ...value, count: newCount }
    onChangeCategory?.(newCategory)
  }

  const onChangeAdditionalPrice = (newPrice: number) => {
    const newCategory: CharterCategory = {
      ...value,
      additionalPerPrice: newPrice,
    }
    onChangeCategory?.(newCategory)
  }

  return (
    <Stack spacing={1.5} width="100%">
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`ID${value.id}: 名前`}
        </Typography>
        <InputText
          size="small"
          placeholder="属性"
          value={value.name}
          onChange={(e) => onChangeName(e.target.value)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`ID${value.id}: 人数カウント`}
        </Typography>
        <InputText
          size="small"
          placeholder="人数のカウント"
          value={value.count}
          onChange={(e) => onChangeCount(parseFloat(e.target.value) || 0)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`ID${value.id}: 料金`}
        </Typography>
        <InputText
          size="small"
          placeholder="追加人数の料金"
          value={value.additionalPerPrice}
          onChange={(e) =>
            onChangeAdditionalPrice(parseInt(e.target.value) || 0)
          }
        />
      </Stack>
      <BasicButton
        title="削除する"
        color="error"
        sx={{ width: '120px', height: '40px' }}
        onClick={onRemoveCategory}
      />
    </Stack>
  )
}

export default InputCharterCategoryItem
