import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { BoatBase } from '~/model/account/boat'
import {
  BOAT_EQUIPMENT_TYPE,
  BoatEquipmentType,
  equipmentName,
} from '~/model/account/boatEquipmentType'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<BoatBase> & OwnProps

const InputEquipmentSection: FC<Props> = ({ title, setValue, getValues }) => {
  const [equipmentList, setEquipmentList] = useState<BoatEquipmentType[]>(
    getValues('equipmentList')
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const equipmentType = event.target.name as BoatEquipmentType
    const isEquipmentType =
      Object.values(BOAT_EQUIPMENT_TYPE).includes(equipmentType)
    if (isEquipmentType) {
      event.target.checked
        ? setEquipmentList([...equipmentList, equipmentType])
        : setEquipmentList(equipmentList.filter((v) => v !== event.target.name))
    }
  }

  useEffect(() => {
    setValue('equipmentList', [...equipmentList])
  }, [equipmentList])

  return (
    <SectionContainer title={title}>
      <FormGroup>
        {Object.values(BOAT_EQUIPMENT_TYPE).map((v, index) => (
          <FormControlLabel
            key={index.toString()}
            control={
              <Checkbox
                checked={equipmentList.includes(v)}
                onChange={handleChange}
                color="success"
                name={v}
              />
            }
            label={
              <Typography
                variant="subtitle2"
                color="secondary.main"
                component="span"
              >
                {equipmentName(v)}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </SectionContainer>
  )
}

export default InputEquipmentSection
