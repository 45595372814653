export const GUEST_CONTACT_METHOD_TYPE = {
  phone: 'phone',
  sms: 'sms',
  email: 'email',
} as const

export type GuestContactMethodType =
  typeof GUEST_CONTACT_METHOD_TYPE[keyof typeof GUEST_CONTACT_METHOD_TYPE]

export interface GuestContactMethod {
  name: GuestContactMethodType
  value: string
}
