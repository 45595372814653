import { Breadcrumbs, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowLogo } from '~/components/svgs/arrow_right.svg'

interface Props {
  items: { name: string; to?: string }[]
}

const StyledArrowLogo = styled(ArrowLogo)({
  width: '16px',
  height: '16px',
})

const BreadcrumbList: FC<Props> = ({ items }) => {
  const navigate = useNavigate()

  return (
    <Breadcrumbs separator={<StyledArrowLogo />}>
      {items.map((item, index) => (
        <Typography
          key={index.toString()}
          variant="subtitle2"
          color="secondary.main"
          component="span"
          onClick={() => item.to && navigate(item.to)}
          sx={
            item.to
              ? {
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }
              : undefined
          }
        >
          {item.name}
        </Typography>
      ))}
    </Breadcrumbs>
  )
}

export default BreadcrumbList
