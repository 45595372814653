import { CSSProperties, FC } from 'react'
import Lottie from 'react-lottie'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require('./loading.json'),
}

interface Props {
  width?: number
  height?: number
  styles?: CSSProperties
}

const Loading: FC<Props> = ({ width, height, styles }) => {
  const defaultSize = 100
  return (
    <Lottie
      style={styles}
      options={defaultOptions}
      width={width ?? defaultSize}
      height={height ?? defaultSize}
    />
  )
}

export default Loading
