import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import SectionProfileAccess from '~/components/molecules/accessSections/sectionProfileAccess'
import SectionProfileAccessCar from '~/components/molecules/accessSections/sectionProfileAccessCar'
import SectionProfileAccessParking from '~/components/molecules/accessSections/sectionProfileAccessParking'
import SectionProfileAccessParkingOptional from '~/components/molecules/accessSections/sectionProfileAccessParkingOptional'
import SectionProfileAccessTrain from '~/components/molecules/accessSections/sectionProfileAccessTrain'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Access } from '~/model/account/access'
import { getAccess } from '~/repositories/accessRepository'

const AccountAccessBody: FC = () => {
  const [editMode, setEditMode] = useState<
    | 'base'
    | 'first_parking'
    | 'second_parking'
    | 'third_parking'
    | 'car'
    | 'train'
  >()
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [access, setAccess] = useState<Access>()

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const access = await getAccess(currentUser.user.uid)
          setAccess(access)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  if (access) {
    return (
      <Stack
        flex={1}
        spacing={isDesktop ? '24px' : '16px'}
        divider={<Divider />}
      >
        <SectionProfileAccess
          access={access}
          editing={editMode === 'base'}
          disabled={editMode && editMode !== 'base'}
          onClickMode={() =>
            setEditMode(editMode === 'base' ? undefined : 'base')
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setAccess(newAccess)
          }}
        />
        <SectionProfileAccessParking
          access={access}
          editing={editMode === 'first_parking'}
          disabled={editMode && editMode !== 'first_parking'}
          onClickMode={() =>
            setEditMode(
              editMode === 'first_parking' ? undefined : 'first_parking'
            )
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setAccess(newAccess)
          }}
        />
        <SectionProfileAccessParkingOptional
          type="second_parking"
          access={access}
          editing={editMode === 'second_parking'}
          disabled={editMode && editMode !== 'second_parking'}
          onClickMode={() =>
            setEditMode(
              editMode === 'second_parking' ? undefined : 'second_parking'
            )
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setAccess(newAccess)
          }}
        />
        <SectionProfileAccessParkingOptional
          type="third_parking"
          access={access}
          editing={editMode === 'third_parking'}
          disabled={editMode && editMode !== 'third_parking'}
          onClickMode={() =>
            setEditMode(
              editMode === 'third_parking' ? undefined : 'third_parking'
            )
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setAccess(newAccess)
          }}
        />
        <SectionProfileAccessCar
          access={access}
          editing={editMode === 'car'}
          disabled={editMode && editMode !== 'car'}
          onClickMode={() =>
            setEditMode(editMode === 'car' ? undefined : 'car')
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setAccess(newAccess)
          }}
        />
        <SectionProfileAccessTrain
          access={access}
          editing={editMode === 'train'}
          disabled={editMode && editMode !== 'train'}
          onClickMode={() =>
            setEditMode(editMode === 'train' ? undefined : 'train')
          }
          onUpdated={(newAccess) => {
            setEditMode(undefined)
            setAccess(newAccess)
          }}
        />
        {/* <SectionReservationPhone
          phone={guide.phone}
          editing={editMode === 'phone'}
          disabled={editMode && editMode !== 'phone'}
          onClickMode={() =>
            setEditMode(editMode === 'phone' ? undefined : 'phone')
          }
          onUpdated={(newPhone) => {
            setEditMode(undefined)
            setGuide({ ...guide, phone: newPhone })
          }}
        /> */}
      </Stack>
    )
  } else {
    return (
      <Typography
        variant="subtitle1"
        component="span"
        color="secondary.main"
        flex={1}
        textAlign="center"
      >
        このアカウントは現在準備中です
      </Typography>
    )
  }
}

export default AccountAccessBody
