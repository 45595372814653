import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import {
  BankAccount,
  BankAccountBase,
  FirestoreBankAccount,
} from '~/model/bankAccount/bankAccount'
import { CollectionPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getBankAccount = async (uid: string) => {
  const ref = doc(firestore, CollectionPath.BankAccounts, uid).withConverter(
    converter<FirestoreBankAccount>()
  )
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const bankAccount: BankAccount = { ...snap.data(), documentId: snap.id }
    return bankAccount
  } else {
    throw new Error('Document not found.')
  }
}

export const addBankAccount = async (uid: string, data: BankAccountBase) => {
  const ref = doc(firestore, CollectionPath.BankAccounts, uid).withConverter(
    converter<FirestoreBankAccount>()
  )

  await setDoc(ref, {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
}

export const updateBankAccount = async (uid: string, data: BankAccountBase) => {
  const ref = doc(firestore, CollectionPath.BankAccounts, uid).withConverter(
    converter<FirestoreBankAccount>()
  )

  await setDoc(ref, { ...data, updatedAt: serverTimestamp() }, { merge: true })
}
