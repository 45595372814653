export enum CollectionPath {
  Configure = 'configure',
  PermittedGuides = '/permittedGuides',
  Accesses = '/accesses',
  Guides = '/guides',
  Plans = '/plans',
  BankAccounts = '/bankAccounts',
}

export const getGuideBoatsPath = (uid: string) => {
  return `${CollectionPath.Guides}/${uid}/boats`
}

export const getGuideTacklesPath = (uid: string) => {
  return `${CollectionPath.Guides}/${uid}/tackles`
}

export const getGuideBoardingProceduresPath = (uid: string) => {
  return `${CollectionPath.Guides}/${uid}/boardingProcedures`
}

export const getGuideFishingSeatProceduresPath = (uid: string) => {
  return `${CollectionPath.Guides}/${uid}/fishingSeatProcedures`
}
