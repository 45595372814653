import {
  Button,
  Divider,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC } from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { CharterCategory } from '~/model/plan/charter'
import { PlanBase } from '~/model/plan/plan'

import InputCharterCategoryItem from './inputPeopleCategoryItem'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const InputPeople = styled(TextField)({
  ['& fieldset']: {
    borderRadius: '8px',
  },
  width: '100px',
})

const InputPrice = styled(TextField)({
  ['& fieldset']: {
    borderRadius: '8px',
  },
  width: '100px',
})

const SuffixText: FC<{ text: string }> = ({ text }) => {
  return (
    <Typography variant="h3" component="span" fontWeight={500}>
      {text}
    </Typography>
  )
}

const InputCharterPriceSection: FC<Props> = ({
  getValues,
  setValue,
  register,
  control,
  formState: { errors },
  title,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { fields, append } = useFieldArray({
    control,
    name: 'charter.categories',
  })

  const minNumberOfPeopleReg = register('charter.minNumberOfPeople', {
    required: true,
    valueAsNumber: true,
    min: 0,
    max: 100,
  })
  const maxNumberOfPeopleReg = register('charter.maxNumberOfPeople', {
    required: true,
    valueAsNumber: true,
    min: 1,
    max: 100,
  })
  const priceReg = register('charter.price', {
    required: true,
    valueAsNumber: true,
    min: 50,
    max: 500000,
  })

  const capacityReg = register('charter.capacity', {
    required: true,
    valueAsNumber: true,
    min: 1,
    max: 100,
  })

  // Category item.
  const addCategory = () => {
    append({
      id: fields.length + 1,
      name: '一般',
      count: 1,
      additionalPerPrice: 0,
    })
  }
  const updateCategory = (category: CharterCategory, index: number) => {
    setValue(`charter.categories.${index}`, category)
  }
  const removeCategory = (index: number) => {
    const categories = getValues('charter.categories')

    const newCategories = categories
      .filter((_, i) => index !== i)
      .sort((a, b) => a.id - b.id)
      .map((s, i) => {
        return { ...s, step: i + 1 }
      })
    setValue('charter.categories', newCategories)
  }

  return (
    <SectionContainer title={title}>
      <Stack spacing={2.5}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Controller
            name="charter.minNumberOfPeople"
            control={control}
            render={({ field }) => (
              <InputPeople
                {...field}
                {...minNumberOfPeopleReg}
                inputRef={minNumberOfPeopleReg.ref}
                error={Boolean(errors.charter?.minNumberOfPeople)}
                type="number"
                variant="outlined"
                size="small"
                placeholder="1"
              />
            )}
          />
          <SuffixText text="人〜" />
          <Controller
            name="charter.maxNumberOfPeople"
            control={control}
            render={({ field }) => (
              <InputPeople
                {...field}
                {...maxNumberOfPeopleReg}
                inputRef={maxNumberOfPeopleReg.ref}
                error={Boolean(errors.charter?.maxNumberOfPeople)}
                type="number"
                variant="outlined"
                size="small"
                placeholder="5"
              />
            )}
          />
          <SuffixText text="人" />
          <Controller
            name="charter.price"
            control={control}
            render={({ field }) => (
              <InputPrice
                {...field}
                {...priceReg}
                inputRef={priceReg.ref}
                error={Boolean(errors.charter?.price)}
                type="number"
                variant="outlined"
                size="small"
                sx={{ paddingLeft: '40px' }}
                placeholder="50,000"
              />
            )}
          />
          <SuffixText text="円" />
        </Stack>
        {isDesktop && <Divider />}
        <Stack spacing={1}>
          <Typography variant="h5" component="span">
            追加人数の料金
          </Typography>
          <Typography
            variant="h6"
            component="span"
            color="subtitle.main"
            whiteSpace="pre-wrap"
          >
            {
              '上記人数を超えた場合、一人追加ごとにこの料金がプラスされます。\n設定しない又は0円で設定した場合は、人数を追加することはできません。'
            }
          </Typography>
          {fields.map((item, index) => (
            <InputCharterCategoryItem
              index={index}
              control={control}
              onChangeCategory={(category) => updateCategory(category, index)}
              onRemoveCategory={() => removeCategory(index)}
            />
          ))}
          <Button onClick={addCategory}>
            <Typography variant="h5" color="primary.main">
              追加する
            </Typography>
          </Button>
        </Stack>
        {isDesktop && <Divider />}
        <Stack spacing={1}>
          <Typography variant="h5" component="span">
            定員
          </Typography>
          <Typography
            variant="h6"
            component="span"
            color="subtitle.main"
            whiteSpace="pre-wrap"
          >
            {'仕立ての場合の乗船人数の定員をご入力ください。'}
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Controller
              name="charter.capacity"
              control={control}
              render={({ field }) => (
                <InputPrice
                  {...field}
                  {...capacityReg}
                  inputRef={capacityReg.ref}
                  error={Boolean(errors.charter?.capacity)}
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="10"
                />
              )}
            />
            <SuffixText text="人" />
          </Stack>
        </Stack>
      </Stack>
    </SectionContainer>
  )
}

export default InputCharterPriceSection
