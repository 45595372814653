import {
  Backdrop,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import BasicButton from '~/components/atoms/basicButton'
import Loading from '~/components/atoms/loading'
import BreadcrumbList from '~/components/molecules/breadcrumbList'
import InputTackleDescriptionSection from '~/components/molecules/tackleSections/inputTackleDescription'
import InputTackleImagesSection from '~/components/molecules/tackleSections/inputTackleImageSection'
import InputTackleTitleSection from '~/components/molecules/tackleSections/inputTackleTitleSection'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { TackleBase } from '~/model/account/tackle'
import {
  addTackle,
  getTackle,
  updateTackle,
} from '~/repositories/tackleRepository'

interface Props {
  // tackleIdがある場合は編集モード
  tackleId?: string
}

const TacklesWriteBody: FC<Props> = ({ tackleId }) => {
  const [isWriting, setIsWriting] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const form = useForm<TackleBase>()
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const navigate = useNavigate()

  const title = useMemo(
    () => (tackleId ? 'タックルを編集' : 'タックルを追加'),
    [tackleId]
  )

  useEffect(() => {
    if (!currentUser) return
    // 初期化処理
    if (tackleId) {
      // 編集画面
      const cleanup = async () => {
        const tackle = await getTackle(currentUser.user.uid, tackleId)
        form.setValue('title', tackle.title)
        form.setValue('description', tackle.description)
        form.setValue('imageUrlList', tackle.imageUrlList)
        setInitialized(true)
      }
      cleanup()
    } else {
      // 追加画面
      form.setValue('title', '')
      form.setValue('imageUrlList', [])
      setInitialized(true)
    }
  }, [tackleId])

  const onSubmit: SubmitHandler<TackleBase> = async (data) => {
    if (!currentUser) return
    setIsWriting(true)
    try {
      if (tackleId) {
        await updateTackle(currentUser.user.uid, tackleId, data)
      } else {
        await addTackle(currentUser.user.uid, data)
        navigate('/tackles', { replace: true })
      }
      toggleAlert(
        true,
        'success',
        tackleId ? 'タックルを更新しました！' : 'タックルを追加しました！'
      )
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsWriting(false)
    console.log(data)
  }

  return (
    <Stack width="100%" spacing={1.5}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWriting}
      >
        <Loading width={150} height={150} />
      </Backdrop>
      {initialized && (
        <Stack spacing={4}>
          <BreadcrumbList
            items={[
              { name: 'タックル一覧', to: '/tackles' },
              { name: 'タックルを追加' },
            ]}
          />
          <Stack component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Typography variant={isDesktop ? 'h1' : 'h2'}>{title}</Typography>
            <InputTackleTitleSection {...form} />
            <Divider />
            <InputTackleImagesSection {...form} />
            <Divider />
            <InputTackleDescriptionSection {...form} />
            <Stack
              paddingTop="24px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <BasicButton
                type="reset"
                title="キャンセル"
                color="info"
                sx={{ width: '140px' }}
                onClick={() => navigate('/tackles', { replace: true })}
              />
              <BasicButton
                type="submit"
                title={tackleId ? '更新する' : '登録する'}
                sx={{ width: '220px' }}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default TacklesWriteBody
