import { Link, Typography } from '@mui/material'
import { FC, useMemo, useState } from 'react'

import InputText from '~/components/atoms/inputText'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { updateGuideYouTubeVideoId } from '~/repositories/guideRepository'

interface OwnProps {
  youtubeId?: string
  onUpdated?: (videoId: string) => void
}

type Props = OwnProps & EditableSectionProps

const SectionProfileYouTube: FC<Props> = ({
  youtubeId,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [currentYouTubeId, setCurrentYouTubeId] = useState(youtubeId)

  const onSubmit = async () => {
    if (!currentUser || !currentYouTubeId) return
    setIsLoading(true)
    try {
      await updateGuideYouTubeVideoId(currentUser.user.uid, currentYouTubeId)
      onUpdated?.(currentYouTubeId)
      toggleAlert(true, 'success', 'YouTubeビデオIDを更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  const youtubeLink = useMemo(
    () => youtubeId && `https://www.youtube.com/watch?v=${youtubeId}`,
    [youtubeId]
  )

  return (
    <EditableSectionContainer
      title="YouTubeビデオID"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentYouTubeId(youtubeId)
        onClickMode?.()
      }}
    >
      {!editing && youtubeLink && (
        <Link href={youtubeLink} target="_blank">
          <Typography
            variant="subtitle2"
            component="span"
            color="primary.main"
            sx={{ wordBreak: 'break-all' }}
          >
            {youtubeLink}
          </Typography>
        </Link>
      )}
      {editing && (
        <InputText
          size="small"
          value={currentYouTubeId}
          placeholder="fZIBjOiClP4"
          onChange={(e) => setCurrentYouTubeId(e.target.value)}
        />
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileYouTube
