import { Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC } from 'react'

import BasicButton from '~/components/atoms/basicButton'

interface Props {
  loading?: boolean
}

const ConfirmationButton: FC<Props> = ({ loading }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: '16px',
      }}
    >
      <Box sx={{ typography: 'h6' }}>
        「同意して認証に進む」をクリックすることで、お客様が「ツリトリ」の
        <Link
          href="https://tsuritori.jp/terms"
          underline="always"
          color="secondary.main"
          target="_blank"
        >
          <Typography variant="h6" component="span">
            利用規約
          </Typography>
        </Link>
        、
        <Link
          href="https://tsuritori.jp/policy"
          underline="always"
          color="secondary.main"
          target="_blank"
        >
          <Typography variant="h6" component="span">
            プライバシーポリシー
          </Typography>
        </Link>
        に同意するものとします。
      </Box>
      <BasicButton
        id="confirmation_button"
        title="同意して認証に進む"
        type="submit"
        loading={loading}
      />
    </Box>
  )
}

export default ConfirmationButton
