import { Stack, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { Control, useWatch } from 'react-hook-form'

import BasicButton from '~/components/atoms/basicButton'
import InputText from '~/components/atoms/inputText'
import { BoardingProcedureBase } from '~/model/account/boardingProcedure'
import { Step } from '~/model/account/step'

interface OwnProps {
  index: number
  control: Control<BoardingProcedureBase, any>
  onChangeStep?: (step: Step) => void
  onRemoveStep?: () => void
}

const InputBoardingProcedureStepItem: FC<OwnProps> = ({
  index,
  control,
  onChangeStep,
  onRemoveStep,
}) => {
  const value = useWatch({ control: control, name: `steps.${index}` })

  const onChangeTitle = (newTitle: string) => {
    const newStep: Step = { ...value, title: newTitle }
    onChangeStep?.(newStep)
  }

  const onChangeDescription = (newDescription: string) => {
    const newStep: Step = {
      ...value,
      description: newDescription,
    }
    onChangeStep?.(newStep)
  }

  return (
    <Stack spacing={1.5} width="100%">
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`STEP${value.step}: タイトル`}
        </Typography>
        <InputText
          size="small"
          placeholder="ステップのタイトル"
          value={value.title}
          onChange={(e) => onChangeTitle(e.target.value)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`STEP${value.step}: 説明`}
        </Typography>
        <TextField
          value={value.description}
          placeholder="ステップの説明"
          variant="outlined"
          multiline
          rows={5}
          sx={{
            ['& fieldset']: { borderRadius: '8px' },
            width: '100%',
          }}
          onChange={(e) => onChangeDescription(e.target.value)}
        />
      </Stack>
      <BasicButton
        title="削除する"
        color="error"
        sx={{ width: '120px', height: '40px' }}
        onClick={onRemoveStep}
      />
    </Stack>
  )
}

export default InputBoardingProcedureStepItem
