import { TextField } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'
import { getTimeDate, getTimeNumber } from '~/utils/utils'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const SelectMeetingTimeSection: FC<Props> = ({
  register,
  setValue,
  getValues,
  formState: { errors },
  clearErrors,
  title,
}) => {
  const [meetingTime, setMeetingTime] = useState<Date | null>(
    getValues('meetingTime') == null
      ? null
      : getTimeDate(getValues('meetingTime'))
  )

  useEffect(() => {
    register('meetingTime', { required: true })
  }, [])

  useEffect(() => {
    if (meetingTime) {
      setValue('meetingTime', getTimeNumber(meetingTime))
      clearErrors('meetingTime')
    }
  }, [meetingTime])

  return (
    <SectionContainer title={title}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          value={meetingTime}
          onChange={(d) => setMeetingTime(d)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.meetingTime)}
              variant="outlined"
              sx={{ ['& fieldset']: { borderRadius: '8px' } }}
              placeholder="集合時間"
            />
          )}
        />
      </LocalizationProvider>
    </SectionContainer>
  )
}

export default SelectMeetingTimeSection
