import { Stack, Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  title: string
  value: string
}

const SectionDisplayBankAccount: FC<Props> = ({ title, value }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <Typography variant="subtitle2" component="span" color="secondary.main">
        {value}
      </Typography>
    </Stack>
  )
}

export default SectionDisplayBankAccount
