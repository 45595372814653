export enum URLPath {
  Root = '/',
  LogIn = '/login',
  Help = '/help',
  Plans = '/plans',
  Boats = '/boats',
  Tackles = '/tackles',
  BoardingProcedures = 'boarding-procedures',
  FishingSeatProcedures = '/fishing-seat-procedures',
  PlansAdd = '/plans/add',
  BoatsAdd = '/boats/add',
  TacklesAdd = '/tackles/add',
  BoardingProceduresAdd = 'boarding-procedures/add',
  FishingSeatProceduresAdd = '/fishing-seat-procedures/add',
  Incomes = '/incomes',
  Account = '/account',
}
