export const RESERVABLE_RANGE_TYPE = {
  notSet: 'not_set',
  twelveMonthsAhead: '12_months_ahead',
  nineMonthsAhead: '9_months_ahead',
  sixMonthsAhead: '6_months_ahead',
  threeMonthsAhead: '3_months_ahead',
  twoMonthsAhead: '2_months_ahead',
  monthAhead: 'month_ahead',
} as const

export type ReservableRangeType =
  typeof RESERVABLE_RANGE_TYPE[keyof typeof RESERVABLE_RANGE_TYPE]
