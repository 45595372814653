import { FC } from 'react'

import Container from '~/components/organisms/container'
import Footer from '~/components/organisms/footer'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import HelpTop from '~/components/organisms/helpTop'

const HelpTemplate: FC = () => {
  return (
    <Container>
      <Head />
      <Header disableItems />
      <HelpTop />
      <Footer />
    </Container>
  )
}

export default HelpTemplate
