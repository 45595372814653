export const getTimeNumber = (date: Date): number => {
  return date.getHours() * 100 + date.getMinutes()
}

export const getTimeDate = (time: number): Date => {
  const date = new Date()

  const hours = Math.floor(time / 100)
  const minutes = time % 100
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}
