import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import { UploadType } from '~/model/uploadType'
import storage from '~/utils/storage'

export const uploadFile = async (file: File, uid: string, type: UploadType) => {
  const storageRef = ref(
    storage,
    `guides/${uid}/${type}/${Date.now()}.${file.name.split('.').pop()}`
  )
  const result = await uploadBytes(storageRef, file)
  return await getDownloadURL(result.ref)
}
