import { Button, styled, Typography, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/material'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import NoImageSVG from '~/components/svgs/no_image.svg'
import { Boat } from '~/model/account/boat'

const StyledImg = styled('img')({
  width: '76px',
  minWidth: '76px',
  height: '76px',
  minHeight: '76px',
})

interface Props {
  boat: Boat
}

const BoatItem: FC<Props> = ({ boat }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const navigate = useNavigate()

  const thumbnailImage = useMemo(() => {
    const thumbnail = boat.imageUrlList[0]
    return thumbnail ? thumbnail : NoImageSVG
  }, [boat.imageUrlList])

  return (
    <Button
      color="secondary"
      onClick={() => navigate(`/boats/edit/${boat.documentId}`)}
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        spacing={2}
        padding="12px 0"
      >
        <StyledImg src={thumbnailImage} />
        {/* desktop */}
        {isDesktop && (
          <>
            <Typography flex={1} variant="h4" component="div" textAlign="left">
              {boat.name}
            </Typography>
          </>
        )}
        {/* mobile */}
        {!isDesktop && (
          <Stack spacing={1}>
            <Typography variant="h4" component="div" textAlign="left">
              {boat.name}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Button>
  )
}

export default BoatItem
