import { Stack, useMediaQuery } from '@mui/material'
import { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import AccountAccessBody from '~/components/organisms/accountAccessBody'
import AccountBankBody from '~/components/organisms/accountBankBody'
import AccountContactMethodBody from '~/components/organisms/accountContactMethodBody'
import AccountProfileBody from '~/components/organisms/accountProfileBody'
import AccountSideNavigation from '~/components/organisms/accountSideNavigation'
import Container from '~/components/organisms/container'
import ContentContainer from '~/components/organisms/contentContainer'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import NotPermittedBody from '~/components/organisms/notPermittedBody'
import { useAuthContext } from '~/components/providers/auth'

const AccountTemplate: FC = () => {
  const { currentUser } = useAuthContext()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const isDesktop = useMediaQuery('(min-width: 841px)')

  const pageQuery = useMemo(() => {
    return query.get('page')
  }, [query])

  return (
    <Container>
      <Head />
      <Header />
      <ContentContainer>
        {currentUser && currentUser.isPermittedGuide && (
          <Stack
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
          >
            {isDesktop && <AccountSideNavigation query={pageQuery} />}
            {!pageQuery && <AccountProfileBody />}
            {pageQuery === 'access' && <AccountAccessBody />}
            {pageQuery === 'contact-method' && <AccountContactMethodBody />}
            {pageQuery === 'bank-account' && <AccountBankBody />}
          </Stack>
        )}
        {currentUser && !currentUser.isPermittedGuide && <NotPermittedBody />}
      </ContentContainer>
    </Container>
  )
}

export default AccountTemplate
