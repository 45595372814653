import { Stack, TextField } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'
import { getTimeDate, getTimeNumber } from '~/utils/utils'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const SelectStartAndEndTimeSection: FC<Props> = ({
  register,
  setValue,
  getValues,
  formState: { errors },
  clearErrors,
  title,
}) => {
  const [startTime, setStartTime] = useState<Date | null>(
    getValues('startTime') == null ? null : getTimeDate(getValues('startTime'))
  )
  const [endTime, setEndTime] = useState<Date | null>(
    getValues('endTime') == null ? null : getTimeDate(getValues('endTime'))
  )

  useEffect(() => {
    register('startTime', { required: true })
    register('endTime', { required: true })
  }, [])

  useEffect(() => {
    if (startTime) {
      setValue('startTime', getTimeNumber(startTime))
      clearErrors('startTime')
    }
  }, [startTime])

  useEffect(() => {
    if (endTime) {
      setValue('endTime', getTimeNumber(endTime))
      clearErrors('endTime')
    }
  }, [endTime])

  return (
    <SectionContainer title={title}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={2.5} alignItems="center">
          <TimePicker
            label="出船時間"
            value={startTime}
            onChange={setStartTime}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.startTime)}
                variant="outlined"
                sx={{ ['& fieldset']: { borderRadius: '8px' } }}
                placeholder="出船時間"
              />
            )}
          />
          <span>〜</span>
          <TimePicker
            label="帰港時間"
            value={endTime}
            onChange={setEndTime}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.endTime)}
                variant="outlined"
                sx={{ ['& fieldset']: { borderRadius: '8px' } }}
                placeholder="帰港時間"
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </SectionContainer>
  )
}

export default SelectStartAndEndTimeSection
