import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { HelpSectionModel } from '~/model/help/helpSection'

const HelpSection: FC<HelpSectionModel> = ({ id, title, articles }) => {
  return (
    <Stack spacing={1.5} width="100%">
      <Typography variant="h2">{title}</Typography>
      {articles.map((article) => (
        <Link to={`/help/section/${id}/article/${article.id}`}>
          <Typography
            variant="subtitle1"
            color="secondary.main"
            sx={{ textDecoration: 'underline' }}
          >
            {article.title}
          </Typography>
        </Link>
      ))}
    </Stack>
  )
}

export default HelpSection
