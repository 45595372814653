import './style.css'

import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { GeoPoint } from 'firebase/firestore'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import BasicButton from '~/components/atoms/basicButton'
import GMap from '~/components/atoms/googlemap'
import InputText from '~/components/atoms/inputText'
import SelectedMenu from '~/components/atoms/selectedMenu'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as CopyLogo } from '~/components/svgs/content_copy.svg'
import { useDebounce } from '~/hooks/useDebounce'
import { Access } from '~/model/account/access'
import { updateAccess } from '~/repositories/accessRepository'

import { PrefectureArray } from './prefectures'

interface OwnProps {
  access: Access
  onUpdated?: (access: Access) => void
}

type Props = OwnProps & EditableSectionProps

// TODO: デカすぎるので、リファクタしたい

const SectionProfileAccess: FC<Props> = ({
  access,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  // For Editing item.
  const [currentGeoCoordinate, setCurrentGeoCoordinate] = useState(
    access?.location
  )
  const [searchGeoCoordinateLati, setSearchGeoCoordinateLati] = useState(
    access?.location.latitude.toString()
  )
  const [searchGeoCoordinateLong, setSearchGeoCoordinateLong] = useState(
    access?.location.longitude.toString()
  )
  const [currentPostalCode, setCurrentPostalCode] = useState(access?.postalCode)
  const [currentAddress, setCurrentAddress] = useState<{
    prefecture?: number
    city?: string
    block?: string
    building?: string
  }>({
    prefecture: access?.prefecture,
    city: access?.city,
    block: access?.block,
    building: access?.building,
  })
  // const [currentParkingType, setCurrentParkingType] = useState(
  //   access?.parkingType
  // )
  // const [currentCarInformation, setCurrentCarInformation] = useState(
  //   access?.carInformation
  // )
  // const [currentTrainInformation, setCurrentTrainInformation] = useState(
  //   access?.trainInformation
  // )
  // const [currentMoreInformationLink, setCurrentMoreInformationLink] = useState(
  //   access?.moreInformationLink
  // )

  const debGeoCoordinateLati = useDebounce(searchGeoCoordinateLati, 2000)
  const debGeoCoordinateLong = useDebounce(searchGeoCoordinateLong, 2000)

  useEffect(() => {
    const lati = Number(debGeoCoordinateLati)
    const long = Number(debGeoCoordinateLong)
    if (lati && long) {
      setCurrentGeoCoordinate(new GeoPoint(lati, long))
    }
  }, [debGeoCoordinateLati, debGeoCoordinateLong])

  const searchAddressWithPostalCode = useCallback(async () => {
    const { results } = await new google.maps.Geocoder().geocode({
      componentRestrictions: {
        country: 'JP',
        postalCode: currentPostalCode,
      },
    })
    const item = results[0]
    if (item) {
      const prefectureLabel =
        item.address_components.filter((c) =>
          c.types.includes('administrative_area_level_1')
        )[0].short_name ?? ''

      const prefecture = PrefectureArray.find(
        (v) => v.label === prefectureLabel
      )?.id

      const locality =
        item.address_components.filter((c) => c.types.includes('locality'))[0]
          ?.short_name ?? ''
      const sub1 =
        item.address_components.filter((c) =>
          c.types.includes('sublocality_level_1')
        )[0]?.short_name ?? ''
      const sub2 =
        item.address_components.filter((c) =>
          c.types.includes('sublocality_level_2')
        )[0]?.short_name ?? ''
      const sub3 =
        item.address_components.filter((c) =>
          c.types.includes('sublocality_level_3')
        )[0]?.short_name ?? ''
      const sub4 =
        item.address_components.filter((c) =>
          c.types.includes('sublocality_level_4')
        )[0]?.short_name ?? ''
      const sub5 =
        item.address_components.filter((c) =>
          c.types.includes('sublocality_level_5')
        )[0]?.short_name ?? ''

      const city = locality + sub1 + sub2 + sub3 + sub4 + sub5

      setCurrentAddress({
        prefecture: prefecture,
        city: city,
        block: '',
        building: '',
      })
    }

    console.log(results[0])
  }, [currentPostalCode])

  const selectedPrefectureIndex = useMemo(() => {
    if (!currentAddress.prefecture) return undefined
    const index = PrefectureArray.findIndex(
      (v) => v.id === currentAddress.prefecture
    )
    return index !== -1 ? index : undefined
  }, [currentAddress.prefecture])

  // const selectedParkingTypeIndex = useMemo(() => {
  //   if (!currentParkingType) return undefined
  //   return Object.values(PARKING_TYPE).indexOf(currentParkingType)
  // }, [currentParkingType])

  const handlePrefectureChange = (index: number) => {
    const newPrefecture = PrefectureArray[index]
    setCurrentAddress({
      ...currentAddress,
      prefecture: newPrefecture.id,
    })
  }

  // const handleParkingChange = (index: number) => {
  //   const newParkingType = Object.values(PARKING_TYPE)[index]
  //   setCurrentParkingType(newParkingType)
  // }

  const onSubmit = async () => {
    const { prefecture, city, block, building } = currentAddress
    if (
      currentUser &&
      currentGeoCoordinate &&
      currentPostalCode &&
      prefecture &&
      city &&
      block
      // && currentParkingType
    ) {
      // 郵便番号、都道府県、市区町村、番地、建物名、地理地点
      const newAccess: Access = {
        ...access,
        postalCode: currentPostalCode,
        prefecture: prefecture,
        city: city,
        block: block,
        building: building,
        location: currentGeoCoordinate,
      }
      setIsLoading(true)
      try {
        await updateAccess(access.documentId, newAccess)
        onUpdated?.(newAccess)
        toggleAlert(true, 'success', 'アクセス情報を更新しました！')
      } catch (e) {
        toggleAlert(true, 'error', e)
      }
      setIsLoading(false)
    }
  }

  return (
    <EditableSectionContainer
      title="集合場所"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setSearchGeoCoordinateLati(access.location.latitude.toString())
        setSearchGeoCoordinateLong(access.location.longitude.toString())
        setCurrentGeoCoordinate(access?.location)
        setCurrentAddress({
          prefecture: access?.prefecture || 0,
          city: access?.city || '',
          block: access?.block || '',
          building: access?.building,
        })
        // setCurrentParkingType(access?.parkingType)
        // setCurrentCarInformation(access?.carInformation)
        // setCurrentTrainInformation(access?.trainInformation)
        // setCurrentMoreInformationLink(access?.moreInformationLink)
        onClickMode?.()
      }}
    >
      {!editing && access && (
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography
              variant="subtitle2"
              component="div"
              color="secondary.main"
            >
              {`〒${access.postalCode} ${access.prefecture}${access.city}${access.block}${access.building}`}
            </Typography>
            <IconButton>
              <CopyLogo />
            </IconButton>
          </Stack>
          <GMap
            position={{
              lat: access.location.latitude,
              lng: access.location.longitude,
            }}
            containerStyle={{
              width: isDesktop ? '562px' : '100%',
              height: isDesktop ? '357px' : 'calc(100vw * 0.5625)',
            }}
          />
        </Stack>
      )}
      {editing && (
        <Stack spacing={3} paddingBottom="24px">
          <Stack spacing={2.5}>
            <Stack direction="row" spacing={2.5}>
              <Stack spacing={2.5}>
                <InputText
                  size="small"
                  name="郵便番号"
                  placeholder="220-0011"
                  value={currentPostalCode}
                  onChange={(e) => setCurrentPostalCode(e.target.value)}
                />
                <SelectedMenu
                  options={PrefectureArray.map((v) => v.label)}
                  selectedIndex={selectedPrefectureIndex}
                  placeholder="都道府県を選択"
                  onChangeSelected={handlePrefectureChange}
                />
                <InputText
                  size="small"
                  name="市区町村"
                  placeholder="横浜市西区高島"
                  value={currentAddress.city}
                  onChange={(e) =>
                    setCurrentAddress({
                      ...currentAddress,
                      city: e.target.value,
                    })
                  }
                />
                <InputText
                  size="small"
                  name="番地"
                  placeholder="2-11-2"
                  value={currentAddress.block}
                  onChange={(e) =>
                    setCurrentAddress({
                      ...currentAddress,
                      block: e.target.value,
                    })
                  }
                />
              </Stack>
              <BasicButton
                title="住所検索"
                color="secondary"
                sx={{ width: '110px', height: '40px' }}
                onClick={searchAddressWithPostalCode}
              />
            </Stack>
            <InputText
              size="small"
              name="建物名・部屋番号"
              placeholder="スカイメナー横浜519"
              value={currentAddress.building}
              onChange={(e) =>
                setCurrentAddress({
                  ...currentAddress,
                  building: e.target.value,
                })
              }
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" component="span">
              地図上の位置
            </Typography>
            <Stack direction="row" spacing={isDesktop ? 2 : 1.5}>
              <InputText
                size="small"
                name="経度"
                placeholder="経度"
                value={searchGeoCoordinateLati}
                onChange={(e) => {
                  setSearchGeoCoordinateLati(e.target.value)
                }}
              />
              <InputText
                size="small"
                name="緯度"
                placeholder="緯度"
                value={searchGeoCoordinateLong}
                onChange={(e) => {
                  setSearchGeoCoordinateLong(e.target.value)
                }}
              />
            </Stack>
            <GMap
              position={
                currentGeoCoordinate && {
                  lat: currentGeoCoordinate.latitude,
                  lng: currentGeoCoordinate.longitude,
                }
              }
              containerStyle={{
                width: isDesktop ? '562px' : '100%',
                height: isDesktop ? '357px' : 'calc(100vw * 0.5625)',
              }}
            />
          </Stack>
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileAccess
