import { Stack, Typography, useMediaQuery } from '@mui/material'
import { GeoPoint } from 'firebase/firestore'
import { FC, useState } from 'react'

import GMap from '~/components/atoms/googlemap'
import InputText from '~/components/atoms/inputText'
import SelectedMenu from '~/components/atoms/selectedMenu'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Access } from '~/model/account/access'
import { updateAccess } from '~/repositories/accessRepository'

interface OwnProps {
  access: Access
  onUpdated?: (access: Access) => void
}

type Props = OwnProps & EditableSectionProps

const SectionProfileAccessParking: FC<Props> = ({
  access,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  // For Editing item.
  const [currentLocationLati, setSearchGeoCoordinateLati] = useState(
    access.firstParking.location.latitude.toString()
  )
  const [currentLocationLong, setSearchGeoCoordinateLong] = useState(
    access.firstParking.location.longitude.toString()
  )
  const [currentTitle, setCurrentTitle] = useState(access.firstParking.title)
  const [currentIsPaid, setCurrentIsPaid] = useState(access.firstParking.isPaid)

  const onSubmit = async () => {
    const locationLati = Number(currentLocationLati)
    const locationLong = Number(currentLocationLong)
    if (
      currentUser &&
      locationLati != null &&
      locationLong != null &&
      currentTitle != null &&
      currentIsPaid != null
    ) {
      // 駐車場1
      // 地理地点、タイトル、有料無料
      const newAccess: Access = {
        ...access,
        firstParking: {
          location: new GeoPoint(locationLati, locationLong),
          title: currentTitle,
          isPaid: currentIsPaid,
        },
      }
      setIsLoading(true)
      try {
        await updateAccess(access.documentId, newAccess)
        onUpdated?.(newAccess)
        toggleAlert(true, 'success', '駐車場1の情報を更新しました！')
      } catch (e) {
        toggleAlert(true, 'error', e)
      }
      setIsLoading(false)
    }
  }

  return (
    <EditableSectionContainer
      title="駐車場1"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setSearchGeoCoordinateLati(
          access.firstParking.location.latitude.toString()
        )
        setSearchGeoCoordinateLong(
          access.firstParking.location.longitude.toString()
        )
        setCurrentTitle(access.firstParking.title)
        setCurrentIsPaid(access.firstParking.isPaid)
        onClickMode?.()
      }}
    >
      {!editing && access && (
        <Stack spacing={2}>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.main"
          >
            {`名称: ${access.firstParking.title}`}
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.main"
          >
            {`駐車場料金: ${access.firstParking.isPaid ? '有料' : '無料'}`}
          </Typography>
          <GMap
            position={{
              lat: access.firstParking.location.latitude,
              lng: access.firstParking.location.longitude,
            }}
            containerStyle={{
              width: isDesktop ? '562px' : '100%',
              height: isDesktop ? '357px' : 'calc(100vw * 0.5625)',
            }}
          />
        </Stack>
      )}
      {editing && (
        <Stack spacing={3} paddingBottom="24px">
          <Stack spacing={2.5}>
            <Stack direction="row" spacing={2.5}>
              <Stack spacing={2.5}>
                <InputText
                  size="small"
                  name="駐車場の名称"
                  placeholder="第一駐車場"
                  value={currentTitle}
                  onChange={(e) => setCurrentTitle(e.target.value)}
                />
                <Stack spacing={1.5}>
                  <Typography variant="h4" component="span">
                    料金
                  </Typography>
                  <SelectedMenu
                    options={['無料', '有料']}
                    selectedIndex={currentIsPaid ? 1 : 0}
                    onChangeSelected={(index) => {
                      if (index === 0) {
                        setCurrentIsPaid(false)
                      } else if (index === 1) {
                        setCurrentIsPaid(true)
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" component="span">
              地図上の位置
            </Typography>
            <Stack direction="row" spacing={isDesktop ? 2 : 1.5}>
              <InputText
                size="small"
                name="経度"
                placeholder="経度"
                value={currentLocationLati}
                onChange={(e) => {
                  setSearchGeoCoordinateLati(e.target.value)
                }}
              />
              <InputText
                size="small"
                name="緯度"
                placeholder="緯度"
                value={currentLocationLong}
                onChange={(e) => {
                  setSearchGeoCoordinateLong(e.target.value)
                }}
              />
            </Stack>
            <GMap
              position={{
                lat: Number(currentLocationLati),
                lng: Number(currentLocationLong),
              }}
              containerStyle={{
                width: isDesktop ? '562px' : '100%',
                height: isDesktop ? '357px' : 'calc(100vw * 0.5625)',
              }}
            />
          </Stack>
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileAccessParking
