import { onAuthStateChanged, User as AuthUser } from 'firebase/auth'
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'

import { getPermitted } from '~/repositories/guideRepository'
import auth from '~/utils/auth'

interface AdminAuth {
  user: AuthUser
  isPermittedGuide: boolean
}

interface AuthContextValue {
  status:
    | 'beforeLogin'
    | 'needLogin'
    | 'loading'
    | 'not_found_auth'
    | 'not_found_guide'
    | 'compleated'
  currentUser?: AdminAuth
  error?: Error
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined)

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}

interface Props {
  children: React.ReactNode
}

export const AuthProvider: FC<Props> = (props) => {
  const [status, setStatus] =
    useState<AuthContextValue['status']>('beforeLogin')
  const [currentUser, setCurrentUser] =
    useState<AuthContextValue['currentUser']>()
  const [error, setError] = useState<AuthContextValue['error']>()

  useEffect(() => {
    onAuthStateChanged(
      auth,
      async (user) => {
        setError(undefined)
        if (user) {
          const isPermitted = await getPermitted(user.uid)
          setCurrentUser({ user, isPermittedGuide: isPermitted })
          setStatus('compleated')
        } else {
          setCurrentUser(undefined)
          setStatus('needLogin')
        }
      },
      (error) => {
        setError(error)
        setStatus('not_found_auth')
      }
    )
  }, [])

  return (
    <AuthContext.Provider value={{ status, currentUser, error }} {...props} />
  )
}
