import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import {
  BoardingProcedure,
  BoardingProcedureBase,
  BoardingProcedureWrite,
  FirestoreBoardingProcedure,
} from '~/model/account/boardingProcedure'
import { getGuideBoardingProceduresPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getBoardingProcedures = async (uid: string) => {
  const boardingProceduresPath = getGuideBoardingProceduresPath(uid)
  const ref = collection(firestore, boardingProceduresPath).withConverter(
    converter<FirestoreBoardingProcedure>()
  )
  const q = query(ref, limit(100))
  const snaps = await getDocs(q)

  return snaps.docs.map((doc) => {
    const boardingProcedure: BoardingProcedure = {
      ...doc.data(),
      documentId: doc.id,
    }
    return boardingProcedure
  })
}

export const getBoardingProcedure = async (
  uid: string,
  boardingProcedureId: string
) => {
  const boardingProceduresPath = getGuideBoardingProceduresPath(uid)
  const ref = doc(
    firestore,
    boardingProceduresPath,
    boardingProcedureId
  ).withConverter(converter<FirestoreBoardingProcedure>())
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const boardingProcedure: BoardingProcedure = {
      ...snap.data(),
      documentId: snap.id,
    }
    return boardingProcedure
  } else {
    throw new Error('Document not found.')
  }
}

export const addBoardingProcedure = async (
  uid: string,
  data: BoardingProcedureBase
) => {
  const boardingProceduresPath = getGuideBoardingProceduresPath(uid)
  const ref = doc(collection(firestore, boardingProceduresPath)).withConverter(
    converter<FirestoreBoardingProcedure>()
  )

  const writeData: BoardingProcedureWrite = {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  await setDoc(ref, writeData)
}

export const updateBoardingProcedure = async (
  uid: string,
  boardingProcedureId: string,
  data: BoardingProcedureBase
) => {
  const boardingProceduresPath = getGuideBoardingProceduresPath(uid)
  const ref = doc(
    firestore,
    boardingProceduresPath,
    boardingProcedureId
  ).withConverter(converter<FirestoreBoardingProcedure>())

  const writeData = { ...data, updatedAt: serverTimestamp() }

  await setDoc(ref, writeData, { merge: true })
}
