import { Stack, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as AccountLogo } from '~/components/svgs/account.svg'
import { ReactComponent as PlanLogo } from '~/components/svgs/plan.svg'
import { ReactComponent as GuideLogo } from '~/components/svgs/ship.svg'

interface Props {
  type:
    | 'plan'
    | 'boat'
    | 'tackle'
    | 'boarding-procedure'
    | 'fishing-seat-procedure'
    | 'account'
}

const HeaderItem: FC<Props> = ({ type }) => {
  const logo = useMemo(() => {
    switch (type) {
      case 'plan':
        return <PlanLogo />
      case 'boat':
        return <GuideLogo />
      case 'tackle':
        return <GuideLogo />
      case 'boarding-procedure':
        return <GuideLogo />
      case 'fishing-seat-procedure':
        return <GuideLogo />
      case 'account':
        return <AccountLogo />
    }
  }, [type])

  const title = useMemo(() => {
    switch (type) {
      case 'plan':
        return '釣りプラン'
      case 'boat':
        return '船の設備'
      case 'tackle':
        return 'タックル'
      case 'boarding-procedure':
        return '乗船手順'
      case 'fishing-seat-procedure':
        return '釣り座'
      case 'account':
        return 'アカウント'
    }
  }, [type])

  const to = useMemo(() => {
    switch (type) {
      case 'plan':
        return '/plans'
      case 'boat':
        return '/boats'
      case 'tackle':
        return '/tackles'
      case 'boarding-procedure':
        return '/boarding-procedures'
      case 'fishing-seat-procedure':
        return '/fishing-seat-procedures'
      case 'account':
        return '/account'
    }
  }, [type])

  return (
    <Link to={to}>
      <Stack direction="column" alignItems="center" spacing={0.5} width="90px">
        {logo}
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {title}
        </Typography>
      </Stack>
    </Link>
  )
}

export default HeaderItem
