import { Button, styled, Typography, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/material'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import NoImageSVG from '~/components/svgs/no_image.svg'
import { Tackle } from '~/model/account/tackle'

const StyledImg = styled('img')({
  width: '76px',
  minWidth: '76px',
  height: '76px',
  minHeight: '76px',
})

interface Props {
  tackle: Tackle
}

const TackleItem: FC<Props> = ({ tackle }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const navigate = useNavigate()

  const thumbnailImage = useMemo(() => {
    const thumbnail = tackle.imageUrlList[0]
    return thumbnail ? thumbnail : NoImageSVG
  }, [tackle.imageUrlList])

  return (
    <Button
      color="secondary"
      onClick={() => navigate(`/tackles/edit/${tackle.documentId}`)}
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        spacing={2}
        padding="12px 0"
      >
        <StyledImg src={thumbnailImage} />
        {/* desktop */}
        {isDesktop && (
          <>
            <Typography flex={1} variant="h4" component="div" textAlign="left">
              {tackle.title}
            </Typography>
          </>
        )}
        {/* mobile */}
        {!isDesktop && (
          <Stack spacing={1}>
            <Typography variant="h4" component="div" textAlign="left">
              {tackle.title}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Button>
  )
}

export default TackleItem
