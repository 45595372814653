import { Stack, useMediaQuery } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'

interface Props {
  children?: ReactNode
}

const ContentContainer: FC<Props> = ({ children }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const headerHeight = useMemo(() => (isDesktop ? '84px' : '50px'), [isDesktop])
  const footerHeight = '274px'
  const containerHeight = useMemo(
    () =>
      `calc(100vh - ${headerHeight} - ${footerHeight})`,
    [isDesktop, headerHeight]
  )

  return (
    <Stack
      minHeight={containerHeight}
      width={'100%'}
      maxWidth={isDesktop ? '1200px' : '100%'}
      padding={isDesktop ? '32px 24px 60px 24px' : '20px 16px 50px 16px'}
      margin="0 auto"
      alignItems="center"
      boxSizing="border-box"
    >
      {children}
    </Stack>
  )
}

export default ContentContainer
