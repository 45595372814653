import { FC } from 'react'

import Container from '~/components/organisms/container'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import HelpArticleBody from '~/components/organisms/helpArticleBody'

const HelpArticleTemplate: FC = () => {
  return (
    <Container>
      <Head />
      <Header disableItems />
      <HelpArticleBody />
    </Container>
  )
}

export default HelpArticleTemplate
