import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore'

import { CollectionPath } from '~/model/path'
import { FirestorePlan, Plan, PlanBase, PlanWrite } from '~/model/plan/plan'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getPlans = async (uid: string) => {
  const ref = collection(firestore, CollectionPath.Plans).withConverter(
    converter<FirestorePlan>()
  )
  const q = query(ref, where('guideUid', '==', uid), limit(100))
  const snaps = await getDocs(q)

  return snaps.docs.map((doc) => {
    const plan: Plan = { ...doc.data(), documentId: doc.id }
    return plan
  })
}

export const getPlan = async (planId: string) => {
  const ref = doc(firestore, CollectionPath.Plans, planId).withConverter(
    converter<FirestorePlan>()
  )
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const plan: Plan = { ...snap.data(), documentId: snap.id }
    return plan
  } else {
    throw new Error('Document not found.')
  }
}

export const addPlan = async (data: PlanBase) => {
  const ref = doc(collection(firestore, CollectionPath.Plans)).withConverter(
    converter<FirestorePlan>()
  )

  const writeData: PlanWrite = {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  await setDoc(ref, writeData)
}

export const updatePlan = async (planId: string, data: PlanBase) => {
  const ref = doc(firestore, CollectionPath.Plans, planId).withConverter(
    converter<FirestorePlan>()
  )

  const writeData = { ...data, updatedAt: serverTimestamp() }

  await setDoc(ref, writeData, { merge: true })
}
