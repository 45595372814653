import { Grid, styled } from '@mui/material'
import { FC, useState } from 'react'

import InputImage from '~/components/atoms/inputImage'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { UPLOAD_TYPE } from '~/model/uploadType'
import { updateGuideImageUrlList } from '~/repositories/guideRepository'

interface OwnProps {
  images: string[]
  limit: number
  onUpdated?: (imageUrlList: string[]) => void
}

type Props = OwnProps & EditableSectionProps

const StyledImg = styled('img')({
  width: '112px',
  height: '112px',
})

const SectionProfileImages: FC<Props> = ({
  images,
  limit,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [currentImages, setCurrentImages] = useState(images)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      await updateGuideImageUrlList(currentUser.user.uid, currentImages)
      onUpdated?.(currentImages)
      toggleAlert(true, 'success', '画像リストを更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  return (
    <EditableSectionContainer
      title="画像リスト"
      editing={editing}
      disabled={disabled}
      onClickMode={() => {
        setCurrentImages(images)
        onClickMode?.()
      }}
      onClickSave={onSubmit}
      loading={isLoading}
    >
      {!editing && (
        <Grid container spacing={2}>
          {images.map((url, index) => (
            <Grid key={index.toString()} item>
              <StyledImg src={url} />
            </Grid>
          ))}
        </Grid>
      )}
      {editing && (
        <Grid container spacing={2}>
          {currentImages.map((url, index) => (
            <Grid key={index.toString()} item>
              <InputImage
                key={url}
                type={UPLOAD_TYPE.profile}
                imageUrl={url}
                onClose={() =>
                  setCurrentImages(currentImages.filter((_, i) => i !== index))
                }
              />
            </Grid>
          ))}
          {images.length < limit && (
            <Grid item>
              <InputImage
                type={UPLOAD_TYPE.profile}
                onAddImageUrl={(url) =>
                  setCurrentImages((prev) => [...prev, url])
                }
              />
            </Grid>
          )}
        </Grid>
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileImages
