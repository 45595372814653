import { TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'

import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { updateGuideAboutUs } from '~/repositories/guideRepository'

interface OwnProps {
  aboutUs?: string
  onUpdated?: (aboutUs: string) => void
}

type Props = OwnProps & EditableSectionProps

const SectionProfileIntroduction: FC<Props> = ({
  aboutUs,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [currentAboutUs, setCurrentAboutUs] = useState(aboutUs)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      await updateGuideAboutUs(currentUser.user.uid, currentAboutUs || '')
      onUpdated?.(currentAboutUs || '')
      toggleAlert(true, 'success', 'ガイド紹介文を更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  return (
    <EditableSectionContainer
      title="ガイドの紹介文"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentAboutUs(aboutUs)
        onClickMode?.()
      }}
    >
      {!editing && (
        <Typography variant="subtitle2" component="div" color="secondary.main">
          {aboutUs}
        </Typography>
      )}
      {editing && (
        <TextField
          value={currentAboutUs}
          onChange={(e) => setCurrentAboutUs(e.target.value)}
          variant="outlined"
          multiline
          rows={5}
          sx={{
            ['& fieldset']: { borderRadius: '8px' },
            width: '100%',
          }}
        />
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileIntroduction
