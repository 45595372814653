import { IconButton, Stack, styled, useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { signOut } from 'firebase/auth'
import { FC, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import HeaderItem from '~/components/molecules/headerItem'
import HeaderMenu from '~/components/molecules/headerMenu'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as MenuLogo } from '~/components/svgs/menu.svg'
import { ReactComponent as NoProfile } from '~/components/svgs/no_profile.svg'
import { ReactComponent as ServiceLogo } from '~/components/svgs/service_logo.svg'
import auth from '~/utils/auth'

const StyledAppBar = styled(AppBar)({})

const StyledToolbar = styled(Toolbar)({
  ['@media screen and (min-width: 0px) and (max-width: 840px)']: {
    minHeight: '50px',
    padding: '0 20px',
  },
  ['@media screen and (min-width: 841px)']: {
    minHeight: '84px',
    padding: '0 32px',
  },
})

const StyledLogo = styled(ServiceLogo)({
  ['@media screen and (min-width: 0px) and (max-width: 840px)']: {
    width: '96px',
    height: '26px',
  },
  ['@media screen and (min-width: 841px)']: {
    width: '167px',
    height: '44px',
  },
})

interface Props {
  disableItems?: boolean
}

const Header: FC<Props> = ({ disableItems }) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpenMenu = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClick = (to: string) => {
    handleClose()
    navigate(to)
  }
  const handleLogout = async () => {
    handleClose()
    try {
      await signOut(auth)
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <StyledAppBar position="static" color="inherit" elevation={2}>
        <StyledToolbar sx={{ width: '100%', boxSizing: 'border-box' }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={isDesktop ? undefined : 'space-between'}
            width="100%"
          >
            <Link to="/">
              <StyledLogo />
            </Link>
            {isDesktop && (
              <Stack direction="row" flex={1}>
                {currentUser &&
                  currentUser.isPermittedGuide &&
                  !disableItems && (
                    <>
                      <HeaderItem type="plan" />
                      <HeaderItem type="boat" />
                      <HeaderItem type="tackle" />
                      <HeaderItem type="boarding-procedure" />
                      <HeaderItem type="fishing-seat-procedure" />
                      <HeaderItem type="account" />
                    </>
                  )}
              </Stack>
            )}
            {currentUser && currentUser.isPermittedGuide && (
              <IconButton
                aria-expanded={isOpenMenu}
                aria-label="ナビゲーションメニュー"
                onClick={handleClick}
              >
                {isDesktop && (
                  <IconButton>
                    <NoProfile />
                  </IconButton>
                )}
                {!isDesktop && <MenuLogo />}
              </IconButton>
            )}
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
      <HeaderMenu
        isOpen={isOpenMenu}
        anchorElement={anchorEl}
        onClick={handleMenuClick}
        onClickLogout={handleLogout}
        onClose={handleClose}
      />
    </Box>
  )
}

export default Header
