import { TextField } from '@mui/material'
import { FC } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { BoatBase } from '~/model/account/boat'

interface OwnProps {
  title: string
  placeholder?: string
}

type Props = UseFormReturn<BoatBase> & OwnProps

const InputBoatMultilineTextSection: FC<Props> = ({
  title,
  placeholder,
  register,
  control,
  formState: { errors },
}) => {
  const descriptionReg = register('description', { maxLength: 5000 })

  return (
    <SectionContainer title={title}>
      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            {...{ error: Boolean(errors.description) }}
            {...descriptionReg}
            inputRef={descriptionReg.ref}
            variant="outlined"
            multiline={true}
            rows={5}
            sx={{
              ['& fieldset']: { borderRadius: '8px' },
              width: '100%',
            }}
            placeholder={placeholder}
          />
        )}
      />
    </SectionContainer>
  )
}

export default InputBoatMultilineTextSection
