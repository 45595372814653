import { FC } from 'react'

import BoatsBody from '~/components/organisms/boatsBody'
import Container from '~/components/organisms/container'
import ContentContainer from '~/components/organisms/contentContainer'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import NotPermittedBody from '~/components/organisms/notPermittedBody'
import { useAuthContext } from '~/components/providers/auth'

const BoatsTemplate: FC = () => {
  const { currentUser } = useAuthContext()
  return (
    <Container>
      <Head />
      <Header />
      <ContentContainer>
        {currentUser && currentUser.isPermittedGuide && <BoatsBody />}
        {currentUser && !currentUser.isPermittedGuide && <NotPermittedBody />}
      </ContentContainer>
    </Container>
  )
}

export default BoatsTemplate
