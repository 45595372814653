import { LoadingButton } from '@mui/lab'
import { SxProps, Theme, Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  id?: string
  title: string
  type?: 'submit' | 'reset' | 'button'
  loading?: boolean
  color?: 'primary' | 'subtitle' | 'secondary' | 'info' | 'error'
  onClick?: () => void
  sx?: SxProps<Theme>
}

const BasicButton: FC<Props> = ({
  id,
  title,
  type,
  loading,
  color,
  onClick,
  sx,
}) => {
  return (
    <LoadingButton
      id={id}
      type={type}
      fullWidth
      variant="contained"
      disableElevation
      size="large"
      color={color}
      loading={loading}
      loadingPosition="start"
      onClick={onClick}
      sx={{ borderRadius: '8px', height: '48px', ...sx }}
    >
      <Typography variant="button" component="div">
        {title}
      </Typography>
    </LoadingButton>
  )
}

export default BasicButton
