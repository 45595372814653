import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { FC, useCallback, useState } from 'react'

import envConfig from '~/config/env-config'

import Loading from '../loading'

interface Props {
  position?: { lat: number; lng: number }
  containerStyle?: React.CSSProperties
}

const GMap: FC<Props> = ({ position, containerStyle }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.googleMapApiKey,
    language: 'ja'
  })

  const [map, setMap] = useState<any>(null)

  const onLoad = useCallback(function callback(map: {
    fitBounds: (arg0: google.maps.LatLngBounds) => void
  }) {
    setMap(map)
  },
  [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={17}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
        >
          {position && <Marker position={position} />}
        </GoogleMap>
      )}
      {!isLoaded && <Loading />}
    </>
  )
}

export default GMap
