import { Box, Link } from '@mui/material'
import { FC } from 'react'

import { ReactComponent as ServiceLogo } from '~/components/svgs/footer_service_logo.svg'

const Footer: FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'footerBackground.main',
        '@media screen and (min-width: 0px) and (max-width: 840px)': {
          padding: '24px 20px 40px 20px',
        },
        '@media screen and (min-width: 841px)': {
          padding: '24px 20px 40px 92px',
        },
      }}
    >
      <ServiceLogo />
      <Box
        sx={{
          marginTop: '24px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '40px',
          paddingLeft: '10px',
        }}
      >
        <Box
          component="ul"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
          }}
        >
          <Link
            href="https://tsuritori.jp/terms"
            underline="none"
            variant="subtitle2"
            color="white"
            target="_blank"
          >
            利用規約
          </Link>
          <Link
            href="https://tsuritori.jp/policy"
            underline="none"
            variant="subtitle2"
            color="white"
            target="_blank"
          >
            プライバシーポリシー
          </Link>
          <Link
            href="/help"
            underline="none"
            variant="subtitle2"
            color="white"
            target="_blank"
          >
            ヘルプセンター
          </Link>
        </Box>
        <Box
          component="small"
          color="white"
          sx={{ fontWeight: 300 }}
        >{`©︎ ${new Date().getFullYear()} YAMAME, Inc.`}</Box>
      </Box>
    </Box>
  )
}

export default Footer
