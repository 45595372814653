import { Stack, Typography, useMediaQuery } from '@mui/material'
import { GeoPoint } from 'firebase/firestore'
import { FC, useMemo, useState } from 'react'

import BasicButton from '~/components/atoms/basicButton'
import GMap from '~/components/atoms/googlemap'
import InputText from '~/components/atoms/inputText'
import SelectedMenu from '~/components/atoms/selectedMenu'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Access } from '~/model/account/access'
import { deleteOptionalParking, updateAccess } from '~/repositories/accessRepository'

interface OwnProps {
  type: 'second_parking' | 'third_parking'
  access: Access
  onUpdated?: (access: Access) => void
}

type Props = OwnProps & EditableSectionProps

const SectionProfileAccessParkingOptional: FC<Props> = ({
  type,
  access,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  // For Editing item.
  const [currentLocationLati, setSearchGeoCoordinateLati] = useState(() => {
    if (type === 'second_parking') {
      return access.secondParking?.location.latitude.toString()
    }
    if (type === 'third_parking') {
      return access.thirdParking?.location.latitude.toString()
    }
  })
  const [currentLocationLong, setSearchGeoCoordinateLong] = useState(() => {
    if (type === 'second_parking') {
      return access.secondParking?.location.longitude.toString()
    }
    if (type === 'third_parking') {
      return access.thirdParking?.location.longitude.toString()
    }
  })
  const [currentTitle, setCurrentTitle] = useState(() => {
    if (type === 'second_parking') {
      return access.secondParking?.title
    }
    if (type === 'third_parking') {
      return access.thirdParking?.title
    }
  })
  const [currentIsPaid, setCurrentIsPaid] = useState(() => {
    if (type === 'second_parking') {
      return access.secondParking?.isPaid
    }
    if (type === 'third_parking') {
      return access.thirdParking?.isPaid
    }
    return false
  })

  const selectedAccess = useMemo(
    () =>
      type === 'second_parking' ? access.secondParking : access.thirdParking,
    [type, access]
  )

  const onSubmit = async () => {
    const locationLati = Number(currentLocationLati)
    const locationLong = Number(currentLocationLong)
    if (
      currentUser &&
      locationLati != null &&
      locationLong != null &&
      currentTitle != null &&
      currentIsPaid != null
    ) {
      // 駐車場1
      // 地理地点、タイトル、有料無料
      const newAccess: Access = { ...access }
      if (type === 'second_parking') {
        newAccess.secondParking = {
          location: new GeoPoint(locationLati, locationLong),
          title: currentTitle,
          isPaid: currentIsPaid,
        }
      } else if (type === 'third_parking') {
        newAccess.thirdParking = {
          location: new GeoPoint(locationLati, locationLong),
          title: currentTitle,
          isPaid: currentIsPaid,
        }
      }
      setIsLoading(true)
      try {
        await updateAccess(access.documentId, newAccess)
        onUpdated?.(newAccess)
        toggleAlert(
          true,
          'success',
          `${selectedAccess?.title}の情報を更新しました！`
        )
      } catch (e) {
        toggleAlert(true, 'error', e)
      }
      setIsLoading(false)
    }
  }

  const onDelete = async () => {
    const newAccess: Access = { ...access }
    if (type === 'second_parking') {
      delete newAccess.secondParking
    } else if (type === 'third_parking') {
      delete newAccess.thirdParking
    }
    setIsDeleting(true)
    try {
      await deleteOptionalParking(access.documentId, type)
      onUpdated?.(newAccess)
      toggleAlert(
        true,
        'success',
        `${selectedAccess?.title}の情報を削除しました。`
      )
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsDeleting(false)
  }

  return (
    <EditableSectionContainer
      title={type === 'second_parking' ? '駐車場2（任意）' : '駐車場3（任意）'}
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        if (type === 'second_parking') {
          setSearchGeoCoordinateLati(
            access.secondParking?.location.latitude.toString()
          )
          setSearchGeoCoordinateLong(
            access.secondParking?.location.longitude.toString()
          )
          setCurrentTitle(access.secondParking?.title)
          setCurrentIsPaid(access.secondParking?.isPaid || false)
        }
        if (type === 'third_parking') {
          setSearchGeoCoordinateLati(
            access.thirdParking?.location.latitude.toString()
          )
          setSearchGeoCoordinateLong(
            access.thirdParking?.location.longitude.toString()
          )
          setCurrentTitle(access.thirdParking?.title)
          setCurrentIsPaid(access.thirdParking?.isPaid || false)
        }
        onClickMode?.()
      }}
    >
      {!editing && access && selectedAccess && (
        <Stack spacing={2}>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.main"
          >
            {`名称: ${selectedAccess.title}`}
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            color="secondary.main"
          >
            {`駐車場料金: ${selectedAccess.isPaid ? '有料' : '無料'}`}
          </Typography>
          <GMap
            position={{
              lat: selectedAccess.location.latitude,
              lng: selectedAccess.location.longitude,
            }}
            containerStyle={{
              width: isDesktop ? '562px' : '100%',
              height: isDesktop ? '357px' : 'calc(100vw * 0.5625)',
            }}
          />
        </Stack>
      )}
      {editing && (
        <Stack spacing={3} paddingBottom="24px">
          <Stack spacing={2.5}>
            <Stack direction="row" spacing={2.5}>
              <Stack spacing={2.5}>
                <InputText
                  size="small"
                  name="駐車場の名称"
                  placeholder="第二駐車場"
                  value={currentTitle}
                  onChange={(e) => setCurrentTitle(e.target.value)}
                />
                <Stack spacing={1.5}>
                  <Typography variant="h4" component="span">
                    料金
                  </Typography>
                  <SelectedMenu
                    options={['無料', '有料']}
                    selectedIndex={currentIsPaid ? 1 : 0}
                    onChangeSelected={(index) => {
                      if (index === 0) {
                        setCurrentIsPaid(false)
                      } else if (index === 1) {
                        setCurrentIsPaid(true)
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" component="span">
              地図上の位置
            </Typography>
            <Stack direction="row" spacing={isDesktop ? 2 : 1.5}>
              <InputText
                size="small"
                name="経度"
                placeholder="経度"
                value={currentLocationLati}
                onChange={(e) => {
                  setSearchGeoCoordinateLati(e.target.value)
                }}
              />
              <InputText
                size="small"
                name="緯度"
                placeholder="緯度"
                value={currentLocationLong}
                onChange={(e) => {
                  setSearchGeoCoordinateLong(e.target.value)
                }}
              />
            </Stack>
            <GMap
              position={{
                lat: Number(currentLocationLati),
                lng: Number(currentLocationLong),
              }}
              containerStyle={{
                width: isDesktop ? '562px' : '100%',
                height: isDesktop ? '357px' : 'calc(100vw * 0.5625)',
              }}
            />
          </Stack>
          {selectedAccess && (
            <BasicButton
              title="削除する"
              color="error"
              type="submit"
              loading={isDeleting}
              sx={{ width: '120px', height: '40px' }}
              onClick={onDelete}
            />
          )}
        </Stack>
      )}
    </EditableSectionContainer>
  )
}

export default SectionProfileAccessParkingOptional
