import { Stack, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { BankAccountBase } from '~/model/bankAccount/bankAccount'

type BankAccountType = keyof BankAccountBase

interface OwnProps {
  type: BankAccountType
  title: string
  placeholder?: string
}

type Props = UseFormReturn<BankAccountBase> & OwnProps

const SectionInputBankAccount: FC<Props> = ({
  register,
  control,
  formState: { errors },
  type,
  title,
  placeholder,
}) => {
  const reg = register(type, {
    required: true,
    minLength: 1,
    maxLength: 100,
  })
  return (
    <Stack spacing={1}>
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <Controller
        name={type}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            {...reg}
            inputRef={reg.ref}
            error={Boolean(errors[type])}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            sx={{
              ['& fieldset']: { borderRadius: '8px' },
              width: '100%',
            }}
          />
        )}
      />
    </Stack>
  )
}

export default SectionInputBankAccount
