import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { FEATURE_TYPE, FeatureType } from '~/model/plan/featureType'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const InputFeaturesSection: FC<Props> = ({ title, setValue, getValues }) => {
  const [features, setFeatures] = useState<FeatureType[]>(
    getValues('featureList')
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const featureType = event.target.name as FeatureType
    const isFeatureType = Object.values(FEATURE_TYPE).includes(featureType)
    if (isFeatureType) {
      event.target.checked
        ? setFeatures([...features, featureType])
        : setFeatures(features.filter((v) => v !== event.target.name))
    }
  }

  useEffect(() => {
    setValue('featureList', [...features])
  }, [features])

  return (
    <SectionContainer title={title}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={features.includes('easy')}
              onChange={handleChange}
              color="success"
              name="easy"
            />
          }
          label={
            <Typography
              variant="subtitle2"
              color="secondary.main"
              component="span"
            >
              初心者でも簡単
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={features.includes('empty_handed')}
              onChange={handleChange}
              color="success"
              name="empty_handed"
            />
          }
          label={
            <Typography
              variant="subtitle2"
              color="secondary.main"
              component="span"
            >
              手ぶらOK
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={features.includes('support')}
              onChange={handleChange}
              color="success"
              name="support"
            />
          }
          label={
            <Typography
              variant="subtitle2"
              color="secondary.main"
              component="span"
            >
              中乗りさんあり
            </Typography>
          }
        />
      </FormGroup>
    </SectionContainer>
  )
}

export default InputFeaturesSection
