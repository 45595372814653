import { Grid, Stack, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import InputImage from '~/components/atoms/inputImage'
import SectionContainer from '~/components/atoms/sectionContainer'
import { TackleBase } from '~/model/account/tackle'
import { UPLOAD_TYPE } from '~/model/uploadType'

type Props = UseFormReturn<TackleBase>

const InputTackleImagesSection: FC<Props> = ({ setValue, getValues }) => {
  const limit = 20
  const [imageUrls, setImageUrls] = useState<string[]>(
    getValues('imageUrlList')
  )

  useEffect(() => {
    console.log(imageUrls)
    setValue('imageUrlList', [...imageUrls])
  }, [imageUrls])

  return (
    <SectionContainer title="タックル画像">
      <Stack spacing={2.5}>
        <Grid container spacing={2}>
          {imageUrls.map((url, index) => (
            <Grid item>
              <InputImage
                key={url}
                type={UPLOAD_TYPE.tackle}
                imageUrl={url}
                onClose={() =>
                  setImageUrls(imageUrls.filter((_, i) => i !== index))
                }
              />
            </Grid>
          ))}
          {imageUrls.length < limit && (
            <Grid item>
              <InputImage
                type={UPLOAD_TYPE.tackle}
                onAddImageUrl={(url) => setImageUrls((prev) => [...prev, url])}
              />
            </Grid>
          )}
        </Grid>
        <Typography
          variant="h6"
          color="subtitle.main"
          component="span"
          whiteSpace="pre-wrap"
        >
          {`※画像は${limit}枚まで追加できます\n※対応ファイル: jpg, png\n※5MB以内の画像ファイルを用意してください`}
        </Typography>
      </Stack>
    </SectionContainer>
  )
}

export default InputTackleImagesSection
