import { doc, getDoc } from 'firebase/firestore'

import { FirestoreMaintenance } from '~/model/maintenance'
import { CollectionPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getConfigure = async () => {
  const ref = doc(
    firestore,
    CollectionPath.Configure,
    'config'
  ).withConverter(converter<FirestoreMaintenance>())

  const snap = await getDoc(ref)

  if (snap.exists()) {
    return snap.data()
  } else {
    throw new Error('Configure document not found.')
  }
}
