import { Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, ReactNode } from 'react'

import BasicButton from '~/components/atoms/basicButton'
import { ReactComponent as EditIcon } from '~/components/svgs/edit.svg'

export interface EditableSectionProps {
  editing?: boolean
  disabled?: boolean
  loading?: boolean
  onClickSave?: () => void
  onClickMode?: () => void
}

interface OwnProps {
  title: string
  description?: string
  children?: ReactNode
}

type Props = EditableSectionProps & OwnProps

const EditableSectionContainer: FC<Props> = ({
  title,
  description,
  editing,
  disabled,
  loading,
  children,
  onClickSave,
  onClickMode,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')

  return (
    <Stack spacing={2} sx={{ opacity: disabled ? 0.3 : 1 }}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant={isDesktop ? 'h2' : 'h4'} component="h2">
            {title}
          </Typography>
          <Button
            disabled={disabled}
            color="secondary"
            startIcon={!editing && <EditIcon />}
            onClick={onClickMode}
          >
            <Typography
              variant={isDesktop ? 'h4' : 'h5'}
              component="span"
              sx={{ textDecoration: 'underline' }}
            >
              {!editing ? '編集' : 'キャンセル'}
            </Typography>
          </Button>
        </Stack>
        {description && (
          <Typography variant="h6" component="span">
            {description}
          </Typography>
        )}
      </Stack>
      {children}
      {editing && !disabled && (
        <BasicButton
          title="保存する"
          color="secondary"
          type="submit"
          loading={loading}
          sx={{ width: '120px', height: '40px' }}
          onClick={onClickSave}
        />
      )}
    </Stack>
  )
}

export default EditableSectionContainer
