import {
  Backdrop,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import BasicButton from '~/components/atoms/basicButton'
import Loading from '~/components/atoms/loading'
import InputBoardingProcedureStepSection from '~/components/molecules/boardingProcedureSections/inputBoardingProcedureStepSection'
import InputBoardingProcedureTitleSection from '~/components/molecules/boardingProcedureSections/inputBoardingProcedureTitleSection'
import BreadcrumbList from '~/components/molecules/breadcrumbList'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { BoardingProcedureBase } from '~/model/account/boardingProcedure'
import {
  addBoardingProcedure,
  getBoardingProcedure,
  updateBoardingProcedure,
} from '~/repositories/boardingProcedureRepository'

interface Props {
  // boardingProcedureIdがある場合は編集モード
  boardingProcedureId?: string
}

const BoardingProceduresWriteBody: FC<Props> = ({ boardingProcedureId }) => {
  const [isWriting, setIsWriting] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const form = useForm<BoardingProcedureBase>()
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const navigate = useNavigate()

  const title = useMemo(
    () => (boardingProcedureId ? '乗船手順を編集' : '乗船手順を追加'),
    [boardingProcedureId]
  )

  useEffect(() => {
    if (!currentUser) return
    // 初期化処理
    if (boardingProcedureId) {
      // 編集画面
      const cleanup = async () => {
        const boardingProcedure = await getBoardingProcedure(
          currentUser.user.uid,
          boardingProcedureId
        )
        form.setValue('title', boardingProcedure.title)
        form.setValue('steps', boardingProcedure.steps)
        setInitialized(true)
      }
      cleanup()
    } else {
      // 追加画面
      form.setValue('title', '乗船手順')
      form.setValue('steps', [])
      setInitialized(true)
    }
  }, [boardingProcedureId])

  const onSubmit: SubmitHandler<BoardingProcedureBase> = async (data) => {
    if (!currentUser) return
    setIsWriting(true)
    try {
      if (boardingProcedureId) {
        await updateBoardingProcedure(
          currentUser.user.uid,
          boardingProcedureId,
          data
        )
      } else {
        await addBoardingProcedure(currentUser.user.uid, data)
        navigate('/boarding-procedures', { replace: true })
      }
      toggleAlert(
        true,
        'success',
        boardingProcedureId
          ? '乗船手順を更新しました！'
          : '乗船手順を追加しました！'
      )
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsWriting(false)
    console.log(data)
  }

  return (
    <Stack width="100%" spacing={1.5}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWriting}
      >
        <Loading width={150} height={150} />
      </Backdrop>
      {initialized && (
        <Stack spacing={4}>
          <BreadcrumbList
            items={[
              { name: '乗船手順一覧', to: '/boarding-procedures' },
              { name: '乗船手順を追加' },
            ]}
          />
          <Stack component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Typography variant={isDesktop ? 'h1' : 'h2'}>{title}</Typography>
            <InputBoardingProcedureTitleSection
              {...form}
              title="タイトル"
              placeholder="乗船手順のタイトル"
            />
            <Divider />
            <InputBoardingProcedureStepSection {...form} title="ステップ" />
            <Stack
              paddingTop="24px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <BasicButton
                type="reset"
                title="キャンセル"
                color="info"
                sx={{ width: '140px' }}
                onClick={() =>
                  navigate('/boarding-procedures', { replace: true })
                }
              />
              <BasicButton
                type="submit"
                title={boardingProcedureId ? '更新する' : '登録する'}
                sx={{ width: '220px' }}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default BoardingProceduresWriteBody
