import { styled, useMediaQuery } from '@mui/material'
import { FC } from 'react'

import Container from '~/components/organisms/container'
import Head from '~/components/organisms/head'
import LoginEmailBody from '~/components/organisms/loginEmailBody'
import LoginHeader from '~/components/organisms/loginHeader'
import { ReactComponent as ServiceLogo } from '~/components/svgs/service_logo.svg'

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  alignItems: 'center',
  rowGap: '32px',
  ['@media screen and (min-width: 841px)']: {
    width: '566px',
    paddingTop: '48px',
    paddingBottom: '60px',
  },
})

const StyledContentContainer = styled('div')({
  width: '100%',
  ['@media screen and (min-width: 841px)']: {
    border: '1px solid var(--color-fidee-border)',
    borderRadius: '8px',
    overflow: 'hidden',
  },
})

const LoginTemplate: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')

  return (
    <Container>
      <Head />
      <StyledContainer>
        {isDesktop && <ServiceLogo />}
        <StyledContentContainer>
          <LoginHeader />
          <LoginEmailBody />
        </StyledContentContainer>
      </StyledContainer>
    </Container>
  )
}

export default LoginTemplate
