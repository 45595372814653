import { Link, Stack, Typography } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import SelectedMenu from '~/components/atoms/selectedMenu'
import { cancellationPolicies } from '~/model/help/helpArticle'
import {
  CANCELLATION_POLICY,
  CancellationPolicy,
} from '~/model/plan/cancellationPolicy'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const options = ['全額返金', '緩め', '普通', '厳しめ', '返金不可']

const SelectCancellationPolicy: FC<Props> = ({
  register,
  setValue,
  getValues,
  formState: { errors },
  clearErrors,
  title,
}) => {
  const [selectedPolicy, setSelectedPolicy] = useState<CancellationPolicy>(
    getValues('cancellationPolicyType')
  )

  useEffect(() => {
    register('cancellationPolicyType', { required: true })
  }, [])

  useEffect(() => {
    if (selectedPolicy) {
      setValue('cancellationPolicyType', selectedPolicy)
      clearErrors('cancellationPolicyType')
    }
  }, [selectedPolicy])

  const policyDescription = useMemo(() => {
    if (!selectedPolicy) return
    switch (selectedPolicy) {
      case 'full_refund':
        return cancellationPolicies('full-refund')
      case 'flexible':
        return cancellationPolicies('flexible')
      case 'moderate':
        return cancellationPolicies('moderate')
      case 'strict':
        return cancellationPolicies('strict')
      case 'not_refund':
        return cancellationPolicies('no-refund')
    }
  }, [selectedPolicy])

  const selectedPolicyIndex = useMemo(() => {
    if (selectedPolicy)
      return Object.values(CANCELLATION_POLICY).indexOf(selectedPolicy)
  }, [selectedPolicy])

  const handleChange = (index: number) => {
    const newPolicy = Object.values(CANCELLATION_POLICY)[index]
    setSelectedPolicy(newPolicy)
  }

  return (
    <SectionContainer title={title}>
      <Stack spacing={2.5}>
        <SelectedMenu
          options={options}
          selectedIndex={selectedPolicyIndex}
          onChangeSelected={handleChange}
          error={Boolean(errors.cancellationPolicyType)}
        />
        <Stack spacing={1.5}>
          {selectedPolicyIndex !== undefined && (
            <>
              <Typography variant="h5" component="span">
                {options[selectedPolicyIndex]}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                color="secondary.main"
                whiteSpace="pre-wrap"
              >
                {policyDescription}
              </Typography>
            </>
          )}
          <Typography variant="h6" component="span" color="subtitle.main">
            ※ 詳しくは「
            <Link
              href="/help/section/6/article/2"
              target="_blank"
              variant="h6"
              color="subtitle.main"
            >
              キャンセルポリシーについて
            </Link>
            」をご覧ください。
          </Typography>
        </Stack>
      </Stack>
    </SectionContainer>
  )
}

export default SelectCancellationPolicy
