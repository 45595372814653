import {
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC } from 'react'

import { ReactComponent as MinusIcon } from '~/components/svgs/minus.svg'
import { Item } from '~/model/account/item'

interface Props {
  item: Item
  onChangeName?: (name: string) => void
  onChangePrice?: (price: number) => void
  onRemove?: () => void
}

const StyledInputText = styled(TextField)({
  ['& fieldset']: {
    borderRadius: '8px',
  },
})

const StyledMinusIcon = styled(MinusIcon)({
  ['path:first-child']: {
    fill: '#FF0000',
  },
})

const InputItem: FC<Props> = ({
  item,
  onChangeName,
  onChangePrice,
  onRemove,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')

  return (
    <Stack direction="row" alignItems="center" spacing={isDesktop ? 2 : 1}>
      <StyledInputText
        value={item.name}
        size="small"
        variant="outlined"
        placeholder="例) レンタルタックル"
        onChange={(e) => onChangeName?.(e.target.value)}
        sx={{ width: isDesktop ? '300px' : '180px' }}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <StyledInputText
          value={item.price}
          size="small"
          variant="outlined"
          placeholder="1,000"
          onChange={(e) => onChangePrice?.(parseInt(e.target.value) || 0)}
          sx={{ width: '80px' }}
        />
        <Typography variant="h3" component="span" fontWeight={500}>
          円
        </Typography>
      </Stack>
      <IconButton disableRipple onClick={onRemove}>
        <StyledMinusIcon />
      </IconButton>
    </Stack>
  )
}

export default InputItem
