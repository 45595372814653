import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, ReactNode } from 'react'

interface Props {
  title: string
  children?: ReactNode
}

const SectionContainer: FC<Props> = ({ title, children }) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  return (
    <Stack
      direction={isDesktop ? 'row' : 'column'}
      alignItems={isDesktop ? 'center' : 'flex-start'}
      padding={isDesktop ? '32px 0 32px 0' : '16px 0 16px 0'}
      spacing={isDesktop ? 0 : 1.5}
    >
      <Typography variant="h2" component="span" width="214px">
        {title}
      </Typography>
      <Box width={isDesktop ? 'calc(100% - 214px)' : '100%'}>
        {children}
      </Box>
    </Stack>
  )
}

export default SectionContainer
