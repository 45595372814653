import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import {
  FirestoreFishingSeatProcedure,
  FishingSeatProcedure,
  FishingSeatProcedureBase,
  FishingSeatProcedureWrite,
} from '~/model/account/fishingSeatProcedure'
import { getGuideFishingSeatProceduresPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getFishingSeatProcedures = async (uid: string) => {
  const fishingSeatProceduresPath = getGuideFishingSeatProceduresPath(uid)
  const ref = collection(firestore, fishingSeatProceduresPath).withConverter(
    converter<FirestoreFishingSeatProcedure>()
  )
  const q = query(ref, limit(100))
  const snaps = await getDocs(q)

  return snaps.docs.map((doc) => {
    const fishingSeatProcedure: FishingSeatProcedure = {
      ...doc.data(),
      documentId: doc.id,
    }
    return fishingSeatProcedure
  })
}

export const getFishingSeatProcedure = async (
  uid: string,
  fishingSeatProcedureId: string
) => {
  const fishingSeatProceduresPath = getGuideFishingSeatProceduresPath(uid)
  const ref = doc(
    firestore,
    fishingSeatProceduresPath,
    fishingSeatProcedureId
  ).withConverter(converter<FirestoreFishingSeatProcedure>())
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const fishingSeatProcedure: FishingSeatProcedure = {
      ...snap.data(),
      documentId: snap.id,
    }
    return fishingSeatProcedure
  } else {
    throw new Error('Document not found.')
  }
}

export const addFishingSeatProcedure = async (
  uid: string,
  data: FishingSeatProcedureBase
) => {
  const fishingSeatProceduresPath = getGuideFishingSeatProceduresPath(uid)
  const ref = doc(
    collection(firestore, fishingSeatProceduresPath)
  ).withConverter(converter<FirestoreFishingSeatProcedure>())

  const writeData: FishingSeatProcedureWrite = {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  await setDoc(ref, writeData)
}

export const updateFishingSeatProcedure = async (
  uid: string,
  fishingSeatProcedureId: string,
  data: FishingSeatProcedureBase
) => {
  const fishingSeatProceduresPath = getGuideFishingSeatProceduresPath(uid)
  const ref = doc(
    firestore,
    fishingSeatProceduresPath,
    fishingSeatProcedureId
  ).withConverter(converter<FirestoreFishingSeatProcedure>())

  const writeData = { ...data, updatedAt: serverTimestamp() }

  await setDoc(ref, writeData, { merge: true })
}
