import { styled } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { FC } from 'react'

const StyledAppBar = styled(AppBar)({
  ['@media screen and (min-width: 841px)']: {
    boxShadow: 'none',
    borderBottom: '1px solid var(--color-fidee-border)',
  },
})

const StyledToolbar = styled(Toolbar)({
  ['@media screen and (min-width: 0px) and (max-width: 840px)']: {
    minHeight: '50px',
  },
  ['@media screen and (min-width: 841px)']: {
    minHeight: '50px',
  },
})

const LoginHeader: FC = () => {
  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <StyledAppBar position="static" color="inherit" elevation={2}>
        <StyledToolbar>
          <Typography
            variant="h4"
            component="h1"
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            ログイン
          </Typography>
        </StyledToolbar>
      </StyledAppBar>
    </Box>
  )
}

export default LoginHeader
