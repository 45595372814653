import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title?: string
  description?: string
  url?: string
  image?: string
  type?: string
}

const Head: React.FC<Props> = (props) => {
  const title = useMemo(() => {
    return props.title ? props.title : 'ツリトリ管理画面'
  }, [props.title])

  const description = useMemo(() => {
    return props.description
      ? props.description
      : '釣り船予約サービス「ツリトリ」のガイド向け管理画面'
  }, [props.description])

  const url = useMemo(() => {
    return props.url ? props.url : 'https://guide.tsuritori.jp'
  }, [props.url])

  const image = useMemo(() => {
    return props.image
      ? props.image
      : 'https://guide.tsuritori.jp/static/images/tsuritori_ogp.png'
  }, [props.image])

  return (
    <Helmet>
      {/* SEO */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      ></meta>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="apple-mobile-web-app-title" content="ツリトリ管理画面" />
      <meta name="application-name" content="ツリトリ管理画面" />
      <meta name="msapplication-TileColor" content="#00A3FF" />
      <meta name="theme-color" content="#ffffff" />
      {/* OGP */}
      <meta property="og:site_name" content="ツリトリ管理画面" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={props.type || 'website'} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="ja_JP" />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* Other */}
      <meta name="robots" content="noindex,nofollow" />
      <link
        rel="icon"
        href="/static/favicon/favicon_16.png"
        sizes="16x16"
        type="image/png"
      />
      <link
        rel="icon"
        href="/static/favicon/favicon_32.png"
        sizes="32x32"
        type="image/png"
      />
      <link
        rel="icon"
        href="/static/favicon/favicon_48.png"
        sizes="48x48"
        type="image/png"
      />
      <link
        rel="icon"
        href="/static/favicon/favicon_62.png"
        sizes="62x62"
        type="image/png"
      />
      <link
        rel="mask-icon"
        href="/static/favicon/safari_pinned.svg"
        color="#393939"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/favicon/apple_touch.png"
      />
      <link rel="shortcut icon" href={'/static/favicon/favicon_32.png'} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
    </Helmet>
  )
}

export default Head
