export const DEADLINE_TYPE = {
  dayBefore: 'day_before',
  twoDaysBefore: '2_days_before',
  threeDaysBefore: '3_days_before',
  fourDaysBefore: '4_days_before',
  sevenDaysBefore: '7_days_before',
} as const

export type DeadlineType =
  typeof DEADLINE_TYPE[keyof typeof DEADLINE_TYPE]

