import './properties.css'

import * as React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AlertProvider } from './components/providers/alert'
import { AuthProvider, useAuthContext } from './components/providers/auth'
import Maintenance from './components/providers/maintenance'
import Account from './pages/account'
import BoardingProcedures from './pages/boardingProcedures'
import BoardingProceduresAdd from './pages/boardingProceduresAdd'
import BoardingProceduresEdit from './pages/boardingProceduresEdit'
import Boats from './pages/boats'
import BoatsAdd from './pages/boatsAdd'
import BoatsEdit from './pages/boatsEdit'
import FishingSeatProcedures from './pages/fishingSeatProcedures'
import FishingSeatProceduresAdd from './pages/fishingSeatProceduresAdd'
import FishingSeatProceduresEdit from './pages/fishingSeatProceduresEdit'
import Help from './pages/help'
import HelpArticle from './pages/helpArticle'
import Home from './pages/home'
import Incomes from './pages/incomes'
import LogIn from './pages/login'
import NotFound from './pages/notFound'
import Plans from './pages/plans'
import PlansAdd from './pages/plansAdd'
import PlansEdit from './pages/plansEdit'
import Tackles from './pages/tackles'
import TacklesAdd from './pages/tacklesAdd'
import TacklesEdit from './pages/tacklesEdit'
import { URLPath as Path } from './utils/url'

interface Props {
  children: React.ReactNode
}

const AuthRoute: React.ElementType<Props> = ({ children }) => {
  const { status, currentUser } = useAuthContext()
  if (status === 'beforeLogin' || status === 'loading') {
    // Loading
    return <></>
  } else {
    if (!currentUser) {
      return <Navigate to={Path.LogIn} />
    } else {
      return <>{children}</>
    }
  }
}

const UnAuthRoute: React.ElementType<Props> = ({ children }) => {
  const { status, currentUser } = useAuthContext()
  if (status === 'beforeLogin' || status === 'loading') {
    // Loading
    return <></>
  } else {
    return !currentUser ? <>{children}</> : <Navigate to={Path.Root} />
  }
}

const App: React.FC = () => {
  return (
    <Maintenance>
      <AuthProvider>
        <AlertProvider>
          <Routes>
            <Route
              path="/"
              element={
                <AuthRoute>
                  <Home />
                </AuthRoute>
              }
            />
            <Route
              path={Path.Plans}
              element={
                <AuthRoute>
                  <Plans />
                </AuthRoute>
              }
            />
            <Route
              path={Path.Boats}
              element={
                <AuthRoute>
                  <Boats />
                </AuthRoute>
              }
            />
            <Route
              path={Path.Tackles}
              element={
                <AuthRoute>
                  <Tackles />
                </AuthRoute>
              }
            />
            <Route
              path={Path.BoardingProcedures}
              element={
                <AuthRoute>
                  <BoardingProcedures />
                </AuthRoute>
              }
            />
            <Route
              path={Path.FishingSeatProcedures}
              element={
                <AuthRoute>
                  <FishingSeatProcedures />
                </AuthRoute>
              }
            />
            <Route
              path={Path.BoardingProceduresAdd}
              element={
                <AuthRoute>
                  <BoardingProceduresAdd />
                </AuthRoute>
              }
            />
            <Route
              path={Path.FishingSeatProceduresAdd}
              element={
                <AuthRoute>
                  <FishingSeatProceduresAdd />
                </AuthRoute>
              }
            />
            <Route
              path={Path.PlansAdd}
              element={
                <AuthRoute>
                  <PlansAdd />
                </AuthRoute>
              }
            />
            <Route
              path={Path.BoatsAdd}
              element={
                <AuthRoute>
                  <BoatsAdd />
                </AuthRoute>
              }
            />
            <Route
              path={Path.TacklesAdd}
              element={
                <AuthRoute>
                  <TacklesAdd />
                </AuthRoute>
              }
            />
            <Route
              path={Path.BoardingProceduresAdd}
              element={
                <AuthRoute>
                  <BoardingProceduresAdd />
                </AuthRoute>
              }
            />
            <Route
              path="/plans/edit/:planId"
              element={
                <AuthRoute>
                  <PlansEdit />
                </AuthRoute>
              }
            />
            <Route
              path="/boats/edit/:boatId"
              element={
                <AuthRoute>
                  <BoatsEdit />
                </AuthRoute>
              }
            />
            <Route
              path="/tackles/edit/:tackleId"
              element={
                <AuthRoute>
                  <TacklesEdit />
                </AuthRoute>
              }
            />
            <Route
              path="/boarding-procedures/edit/:boardingprocedureId"
              element={
                <AuthRoute>
                  <BoardingProceduresEdit />
                </AuthRoute>
              }
            />
            <Route
              path="/fishing-seat-procedures/edit/:fishingSeatProceduresId"
              element={
                <AuthRoute>
                  <FishingSeatProceduresEdit />
                </AuthRoute>
              }
            />
            <Route
              path={Path.Incomes}
              element={
                <AuthRoute>
                  <Incomes />
                </AuthRoute>
              }
            />
            <Route
              path={Path.Account}
              element={
                <AuthRoute>
                  <Account />
                </AuthRoute>
              }
            />
            <Route
              path={Path.LogIn}
              element={
                <UnAuthRoute>
                  <LogIn />
                </UnAuthRoute>
              }
            />
            <Route path={Path.Help} element={<Help />} />
            <Route
              path="/help/section/:sectionId/article/:articleId"
              element={<HelpArticle />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AlertProvider>
      </AuthProvider>
    </Maintenance>
  )
}

export default App
