import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import TackleItem from '~/components/molecules/tackleItem'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Tackle } from '~/model/account/tackle'
import { getTackles } from '~/repositories/tackleRepository'

const TacklesBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [guideTackleList, setGuideTackleList] = useState<Tackle[]>([])

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const tackles = await getTackles(currentUser.user.uid)
          setGuideTackleList(tackles)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  return (
    <Stack spacing={1.5} width="100%">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant={isDesktop ? 'h1' : 'h2'}>タックル一覧</Typography>
        <Link to="add">
          <Typography
            variant="button"
            component="div"
            bgcolor="primary.main"
            textAlign="center"
            height={isDesktop ? '40px' : '32px'}
            lineHeight={isDesktop ? '40px' : '32px'}
            width="140px"
            borderRadius="8px"
          >
            +タックルを登録
          </Typography>
        </Link>
      </Stack>
      <Stack divider={<Divider />}>
        {guideTackleList.map((tackle) => (
          <TackleItem tackle={tackle} />
        ))}
      </Stack>
    </Stack>
  )
}

export default TacklesBody
