import React, { FC, useEffect, useState } from 'react'

import { FirestoreMaintenance } from '~/model/maintenance'
import { getConfigure } from '~/repositories/maintenanceRepository'

interface Props {
  children: React.ReactNode
}

export const Maintenance: FC<Props> = ({ children }) => {
  const [configure, setConfigure] = useState<FirestoreMaintenance>()

  useEffect(() => {
    const cleanup = async () => {
      const result = await getConfigure()
      setConfigure(result)
    }
    cleanup()
  }, [])

  if (!configure) {
    // loading
    return <></>
  }

  return configure.guidePortalMaintenance ? <>maintenance</> : <>{children}</>
}

export default Maintenance
