import { AlertColor, SnackbarCloseReason } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import styles from './styles.module.css'

interface AlertContextValue {
  toggleAlert: (
    isOpen: boolean,
    severity?: AlertColor,
    message?: string | unknown
  ) => void
}

const AlertContext = createContext<AlertContextValue | undefined>(undefined)

export const useAlertContext = () => {
  const context = useContext(AlertContext)
  if (!context) {
    throw new Error('useAlertContext must be used within a AlertProvider')
  }
  return context
}

interface Props {
  isOpen: boolean
  severity: AlertColor
  message?: string | unknown
  onClose?: () => void
}

const AlertComponent: FC<Props> = ({ isOpen, severity, message, onClose }) => {
  const handleClose = useCallback((reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose?.()
  }, [])

  const messageText = useMemo(() => {
    if (message instanceof Error) {
      return message.message
    } else {
      return typeof message === 'string' ? message : 'エラーが派生しました。'
    }
  }, [message])

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={(_, reason) => handleClose(reason)}
    >
      <MuiAlert
        className={styles.alert}
        elevation={6}
        variant="filled"
        severity={severity}
      >
        {messageText}
      </MuiAlert>
    </Snackbar>
  )
}

interface AlertProviderProps {
  children: React.ReactNode
}

export const AlertProvider: FC<AlertProviderProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [severity, setSeverity] = useState<AlertColor>('success')
  const [message, setMessage] = useState<string | unknown>()

  const toggleAlert = (
    isOpen: boolean,
    severity: AlertColor = 'success',
    message?: string | unknown
  ) => {
    setIsOpen(isOpen)
    setSeverity(severity)
    setMessage(message)
  }

  return (
    <AlertContext.Provider value={{ toggleAlert }}>
      <AlertComponent
        isOpen={isOpen}
        severity={severity}
        message={message}
        onClose={() => setIsOpen(false)}
      />
      {props.children}
    </AlertContext.Provider>
  )
}
