import { Box, Typography, useMediaQuery } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import InputText from '~/components/atoms/inputText'
import ConfirmationButton from '~/components/molecules/confirmationButton'
import { useAlertContext } from '~/components/providers/alert'
import { signIn } from '~/repositories/authRepository'

interface IFormInput {
  email: string
  password: string
}

const LoginEmailBody: FC = () => {
  const {
    getValues,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>()
  const [isLoading, setIsLoading] = useState(false)
  const { toggleAlert } = useAlertContext()
  const isTablet = useMediaQuery('(min-width: 0px) and (max-width: 840px)')

  const login = useCallback(async () => {
    const { email, password } = getValues()
    setIsLoading(true)
    try {
      await signIn(email, password)
    } catch (e) {
      const message = e instanceof Error ? e.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsLoading(false)
  }, [getValues('email'), getValues('password')])

  return (
    <form onSubmit={handleSubmit(login)}>
      <Box
        sx={{
          padding: '38px 24px 50px 24px',
          display: 'grid',
          rowGap: '24px',
        }}
      >
        <Typography variant={isTablet ? 'h2' : 'h1'}>
          ガイド管理画面へようこそ！
        </Typography>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <InputText
              inputProps={{
                ...field,
                ...register('email', {
                  required: true,
                  pattern: /[\w\-\\._]+@[\w\-\\._]+\.[A-Za-z]+/,
                }),
              }}
              error={errors.email ? true : false}
              label="メールアドレス"
              placeholder="example@yamameinc.jp"
              type="email"
              size="small"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <InputText
              inputProps={{
                ...field,
                ...register('password', { required: true, minLength: 6 }),
              }}
              error={errors.password ? true : false}
              label="パスワード"
              type="password"
              size="small"
            />
          )}
        />
        <ConfirmationButton loading={isLoading} />
      </Box>
    </form>
  )
}

export default LoginEmailBody
