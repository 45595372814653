import { FC } from 'react'

import Container from '~/components/organisms/container'
import Head from '~/components/organisms/head'
import Header from '~/components/organisms/header'
import HomeBody from '~/components/organisms/homeBody'
import NotPermittedBody from '~/components/organisms/notPermittedBody'
import { useAuthContext } from '~/components/providers/auth'

const IncomesTemplate: FC = () => {
  const { currentUser } = useAuthContext()
  return (
    <Container>
      <Head />
      <Header />
      {currentUser && currentUser.isPermittedGuide && <HomeBody />}
      {currentUser && !currentUser.isPermittedGuide && <NotPermittedBody />}
    </Container>
  )
}

export default IncomesTemplate
