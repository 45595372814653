import { Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FC, useState } from 'react'

import { ReactComponent as ArrowBottomIcon } from '~/components/svgs/arrow_bottom.svg'

interface Props {
  options: string[]
  selectedIndex?: number
  error?: boolean
  placeholder?: string
  onChangeSelected?: (index: number) => void
}

const SelectedMenu: FC<Props> = ({
  options,
  selectedIndex,
  error,
  placeholder,
  onChangeSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElWidth, setAnchorElWidth] = useState<number>()
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setAnchorElWidth(event.currentTarget.offsetWidth)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    onChangeSelected?.(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <List component="nav" aria-label="Device settings" disablePadding>
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx={{
            height: '40px',
            border: error ? '1px solid #FF0000' : '1px solid #E4E4E4',
            borderRadius: '8px',
          }}
        >
          <ListItemText
            primary={
              selectedIndex !== undefined
                ? options[selectedIndex]
                : placeholder || '選択してください'
            }
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: 'secondary.main',
              component: 'span',
            }}
          />
          <ArrowBottomIcon />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
          sx: { width: anchorElWidth && anchorElWidth },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <Typography
              variant="subtitle1"
              component="span"
              color="secondary.main"
            >
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default SelectedMenu
