import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC } from 'react'

import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as CloseIcon } from '~/components/svgs/close.svg'
import { ReactComponent as LogoutIcon } from '~/components/svgs/logout.svg'

interface Props {
  isOpen: boolean
  anchorElement: HTMLElement | null
  onClick?: (to: string) => void
  onClickLogout?: () => void
  onClose?: () => void
}

interface ListItemProps {
  title?: string
  endIcon?: React.ReactNode
  onClick?: () => void
}
const ListItem: FC<ListItemProps> = ({ title, endIcon, onClick }) => {
  return (
    <ListItemButton onClick={onClick} sx={{ padding: '4px 0' }}>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: 'subtitle1',
          color: 'secondary.main',
          component: 'span',
        }}
      />
      {endIcon && endIcon}
    </ListItemButton>
  )
}

const HeaderMenu: FC<Props> = ({
  isOpen,
  anchorElement,
  onClick,
  onClickLogout,
  onClose,
}) => {
  const { currentUser } = useAuthContext()
  const isDesktop = useMediaQuery('(min-width: 841px)')

  if (isDesktop && currentUser) {
    return (
      <Menu
        anchorEl={anchorElement}
        id="account-menu"
        open={isOpen && isDesktop}
        onClose={onClose}
        onClick={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '240px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 30,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack padding="16px" divider={<Divider />} spacing={1.5}>
          <Stack spacing={1}>
            <Typography variant="h4" component="span" width="100%">
              ログイン中
            </Typography>
            <Typography
              variant="subtitle1"
              color="secondary.main"
              component="span"
              width="100%"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {currentUser.user.email}
            </Typography>
          </Stack>
          <List component="nav" aria-label="account menus" disablePadding>
            <ListItem
              title="アカウント"
              onClick={() => onClick?.('/account')}
            />
            <ListItem title="船の設備" onClick={() => onClick?.('/boats')} />
            <ListItem title="タックル" onClick={() => onClick?.('/tackles')} />
            <ListItem title="釣りプラン" onClick={() => onClick?.('/plans')} />
            <ListItem
              title="乗船手順"
              onClick={() => onClick?.('/boarding-procedures')}
            />
            <ListItem
              title="釣り座決定"
              onClick={() => onClick?.('/fishing-seat-procedures')}
            />
          </List>
          <List component="nav" aria-label="other menus" disablePadding>
            <ListItem
              title="ヘルプセンター"
              onClick={() => onClick?.('/help')}
            />
            <ListItem
              title="ログアウト"
              endIcon={<LogoutIcon />}
              onClick={() => onClickLogout?.()}
            />
          </List>
        </Stack>
      </Menu>
    )
  }

  if (!isDesktop && currentUser) {
    return (
      <Drawer anchor="right" open={isOpen && !isDesktop} onClose={onClose}>
        <Stack width="280px" padding="16px" spacing={3} divider={<Divider />}>
          <Stack alignItems="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h4"
              component="span"
              marginTop="12px"
              width="100%"
            >
              ログイン中
            </Typography>
            <Typography
              variant="subtitle1"
              color="secondary.main"
              component="span"
              marginTop="8px"
              width="100%"
            >
              {currentUser.user.email}
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" component="span">
              アカウント
            </Typography>
            <List component="nav" aria-label="account menus" disablePadding>
              <ListItem
                title="プロフィール情報"
                onClick={() => onClick?.('/account')}
              />
              <ListItem
                title="アクセス情報"
                onClick={() => onClick?.('/account?page=access')}
              />
              <ListItem
                title="連絡先情報"
                onClick={() => onClick?.('/account?page=contact-method')}
              />
              <ListItem
                title="口座情報"
                onClick={() => onClick?.('/account?page=bank-account')}
              />
            </List>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" component="span">
              船の設備
            </Typography>
            <List component="nav" aria-label="boat menus" disablePadding>
              <ListItem title="船一覧" onClick={() => onClick?.('/boats')} />
              <ListItem
                title="船を追加"
                onClick={() => onClick?.('/boats/add')}
              />
            </List>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" component="span">
              タックル
            </Typography>
            <List component="nav" aria-label="boat menus" disablePadding>
              <ListItem
                title="タックル一覧"
                onClick={() => onClick?.('/tackles')}
              />
              <ListItem
                title="タックルを追加"
                onClick={() => onClick?.('/tackles/add')}
              />
            </List>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" component="span">
              釣りプラン
            </Typography>
            <List component="nav" aria-label="plan menus" disablePadding>
              <ListItem
                title="プラン一覧"
                onClick={() => onClick?.('/plans')}
              />
              <ListItem
                title="プランを追加"
                onClick={() => onClick?.('/plans/add')}
              />
            </List>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" component="span">
              乗船手順
            </Typography>
            <List
              component="nav"
              aria-label="boarding-procedures menus"
              disablePadding
            >
              <ListItem
                title="乗船手順一覧"
                onClick={() => onClick?.('/boarding-procedures')}
              />
              <ListItem
                title="乗船手順を追加"
                onClick={() => onClick?.('/boarding-procedures/add')}
              />
            </List>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" component="span">
              釣り座決定
            </Typography>
            <List
              component="nav"
              aria-label="fishing-seat-procedures menus"
              disablePadding
            >
              <ListItem
                title="釣り座決定一覧"
                onClick={() => onClick?.('/fishing-seat-procedures')}
              />
              <ListItem
                title="釣り座決定を追加"
                onClick={() => onClick?.('/fishing-seat-procedures/add')}
              />
            </List>
          </Stack>
          <List component="nav" aria-label="other menus" disablePadding>
            <ListItem
              title="ヘルプセンター"
              onClick={() => onClick?.('/help')}
            />
            <ListItem
              title="ログアウト"
              endIcon={<LogoutIcon />}
              onClick={() => onClickLogout?.()}
            />
          </List>
        </Stack>
      </Drawer>
    )
  }
  return <></>
}

export default HeaderMenu
