import { createTheme, Stack, ThemeProvider } from '@mui/material'
import { FC, ReactNode } from 'react'

import styles from './styles.module.css'

const theme = createTheme({
  typography: {
    fontFamily: ['游ゴシック体', 'YuGothic', '游ゴシック', 'Yu Gothic'].join(
      ','
    ),
    h1: {
      color: '#5F5F5F',
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: 22,
    },
    h2: {
      color: '#5F5F5F',
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: 20,
    },
    h3: {
      color: '#5F5F5F',
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: 18,
    },
    h4: {
      color: '#5F5F5F',
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: 16,
    },
    h5: {
      color: '#5F5F5F',
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: 14,
    },
    h6: {
      color: '#5F5F5F',
      fontWeight: 500,
      letterSpacing: '0.01em',
      fontSize: 12,
    },
    subtitle1: {
      color: '#959595',
      fontWeight: 500,
      letterSpacing: '0.01em',
      fontSize: 16,
    },
    subtitle2: {
      color: '#959595',
      fontWeight: 500,
      letterSpacing: '0.01em',
      fontSize: 14,
    },
    button: {
      color: '#FFFFFF',
      fontWeight: 500,
      letterSpacing: '0.01em',
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      main: '#00A3FF',
    },
    secondary: {
      main: '#5F5F5F',
    },
    success: {
      main: '#00BD50',
    },
    error: {
      main: '#FF0000',
    },
    info: {
      main: '#E4E4E4',
    },
    grayBackground: {
      main: '#F7F7F7',
    },
    e8: {
      main: '#E8E8E8',
    },
    subtitle: {
      main: '#959595',
    },
    dark: {
      main: '#393939',
    },
    footerBackground: {
      main: '#505050',
    },
    border: {
      main: '#E4E4E4',
    },
    pink: {
      main: '#FF5A78',
    },
    sky: {
      main: '#67D6EE',
    },
    paleBlue: {
      main: '#E1FAFF',
    },
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    grayBackground: Palette['primary']
    e8: Palette['primary']
    subtitle: Palette['primary']
    dark: Palette['primary']
    footerBackground: Palette['primary']
    border: Palette['primary']
    pink: Palette['primary']
    sky: Palette['primary']
    paleBlue: Palette['primary']
  }
  interface PaletteOptions {
    grayBackground: PaletteOptions['primary']
    e8: PaletteOptions['primary']
    subtitle: PaletteOptions['primary']
    dark: PaletteOptions['primary']
    footerBackground: PaletteOptions['primary']
    border: PaletteOptions['primary']
    pink: PaletteOptions['primary']
    sky: PaletteOptions['primary']
    paleBlue: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    subtitle: true
    error: true
  }
}

interface Props {
  children?: ReactNode
}

const Container: FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Stack minHeight="100%">{children}</Stack>
    </ThemeProvider>
  )
}

export default Container
