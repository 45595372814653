import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'

export const converter = <T>(): FirestoreDataConverter<T> => ({
  toFirestore: (data: T) => {
    return data
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<T>,
    options: SnapshotOptions
  ) => {
    const data = snapshot.data(options)
    return data
  },
})
