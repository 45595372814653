import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC } from 'react'

import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as CheckIcon } from '~/components/svgs/check.svg'

const HomeBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        alignItems: 'center',
        rowGap: '32px',
        ['@media screen and (min-width: 841px)']: {
          width: '566px',
          paddingTop: '48px',
          paddingBottom: '60px',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack
          width="100%"
          boxSizing="border-box"
          padding={isDesktop ? '0' : '38px 24px 0 24px'}
          spacing={isDesktop ? '60px' : '50px'}
          alignItems="center"
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CheckIcon />
            <Typography variant="h4" component="span">
              {`「${currentUser?.user?.email}」でログインしています`}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default HomeBody
