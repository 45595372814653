import {
  Button,
  Divider,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC } from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'
import { SharedCategory } from '~/model/plan/shared'

import InputSharedCategoryItem from './inputCategoryItem'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const InputPrice = styled(TextField)({
  ['& fieldset']: {
    borderRadius: '8px',
  },
  width: '100px',
})

const SuffixText: FC<{ text: string }> = ({ text }) => {
  return (
    <Typography variant="h3" component="span" fontWeight={500}>
      {text}
    </Typography>
  )
}

const InputSharedPriceSection: FC<Props> = ({
  getValues,
  setValue,
  register,
  control,
  formState: { errors },
  title,
}) => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { fields, append } = useFieldArray({
    control,
    name: 'shared.categories',
  })
  const capacityReg = register('shared.capacity', {
    required: true,
    valueAsNumber: true,
    min: 1,
    max: 100,
  })

  // Category item.
  const addCategory = () => {
    append({
      id: fields.length + 1,
      name: '一般',
      price: 0,
    })
  }
  const updateCategory = (category: SharedCategory, index: number) => {
    setValue(`shared.categories.${index}`, category)
  }
  const removeCategory = (index: number) => {
    const categories = getValues('shared.categories')

    const newCategories = categories
      .filter((_, i) => index !== i)
      .sort((a, b) => a.id - b.id)
      .map((s, i) => {
        return { ...s, step: i + 1 }
      })
    setValue('shared.categories', newCategories)
  }

  return (
    <SectionContainer title={title}>
      <Stack spacing={2.5}>
        <Stack spacing={1}>
          <Typography variant="h5" component="span">
            属性別の料金
          </Typography>
          {fields.map((item, index) => (
            <InputSharedCategoryItem
              index={index}
              control={control}
              onChangeCategory={(category) => updateCategory(category, index)}
              onRemoveCategory={() => removeCategory(index)}
            />
          ))}
          <Button onClick={addCategory}>
            <Typography variant="h5" color="primary.main">
              追加する
            </Typography>
          </Button>
        </Stack>
        {isDesktop && <Divider />}
        <Stack spacing={1}>
          <Typography variant="h5" component="span">
            一度に予約できる人数
          </Typography>
          <Typography
            variant="h6"
            component="span"
            color="subtitle.main"
            whiteSpace="pre-wrap"
          >
            {'このプランで一度に予約できる人数'}
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Controller
              name="shared.capacity"
              control={control}
              render={({ field }) => (
                <InputPrice
                  {...field}
                  {...capacityReg}
                  inputRef={capacityReg.ref}
                  error={Boolean(errors.charter?.capacity)}
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="10"
                />
              )}
            />
            <SuffixText text="人" />
          </Stack>
        </Stack>
      </Stack>
    </SectionContainer>
  )
}

export default InputSharedPriceSection
