import {
  Backdrop,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import BasicButton from '~/components/atoms/basicButton'
import Loading from '~/components/atoms/loading'
import BreadcrumbList from '~/components/molecules/breadcrumbList'
import InputTackleDescriptionSection from '~/components/molecules/tackleSections/inputTackleDescription'
import InputTackleImagesSection from '~/components/molecules/tackleSections/inputTackleImageSection'
import InputTackleTitleSection from '~/components/molecules/tackleSections/inputTackleTitleSection'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { FishingSeatProcedureBase } from '~/model/account/fishingSeatProcedure'
import {
  addFishingSeatProcedure,
  getFishingSeatProcedure,
  updateFishingSeatProcedure,
} from '~/repositories/fishingSeatProcedureRepository'

interface Props {
  // fishingSeatProcedureIdがある場合は編集モード
  fishingSeatProcedureId?: string
}

const FishingSeatProceduresWriteBody: FC<Props> = ({
  fishingSeatProcedureId,
}) => {
  const [isWriting, setIsWriting] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const form = useForm<FishingSeatProcedureBase>()
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const navigate = useNavigate()

  const title = useMemo(
    () => (fishingSeatProcedureId ? '釣り座決定を編集' : '釣り座決定を追加'),
    [fishingSeatProcedureId]
  )

  useEffect(() => {
    if (!currentUser) return
    // 初期化処理
    if (fishingSeatProcedureId) {
      // 編集画面
      const cleanup = async () => {
        const fishingSeatProcedure = await getFishingSeatProcedure(
          currentUser.user.uid,
          fishingSeatProcedureId
        )
        form.setValue('title', fishingSeatProcedure.title)
        form.setValue('description', fishingSeatProcedure.description)
        form.setValue('imageUrlList', fishingSeatProcedure.imageUrlList)
        setInitialized(true)
      }
      cleanup()
    } else {
      // 追加画面
      form.setValue('title', '')
      form.setValue('imageUrlList', [])
      setInitialized(true)
    }
  }, [fishingSeatProcedureId])

  const onSubmit: SubmitHandler<FishingSeatProcedureBase> = async (data) => {
    if (!currentUser) return
    setIsWriting(true)
    try {
      if (fishingSeatProcedureId) {
        await updateFishingSeatProcedure(
          currentUser.user.uid,
          fishingSeatProcedureId,
          data
        )
      } else {
        await addFishingSeatProcedure(currentUser.user.uid, data)
        navigate('/fishing-seat-procedures', { replace: true })
      }
      toggleAlert(
        true,
        'success',
        fishingSeatProcedureId
          ? '釣り座決定を更新しました！'
          : '釣り座決定を追加しました！'
      )
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'エラーが発生しました。'
      toggleAlert(true, 'error', message)
    }
    setIsWriting(false)
    console.log(data)
  }

  return (
    <Stack width="100%" spacing={1.5}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWriting}
      >
        <Loading width={150} height={150} />
      </Backdrop>
      {initialized && (
        <Stack spacing={4}>
          <BreadcrumbList
            items={[
              { name: '釣り座決定一覧', to: '/fishing-seat-procedures' },
              { name: '釣り座決定を追加' },
            ]}
          />
          <Stack component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Typography variant={isDesktop ? 'h1' : 'h2'}>{title}</Typography>
            <InputTackleTitleSection {...form} />
            <Divider />
            <InputTackleImagesSection {...form} />
            <Divider />
            <InputTackleDescriptionSection {...form} />
            <Stack
              paddingTop="24px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <BasicButton
                type="reset"
                title="キャンセル"
                color="info"
                sx={{ width: '140px' }}
                onClick={() =>
                  navigate('/fishing-seat-procedures', { replace: true })
                }
              />
              <BasicButton
                type="submit"
                title={fishingSeatProcedureId ? '更新する' : '登録する'}
                sx={{ width: '220px' }}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default FishingSeatProceduresWriteBody
