import { Button, Grid, Stack, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'

import fishSpecies from './fishes'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const SelectFishes: FC<Props> = ({
  register,
  setValue,
  getValues,
  formState: { errors },
  clearErrors,
  title,
}) => {
  const [selectedIdList, setSelectedIdList] = useState<number[]>(
    getValues('fishes')
  )

  useEffect(() => {
    setValue('fishes', selectedIdList)
    clearErrors('fishes')
  }, [selectedIdList])

  useEffect(() => {
    register('fishes', { required: true })
  }, [])

  const handleItemClick = (id: number) => {
    const selected = selectedIdList.includes(id)
    setSelectedIdList(
      selected
        ? selectedIdList.filter((v) => v !== id)
        : [...selectedIdList, id]
    )
  }

  return (
    <SectionContainer title={title}>
      <Stack spacing={2} width="100%">
        <Grid container spacing={2}>
          {fishSpecies.map((item) => (
            <Grid item>
              <Button
                variant="text"
                disableRipple
                color="secondary"
                onClick={() => handleItemClick(item.id)}
                sx={{
                  borderRadius: '30px',
                  border: selectedIdList.includes(item.id)
                    ? '2px solid #393939'
                    : 'none',
                  height: '40px',
                  backgroundColor: 'grayBackground.main',
                  minWidth: '1px',
                  padding: '0 12px',
                }}
              >
                <Typography variant="h5" component="span">
                  {item.name}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
        {Boolean(errors.fishes) && (
          <Typography
            variant="subtitle2"
            component="span"
            color="error"
            paddingTop="8px"
          >
            釣れる魚は少なくとも一つは選択してください。
          </Typography>
        )}
      </Stack>
    </SectionContainer>
  )
}

export default SelectFishes
