import { Button, Typography } from '@mui/material'
import { FC } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { BoardingProcedureBase } from '~/model/account/boardingProcedure'
import { Step } from '~/model/account/step'

import InputBoardingProcedureStepItem from './stepItem'

interface OwnProps {
  title: string
  onChangeStep?: (step: Step) => void
}

type Props = UseFormReturn<BoardingProcedureBase> & OwnProps

const InputBoardingProcedureStepSection: FC<Props> = ({
  setValue,
  getValues,
  control,
  title,
}) => {
  const { fields, append } = useFieldArray({ control, name: 'steps' })

  const addStep = () => {
    append({ title: '', step: fields.length + 1, imageUrlList: [] })
  }

  const updateStep = (step: Step, index: number) => {
    setValue(`steps.${index}`, step)
  }

  const removeStep = (index: number) => {
    const steps = getValues('steps')
    // remove(index)

    const newSteps = steps
      .filter((_, i) => index !== i)
      .sort((a, b) => a.step - b.step)
      .map((s, i) => {
        return { ...s, step: i + 1 }
      })

    setValue('steps', newSteps)
  }

  return (
    <SectionContainer title={title}>
      {fields.map((step, index) => (
        <InputBoardingProcedureStepItem
          index={index}
          control={control}
          onChangeStep={(step) => updateStep(step, index)}
          onRemoveStep={() => removeStep(index)}
        />
      ))}
      <Button onClick={addStep}>
        <Typography variant="h5" color="primary.main">
          追加する
        </Typography>
      </Button>
    </SectionContainer>
  )
}

export default InputBoardingProcedureStepSection
