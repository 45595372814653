import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import { FC, useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  title: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const InputMonth = styled(TextField)({
  ['& fieldset']: {
    borderRadius: '8px',
  },
  width: '100px',
})

const SuffixText: FC<{ text: string }> = ({ text }) => {
  return (
    <Typography variant="h3" component="span" fontWeight={500}>
      {text}
    </Typography>
  )
}

const InputSeasonSection: FC<Props> = ({
  register,
  control,
  setValue,
  getValues,
  formState: { errors },
  title,
}) => {
  // 通年かどうか
  const [isAllYear, setIsAllYear] = useState(
    getValues('season.start') == null || getValues('season.end') == null
  )

  const startReg = register('season.start', {
    required: !isAllYear,
    valueAsNumber: true,
    min: 1,
    max: 12,
  })

  const endReg = register('season.end', {
    required: !isAllYear,
    valueAsNumber: true,
    min: 1,
    max: 12,
  })

  useEffect(() => {
    if (isAllYear) {
      setValue('season', null)
    }
  }, [isAllYear])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllYear((event.target as HTMLInputElement).value === 'full')
  }

  return (
    <SectionContainer title={title}>
      <Stack spacing={2}>
        <RadioGroup
          defaultValue={isAllYear ? 'full' : 'specific'}
          name="radio-buttons-group"
          onChange={handleChange}
        >
          <FormControlLabel
            value="full"
            control={<Radio color="success" />}
            label={
              <Typography
                variant="subtitle2"
                color="secondary.main"
                component="span"
              >
                通年
              </Typography>
            }
          />
          <FormControlLabel
            value="specific"
            control={<Radio color="success" />}
            label={
              <Typography
                variant="subtitle2"
                color="secondary.main"
                component="span"
              >
                特定の時期だけ
              </Typography>
            }
          />
        </RadioGroup>
        {!isAllYear && (
          <Stack spacing={1} direction="row" alignItems="center">
            <Controller
              name="season.start"
              control={control}
              render={({ field }) => (
                <InputMonth
                  {...field}
                  {...startReg}
                  inputRef={startReg.ref}
                  error={Boolean(errors.season)}
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="1"
                />
              )}
            />
            <SuffixText text="月〜" />
            <Controller
              name="season.end"
              control={control}
              render={({ field }) => (
                <InputMonth
                  {...field}
                  {...endReg}
                  inputRef={endReg.ref}
                  error={Boolean(errors.season)}
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="9"
                />
              )}
            />
            <SuffixText text="月" />
          </Stack>
        )}
      </Stack>
    </SectionContainer>
  )
}

export default InputSeasonSection
