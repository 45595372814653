import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'

import auth from '~/utils/auth'

export const signIn = (email: string, passwrod: string) => {
  return signInWithEmailAndPassword(auth, email, passwrod)
}

export const logout = () => {
  return signOut(auth)
}

export const sendLogInPasswordResetEmail = async (email: string, redirectUrl: string) => {
  return sendPasswordResetEmail(auth, email, { url: redirectUrl })
}
