const fishingTypes = [
  {
    id: 1,
    name: 'コマセ釣り',
  },
  {
    id: 2,
    name: '泳がせ釣り',
  },
  {
    id: 3,
    name: 'LT根魚',
  },
  {
    id: 4,
    name: 'サビキ釣り',
  },
  {
    id: 5,
    name: '天秤フカセ釣り',
  },
  {
    id: 8,
    name: '完全フカセ釣り',
  },
  {
    id: 6,
    name: 'ルアーキャスティング',
  },
  {
    id: 10,
    name: 'ジギング',
  },
  {
    id: 11,
    name: 'エビング',
  },
  {
    id: 17,
    name: 'タイラバ',
  },
  {
    id: 7,
    name: '一本釣り',
  },
  {
    id: 9,
    name: 'スッテ/プラヅノ（イカ釣り）',
  },
  {
    id: 16,
    name: 'ティップラン',
  },
  {
    id: 18,
    name: 'バチコン',
  },
  {
    id: 20,
    name: 'アジング',
  },
  {
    id: 21,
    name: 'メバリング',
  },
  {
    id: 23,
    name: 'チニング',
  },
  {
    id: 24,
    name: 'チヌトップ',
  },
  {
    id: 19,
    name: 'フライフィッシング',
  },
  {
    id: 22,
    name: '落とし込み',
  },
  {
    id: 14,
    name: 'タコエギ',
  },
  {
    id: 15,
    name: 'テンヤ（タコ）',
  },
  {
    id: 12,
    name: '胴付き',
  },
  {
    id: 13,
    name: 'カットウ釣り',
  },
  {
    id: 0,
    name: 'その他',
  },
]

export default fishingTypes
