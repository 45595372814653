export const CANCELLATION_POLICY = {
  fullRefund: 'full_refund',
  flexible: 'flexible',
  moderate: 'moderate',
  strict: 'strict',
  notRefund: 'not_refund',
} as const

export type CancellationPolicy =
  typeof CANCELLATION_POLICY[keyof typeof CANCELLATION_POLICY]
