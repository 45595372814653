import {
  Breadcrumbs,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import { FC, useEffect } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useParams } from 'react-router-dom'
import remarkGfm from 'remark-gfm'

import BreadcrumbList from '~/components/molecules/breadcrumbList'
import { articles, HelpArticle } from '~/model/help/helpArticle'
import { HelpSectionModel, sections } from '~/model/help/helpSection'

import styles from './styles.module.css'

const HelpArticleBody: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const [article, setArticle] = useState<HelpArticle>()
  const [section, setSection] = useState<HelpSectionModel>()
  const { sectionId, articleId } = useParams()

  useEffect(() => {
    if (!sectionId || !articleId) return
    const section = sections.filter((item) => item.id === sectionId)[0]
    const article = articles.filter(
      (item) => item.sectionId === sectionId && item.id === articleId
    )[0]
    setSection(section)
    setArticle(article)
  }, [sectionId])
  return (
    <Stack
      direction={'column'}
      maxWidth={isDesktop ? '700px' : '100%'}
      padding={isDesktop ? '32px 0 60px 0' : '24px 24px 50px 24px'}
      margin="0 auto"
      spacing={isDesktop ? 4 : 2}
    >
      {article && section && (
        <BreadcrumbList
          items={[
            { name: '全て', to: '/help' },
            { name: section.title },
            { name: article.title },
          ]}
        />
      )}
      {article && (
        <Typography variant="subtitle2" color="secondary.main">
          <ReactMarkdown
            className={styles.lineBreak}
            children={article.body.replace(/\\n/g, '\n')}
            remarkPlugins={[remarkGfm]}
          />
        </Typography>
      )}
    </Stack>
  )
}

export default HelpArticleBody
