import { Typography } from '@mui/material'
import { FC, useState } from 'react'

import InputText from '~/components/atoms/inputText'
import EditableSectionContainer, {
  EditableSectionProps,
} from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { updateGuidePhone } from '~/repositories/guideRepository'

interface OwnProps {
  phone: string
  onUpdated?: (name: string) => void
}

type Props = OwnProps & EditableSectionProps

const SectionReservationPhone: FC<Props> = ({
  phone,
  onUpdated,
  editing,
  disabled,
  onClickMode,
}) => {
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPhone, setCurrentPhone] = useState(phone)

  const onSubmit = async () => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      await updateGuidePhone(currentUser.user.uid, currentPhone)
      onUpdated?.(currentPhone)
      toggleAlert(true, 'success', 'ご予約の確認先を更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  return (
    <EditableSectionContainer
      title="ご予約の確認先"
      description="ツリトリから予約が入った場合に運営事務局からお電話させていただく電話番号です。"
      editing={editing}
      disabled={disabled}
      loading={isLoading}
      onClickSave={onSubmit}
      onClickMode={() => {
        setCurrentPhone(phone)
        onClickMode?.()
      }}
    >
      {!editing && (
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {phone}
        </Typography>
      )}
      {editing && (
        <InputText
          size="small"
          value={currentPhone}
          onChange={(e) => setCurrentPhone(e.target.value)}
        />
      )}
    </EditableSectionContainer>
  )
}

export default SectionReservationPhone
