import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as ArrowRight } from '~/components/svgs/arrow_right.svg'

interface Props {
  title: string
  to: string
  selected?: boolean
}

const AccountNavigationItem: FC<Props> = ({ title, to, selected }) => {
  return (
    <Link to={to}>
      <Stack
        height="42px"
        direction="row"
        padding="0 12px"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={selected ? 'grayBackground.main' : 'white'}
      >
        <Typography
          variant={selected ? 'h5' : 'subtitle2'}
          component="span"
          color="secondary.main"
        >
          {title}
        </Typography>
        <ArrowRight height="16px" width="16px" />
      </Stack>
    </Link>
  )
}

export default AccountNavigationItem
