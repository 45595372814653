// Anglersに登録されている魚種とIDを参照
// https://anglers.jp/fishes
const fishSpecies = [
  {
    id: 11,
    name: 'タイ',
  },
  {
    id: 12,
    name: 'アジ',
  },
  {
    id: 293,
    name: 'マアジ',
  },
  {
    id: 13,
    name: 'ヒラメ',
  },
  {
    id: 14,
    name: 'カサゴ',
  },
  {
    id: 322,
    name: 'オニカサゴ',
  },
  {
    id: 16,
    name: 'コチ',
  },
  {
    id: 17,
    name: 'キス',
  },
  {
    id: 18,
    name: 'タチウオ',
  },
  {
    id: 19,
    name: 'ハゼ',
  },
  {
    id: 2,
    name: 'シーバス',
  },
  {
    id: 20,
    name: 'ソイ',
  },
  {
    id: 21,
    name: 'ハタ',
  },
  {
    id: 22,
    name: 'サバ',
  },
  {
    id: 469,
    name: 'マダコ',
  },
  {
    id: 25,
    name: 'カマス',
  },
  {
    id: 26,
    name: 'エソ',
  },
  {
    id: 27,
    name: 'シイラ',
  },
  {
    id: 29,
    name: 'ダツ',
  },
  {
    id: 3,
    name: 'イカ',
  },
  {
    id: 106,
    name: 'ヤリイカ',
  },
  {
    id: 108,
    name: 'スルメイカ',
  },
  {
    id: 103,
    name: 'アオリイカ',
  },
  {
    id: 843,
    name: 'マルイカ',
  },
  {
    id: 30,
    name: 'メジナ',
  },
  {
    id: 31,
    name: 'カワハギ',
  },
  {
    id: 32,
    name: 'シログチ',
  },
  {
    id: 35,
    name: 'カンパチ',
  },
  {
    id: 37,
    name: 'カレイ',
  },
  {
    id: 530,
    name: 'ムシガレイ',
  },
  {
    id: 38,
    name: 'イワシ',
  },
  {
    id: 540,
    name: 'ウルメイワシ',
  },
  {
    id: 39,
    name: 'ホウボウ',
  },
  {
    id: 40,
    name: 'アナゴ',
  },
  {
    id: 41,
    name: 'ヒイラギ',
  },
  {
    id: 42,
    name: 'イサキ',
  },
  {
    id: 43,
    name: 'ヒラマサ',
  },
  {
    id: 44,
    name: 'ヤガラ',
  },
  {
    id: 45,
    name: 'ムツ',
  },
  {
    id: 46,
    name: 'カツオ',
  },
  {
    id: 47,
    name: 'ハモ',
  },
  {
    id: 50,
    name: 'クエ',
  },
  {
    id: 51,
    name: 'マカジカ',
  },
  {
    id: 52,
    name: 'マグロ',
  },
  {
    id: 53,
    name: 'ホッケ',
  },
  {
    id: 55,
    name: 'タラ',
  },
  {
    id: 57,
    name: 'サヨリ',
  },
  {
    id: 6,
    name: 'サワラ',
  },
  {
    id: 60,
    name: 'トビウオ',
  },
  {
    id: 61,
    name: 'カジキ',
  },
  {
    id: 62,
    name: 'ヒメジ',
  },
  {
    id: 63,
    name: 'ベラ',
  },
  {
    id: 659,
    name: 'トラフグ',
  },
  {
    id: 657,
    name: 'ショウサイフグ',
  },
  {
    id: 65,
    name: 'アイゴ',
  },
  {
    id: 66,
    name: 'アカメ',
  },
  {
    id: 67,
    name: 'バラムツ',
  },
  {
    id: 68,
    name: 'ギンポ',
  },
  {
    id: 7,
    name: 'メバル',
  },
  {
    id: 1677,
    name: 'タヌキメバル',
  },
  {
    id: 73,
    name: 'クロサギ',
  },
  {
    id: 74,
    name: 'エビ',
  },
  {
    id: 86,
    name: 'イシナギ',
  },
  {
    id: 230,
    name: 'キントキダイ',
  },
  {
    id: 201,
    name: 'キンメダイ',
  },
  {
    id: 195,
    name: 'メダイ',
  },
  {
    id: 187,
    name: 'マダイ',
  },
  {
    id: 5,
    name: 'ブリ',
  },
  {
    id: 913,
    name: 'ワラサ',
  },
  {
    id: 920,
    name: 'イナダ',
  },
  {
    id: 922,
    name: 'ワカシ',
  },
  {
    id: 398,
    name: 'マハタ',
  },
  {
    id: 560,
    name: 'アカムツ',
  },
  {
    id: 562,
    name: 'シロムツ',
  },
  {
    id: 561,
    name: 'クロムツ',
  },
  {
    id: 208,
    name: 'アマダイ',
  },
  {
    id: 229,
    name: 'ハナダイ',
  },
  {
    id: 185,
    name: 'クロダイ',
  },
  {
    id: 975,
    name: 'キビレ',
  },
  {
    id: 190,
    name: 'イシダイ',
  },
  {
    id: 198,
    name: 'イトヨリダイ',
  },
  {
    id: 188,
    name: 'レンコダイ',
  },
  {
    id: 430,
    name: 'ヒメコダイ',
  },
  {
    id: 420,
    name: 'アラ',
  },
  {
    id: 0,
    name: 'その他',
  },
]

export default fishSpecies
