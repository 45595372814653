import { Box, IconButton, styled } from '@mui/material'
import { FC, useCallback, useRef, useState } from 'react'

import Loading from '~/components/atoms/loading'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { ReactComponent as CloseIcon } from '~/components/svgs/circle_cross.svg'
import { ReactComponent as InputLogo } from '~/components/svgs/input_image.svg'
import { UploadType } from '~/model/uploadType'
import { uploadFile } from '~/repositories/storageRepository'

interface Props {
  type: UploadType
  imageUrl?: string
  onAddImageUrl?: (url: string) => void
  onClose?: () => void
}

const StyledImg = styled('img')({
  width: '112px',
  height: '112px',
})

const InputImage: FC<Props> = ({ type, imageUrl, onAddImageUrl, onClose }) => {
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()

  const onFileChange = useCallback(
    async (file: File) => {
      try {
        setLoading(true)
        if (!currentUser) {
          throw new Error('認証情報が取得できません。')
        }
        const url = await uploadFile(file, currentUser.user.uid, type)
        onAddImageUrl?.(url)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        const message =
          error instanceof Error ? error.message : 'エラーが発生しました。'
        toggleAlert(true, 'error', message)
      }
    },
    [type, currentUser]
  )

  return (
    <>
      {imageUrl && (
        <Box position="relative">
          <StyledImg src={imageUrl} />
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: '-15px', right: '-15px' }}
          >
            <CloseIcon width="20px" height="20px" />
          </IconButton>
        </Box>
      )}
      {!imageUrl && (
        <>
          {loading && <Loading width={112} height={112} />}
          {!loading && (
            <IconButton
              sx={{ width: '112px', height: '112px', padding: '0' }}
              onClick={() => inputRef.current?.click()}
            >
              <InputLogo />
              <input
                hidden
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0]
                  if (file) {
                    onFileChange(file)
                  }
                }}
              />
            </IconButton>
          )}
        </>
      )}
    </>
  )
}

export default InputImage
