export interface HelpArticle {
  id: string
  sectionId: string
  title: string
  body: string
}

const origin = window.location.origin
const articleUrl = (sectionId: string, articleId: string) => {
  return `${origin}/help/section/${sectionId}/article/${articleId}`
}
const imageUrl = (name: string) => {
  return `/static/images/${name}.png`
}
const termsUrl = `${origin}/terms`

export const cancellationPolicies = (
  value: 'full-refund' | 'flexible' | 'moderate' | 'strict' | 'no-refund'
) => {
  switch (value) {
    case 'full-refund':
      return `当日の出船時間までにキャンセル: 全額返金
当日の出船時間を過ぎてのキャンセル: 返金なし
`
    case 'flexible':
      return `前日の正午までにキャンセル: 全額返金
前日の正午を過ぎてのキャンセル: 返金なし
`
    case 'moderate':
      return `7 日前の正午までにキャンセル: 全額返金
7 日前の正午を過ぎてから前日の正午までにキャンセル: 60%返金
前日の正午を過ぎてからのキャンセル: 返金なし
`
    case 'strict':
      return `30 日前の正午までにキャンセル: 全額返金
30 日前の正午を過ぎてから 7 日前の正午までにキャンセル: 70%返金
7 日前の正午を過ぎてから前日の正午までにキャンセル: 60%返金
前日の正午を過ぎてからのキャンセル: 返金なし
`
    case 'no-refund':
      return `予約確定後のキャンセルは全て返金不可
`
  }
}

export const articles: HelpArticle[] = [
  // 予約
  {
    id: '1',
    sectionId: '1',
    title: 'リクエスト予約について',
    body: `
# リクエスト予約とは

ツリトリ上でゲストがガイドの釣りプランを予約しようとした際、即時に予約が確定される訳ではなく、ツリトリ運営チームがゲストからの予約を確認した後、ガイドに空き状況と予約承諾の意思を確認した上で、予約を確定又はキャンセルする方式です。

## リクエスト予約の自動キャンセル

ゲストがリクエスト予約を実行後、下記のいずれかの場合には運営チームがリクエスト予約をキャンセルする場合がございますので、ご注意ください。

- リクエストした時点から24時間が経過しても予約確認ができない（連絡がつかない）場合
- 予約締切日時（又は前日の19: 00）までに予約確認ができない（連絡がつかない）場合

例1) 6月19日の15時にリクエスト予約を実行 → 6月20日の15時までに確認できなかった場合はキャンセル
例2) 6月21日開催の釣り予約を前日の6月20日にリクエスト → 釣りプランに設定している「予約締切」までに確認できなかった場合はキャンセル
`,
  },
  {
    id: '2',
    sectionId: '1',
    title: '予約から当日までの流れ',
    body: `
# 予約から当日までの流れ

1.ゲストがツリトリ上で予約をリクエスト

2.ツリトリ運営チームがゲストからの通知を受け、電話にてガイドに予約確定の可否を確認（ゲストからの予約を受け最短1分・最長24時間以内に、確認のご連絡をさせていただきます。）

3.予約が可能だった場合、ツリトリ運営からゲストに予約確定メールを送信(当メールを送信した時点で予約確定となります)

4.ゲストに予約確定メールが送信された後、ガイドに予約情報をメール又はファックスのいずれかご希望の方法で送信します

5.ゲストとやりとりが必要な場合、**4.**で受け取った情報をもとにゲストと直接やり取りを行なってください

6.当日はゲストが提示するデジタル乗船券をご確認の上、釣りを開催してください。

ツリトリで予約するゲストは、事前に乗船料の支払いが完了しています。予約したゲストは、ツリトリ上でデジタル乗船券が表示できるような仕組みになっていますので、そちらの乗船券をご確認の上、釣り体験を開催するようお願いいたします。

![デジタル乗船券](${imageUrl('boat_ticket')})

`,
  },
  {
    id: '3',
    sectionId: '1',
    title: '予約確定後のゲストとの連絡方法について',
    body: `
# 予約確定後のゲストとの連絡方法について

ゲストはリクエスト時に、予約が確定した場合のガイドとの連絡手段を選択することができます。ゲストは、ガイドが管理画面の「アカウント > 連絡先情報 > ゲストとの連絡手段」で設定した項目から予約後の希望の連絡手段を選択します。


例えば、ガイドが電話のみを「アカウント > 連絡先情報 > ゲストとの連絡手段」に設定している場合は、ゲストはリクエスト時に希望連絡手段として、電話のみ選択可能となります。できるだけ、ゲストが安心してやりとりを行えるように、連絡手段は全て（電話、SMS、メール）利用可能に設定しておくようお願いいたします。

## 連絡方法

当日までにゲストに連絡をする必要がある場合には、なるべくゲストが希望している連絡手段でご連絡いただくようお願いいたします。また、ゲストが電話でのやりとりを希望している場合には、予約確定時にお送りする詳細情報にゲストに連絡可能な時間帯も記載されていますので、併せてご確認ください。


予約から当日までの流れをさらに詳しく知りたい方は[予約から当日までの流れ](${articleUrl(
      '1',
      '2'
    )})をご覧ください。
`,
  },
  {
    id: '4',
    sectionId: '1',
    title: '予約制限について',
    body: `
# 予約制限について

ガイドが提供する釣りプランには下記二つの予約制限を設定することが可能です。

- 予約締切日
- 予約可能期間

予約制限の変更について詳しくは「[釣りプランの設定項目](${articleUrl(
      '5',
      '1'
    )})」をご確認ください。

## 予約締切日

最短何日前までの予約を受け付けるかを決める項目です。例えば、2日前と設定した場合は、該当プランを前日に予約することができなくなります。

## 予約可能期間

最長何ヶ月先の予約までを受け付けるかを決める項目です。例えば、3ヶ月先までと設定した場合は、4ヶ月以降先の予約をすることができなくなります。
`,
  },
  // ルール・ガイドライン
  {
    id: '1',
    sectionId: '2',
    title: 'ガイド向けガイドライン',
    body: `
# ガイド向けガイドライン

ツリトリの利用規約は[こちら](${termsUrl})からご確認ください。

ツリトリではユーザーの皆様に安全安心に釣り体験を開催／ご参加いただくため、下記のようなガイドラインを設けています。

## 禁止事項

1.強引な勧誘・斡旋・営業等の行為

以下のような行為は利用規約に違反するため禁止です。

- 他団体やサービスへの入会の斡旋するなどの行為
- ガイド自身が取り扱う商品を執拗に勧める(購入を強制する)などの行為

※ 原則として支払が発生する可能性がある場合には事前に釣り体験ページでの告知が必要となります。
※ ツリトリでの体験内容に関係しない個人情報を聞き出すのはご遠慮ください。

2.釣り体験の支払いをツリトリのシステム外で行う行為

ツリトリ上で予約をおこなった釣り体験に関しては、ツリトリのシステムを通さず事前に料金を徴収する行為は禁止です。なお、現地で販売している釣具や物品などの販売に関してはこの限りではありません。

上記禁止事項を遵守せずツリトリのシステム外で料金を徴収した場合、アカウント停止となる場合がございます。また、悪質な場合は当社が被った損害について利用規約に基づき、その賠償責任を負わなければならないものとします。

3.他ユーザーの画像、文章などの無断使用

ツリトリではトラブル防止のため、他ユーザーの画像、文章などを無断で使用することを禁止としています。

### **違反となる場合**

- ツリトリの体験で得たゲストに関する一切の情報を無断で使用すること
- 他ガイドがツリトリ上で登録している情報を無断で使用すること
- その他、ガイド又はゲストからの指摘により当社が不適切と判断したもの

※ 無断使用についてはツリトリ上でのみの問題に限りません。素材サイトや外部サイトからダウンロードした画像、及び抜粋・引用した文言等の著作権につきましても、使用前に必ずご自身でご確認ください。

4.釣り体験ページ記載内容を遂行しない行為

釣り体験ページ内に記載されている内容は必ず遂行してください。

釣り体験ページに書かれている内容と実際の体験内容に乖離がある場合や、ゲストから複数のクレームが寄せられた際には、釣り体験の予約受付停止、または非公開とさせていただく可能性がございます。

※ 記載時間に比べ明らかに短い・長い時間にならないようご注意ください。
※ ゲストとガイド間の同意の元、釣り体験ページ記載の内容から変更となった場合には本項は該当しません。

5.子供のみの参加

ツリトリでは、未成年（高校生以下）のみのご予約は、ガイド及び保護者の同意がある場合を除いて禁止としています。

ツリトリのシステム上、予約時には「大人」料金での予約となるため、予約完了後の連絡にて、釣り体験に実際に参加するゲスト年齢をご確認いただき、釣り体験開催時は安全面にご配慮くださいますようお願いします。

`,
  },
  {
    id: '3',
    sectionId: '2',
    title: '安全対策のためのガイドライン',
    body: `
# 安全対策のためのガイドライン

## 事故を未然に防ぐために

### 使用する船舶の点検を行う

船舶を使用した釣り体験を提供する場合、法定備品が備えられていることはもちろんのこと、船体自体に不具合がないかなどの点検も出船前に必ず行うようにしてください。

### ゲストに対して注意喚起を行う

安全面から体験に必要と思われる物は予めゲストに持ってきてもらうようお願いしましょう。
ライフジャケット・帽子・サングラス・水筒・運動靴・長袖長ズボンの着用など。
また、当日は水分・塩分補給、適度な休息を心がけながら釣りを行いましょう。

### 緊急時の対応に備える

もしも急にゲストが体調を崩したり、釣りの途中に怪我をしてしまった場合には、すぐに応急処置ができるよう消毒液や絆創膏の準備をし、万が一の事態に備えておきましょう。また、熱中症の予防のため、帽子をかぶり、こまめな水分補給をゲストに促してください。

### 天候状況の把握

開催日の前や開催当日の朝に気象状況を調べ、警報や注意報が発表されていないか十分に情報を収集しましょう。釣り体験の実施・継続が可能か安全を最優先で判断してください。


## 万が一事故が発生したときは

### 情報の収集

事故発生の日時、場所、人数、氏名、性別、年齢、処置の内容、けがの程度などを正確に把握する。

### 情報の発信

当社に事故報告を行う場合、誤解を招かないよう、事実に基づいて正確な表現に努める。

### 事故の記録

収集した情報は、時間の経過に沿って記録する。
`,
  },
  {
    id: '4',
    sectionId: '2',
    title: '車での送迎などについて',
    body: `
# 車での送迎などについて

ツリトリでは旅行業者登録を行っていないため、旅行業法に抵触しない範囲内で釣り体験を開催しなければなりません。

そのため、ツリトリのガイドがゲストから金銭を受け取って自家用車で移動する行為は禁止です。

ただし、特定の条件ではガイドの自家用車にゲストを乗せて移動することが可能です。

## 自家用車にゲストを乗せて移動することが可能な条件

以下のすべてを満たしている場合にのみ、自家用車にゲストを乗せて移動することが可能です。

1.自家用車でのゲストの送迎が無償である
   料金に関する情報を入力する際に「ガソリン代」「送迎代」と記載しないでください。
   釣り体験ページ上で、開催場所（乗船場所など）と集合場所間の送迎が無償であることが分かるようにしてください。

2.送迎されるゲストと、そうでないゲストが支払う体験料金が同じである
   送迎を希望するゲストから別途料金をもらわないでください。

3.その移動が釣りプランに付随する行為である
   開催場所（集合場所、解散場所を含む）への最寄駅等から公共交通機関等の移動手段がなく、最寄駅等から体験開催場所、または体験開催場所から最寄駅等への送迎が無償で行われる場合、無償の送迎は釣りプランの付随行為の一部であると見なすことが可能です。
   開催場所へのアクセスが難しいことを体験紹介ページ上に記載してください。（例：○ 駅から開催場所である △ までは徒歩 1 時間かかります、路線バスも通っていますが週末の場合 1 日 2 本しかありません、など）

   詳細は下記 HP をご覧ください。
   http://wwwtb.mlit.go.jp/chubu/tsukuro/manager/pdf/H30/04.pdf

`,
  },
  {
    id: '5',
    sectionId: '2',
    title: '釣り体験開催における注意事項',
    body: `
# 釣り体験開催における注意事項

## 感染症予防

世界保健機関（WHO）は 2020 年 3 月 11 日、COVID-19 で知られる新型コロナウイルスの感染拡大について、世界的大流行を宣言しました。その後感染は急速に広まり、各国政府が感染拡大を遅らせるためにさまざまな緊急対策を講じてきました。

このような状況に対応するため、ツリトリでは厚生労働省発表の「[新しい生活様式](https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/dengue_fever_qa_00001.html#Q1-2)」を参考に以下のガイドラインを設けます。

釣りプラン作成時、釣り体験開催時には「感染防止の 3 つの基本」の遵守に加え、ガイド特有の注意点など以下ガイドラインをよく読み、十分にご注意いただきますようお願いします。

### 感染防止の３つの基本

1.身体的距離の確保：人との間隔はできるだけ２ m（最低１ m）空ける
2.マスクの着用：外出時、屋内にいる時や会話をするときは症状がなくてもマスクを着用
3.手洗い：こまめな手洗いを行う。手洗いは 30 秒程度かけて水と石けんで丁寧に洗うものや人と触れる前後や場所を移動した際には、手洗いを実施する

### ガイドとして気をつけること

- 不特定多数が触れる可能性がある場所・物はアルコールなどでこまめに清拭消毒する（例：トイレ、手すりなど）
- 他人と共用する物品は最低限にとどめる
- 必ず検温を行い、自分自身、またはゲストの体調に不安がある場合は無理に釣り体験を開催しない
- 開催場所地域における感染拡大状況を把握し、近隣で感染者が出た場合の対応を検討しておく
- 開催日前にゲストに検温を促す、または、集合時に検温の時間を取り熱がある場合は参加を控えてもらう

## 写真や動画撮影時の許可取り

写真や動画を撮りたいときは必ずゲストに許可をとって下さい。
SNS やブログなど Web 上に公開する際には、撮影許可に加えて公開することについてもゲストの承諾を得るようにお願いします。
無許可で撮影、またはインターネットに公開した場合、法律上プライバシーの侵害などで違法になることもあります。
トラブルに発展しかねない可能性がありますのでご注意ください。

`,
  },
  {
    id: '6',
    sectionId: '2',
    title: '法律上の禁止事項',
    body: `
# 法律上の禁止事項

体験を作成する際には、必ず法令に違反する行為およびそのほう助にあたる行為に該当しないかを確認してください。

運営事務局により体験内容が禁止行為に該当すると判断した場合は、体験の非公開や予約キャンセルなどの措置を取る場合があります。

以下は体験を提供するうえで、法令に違反する可能性が高い内容の一例です。適法性に関しては、所管の保健所などに確認してください。

## 海・川の体験

海や川で行う体験については、各都道府県の規制に準じた開催をお願いします。
規制外での開催による事案／事故発生は、ツリトリでは一切責任を負いません。予めご了承下さい。

### 都道府県漁業調整規則で定められている遊漁で使用できる漁具・漁法（海面のみ）

https://www.jfa.maff.go.jp/j/yugyo/y_kisei/kisoku/todo_huken/

東京都におきましては、都の共用水面（川）はどんな規模の川でも、タモ網で魚を取ることは禁止と定められています。タモ網自体が禁止漁法となるため、内水面漁業調整規則（28 条 3 項 4 項）の違反行為となり、違反の場合、同行者も同時に罰せられます。

### 東京都内水面漁業調整規則

https://www.sangyo-rodo.metro.tokyo.lg.jp/nourin/suisan/yuugyo/yuugyorec/rule/

## 遊漁船登録のない船舶体験

船を使っての釣り体験を開催する場合、船の所有者が遊漁船登録を行う必要があります。

※ 詳しくは体験開催場所となる都道府県の遊漁船登録窓口にお問い合わせください。

## 送迎代を含む釣り体験を掲載・販売すること

ツリトリは旅行業者ではないので、旅行業法で禁止されている内容を含む体験を販売することはできません。

ゲストから金銭を受けとって自家用車で移動する行為は旅行業法で禁止されています。

ガイド個人が旅行業者登録のある個人・法人であっても、自家用車で周るツアーをツリトリで販売することはできません。

旅行業法では、「運送」と「宿泊」のサービス提供に関わる企画・ 募集・手配等の業務を「旅行業」と定義しています。（ 第 2 条第 1 項第 1 号）

なお、徒歩での移動や公共交通機関を利用する行為は旅行業法に反しないため、問題ありません。

「[車での送迎などについて](${articleUrl(
      '2',
      '4'
    )})」についてもも併せてご確認ください。

## 宿泊を伴う釣り体験を掲載・販売すること

旅行業法に抵触するため、ツリトリでは宿泊を伴う体験は掲載できません。

体験エリアのお近くの宿泊施設を紹介することは可能です。

ゲストハウスや民泊をしている方がツリトリのガイド登録をすることは可能ですが、ツリトリで宿泊のゲストを募集することは禁止です。必ず日帰り体験のみを掲載・販売してください。

## 複数回開催体験における一括販売(一括支払い)について

ツリトリではゲストの不利益を防ぐため、一旦ゲストから代金を預かり、ガイドの方で不備なく体験の開催を確認できた時点でツリトリからガイドに対して預かっていた代金を引き渡すエスクロー決済(※1)の仕組みを採用しております。
そのため、「全 ● 回」というような、複数回の継続的な開催を想定した体験における一括販売（一括支払い）は、体験の開催が終了する前に代金をガイドに支払ってしまうことになるため禁止となります。

なお、上記の他に、法律の観点からも、年間を通しての体験開催や、一度きりでなく月をまたぎ複数回開催する体験においては、体験内容が「特定継続的役務提供」(※2)に該当する可能性があり、ツリトリでの掲載を許可することができません。

※1)売り手と買い手の間に第三者を介在させることで取引の信頼性を担保する決済

※2)特定継続的役務提供に該当する場合、書面の交付が必要になりますが、書面の交付はツリトリのサービス対応外となります。

`,
  },
  {
    id: '1',
    sectionId: '3',
    title: '確定申告について',
    body: `
# 確定申告について

ツリトリでは、ガイドへの売上振込額は源泉徴収分を差し引かずにお支払いしているため、「支払調書」は発行しておりません。
確定申告の際に収入証明として利用できる「支払報告書」は、ログインページからダウンロード可能です。

なお、確定申告の必要有無などについてはツリトリから個別にご案内をしておりませんので、専門機関や専門家などへご相談ください。

## 支払報告書のダウンロード

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部のメニューから「売上」を選択して、売上画面に移動します

3.売上画面にに表示されている「支払報告書」ボタンをタップします

4.選択している月の支払報告書がダウンロードできるページに移動しますので、そこからダウンロードします


`,
  },
  {
    id: '2',
    sectionId: '3',
    title: '口座情報の変更方法',
    body: `
# 口座情報の変更方法

売上金の受け取り口座の変更方法を下記で説明します。また、当月中の振込処理が完了している場合は、次回以降の振込時に新しい口座情報が適用されますので、予めご了承ください。

## PCの場合

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部のメニューから「アカウント」を選択して、アカウント画面に移動します

3.アカウント画面のサイドメニューより「口座情報」を選択します

4.現在の受け取り口座が表示されますので、右側にある「編集」ボタンより受け取り口座を編集することができます。

## スマホの場合

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部のメニューボタンをタップして表示されたメニューから「口座情報」を選択します

3.現在の受け取り口座が表示されますので、右側にある「編集」ボタンより受け取り口座を編集することができます。

`,
  },
  {
    id: '3',
    sectionId: '3',
    title: '売上を確認する方法',
    body: `
# 売上を確認する

ツリトリ上で売上金を確認する手順を下記でご説明します。

## PCの場合

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部の「売上」アイコンをタップして、売上画面に移動します

3.売上画面に移動すると当月開催した分の売上金が確認できます
例) 「2023年5月 - 売上金」の場合は、6月末に振り込まれる予定の売上金が表示されます

4.前月の売上金を確認したい場合は「< 前月」ボタンより、次月の売上金を確認したい場合は「次月 >」ボタンより確認できます

## スマホの場合

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部のメニューボタンをタップして表示されたメニューから「売上」を選択します

3.売上画面に移動すると当月開催した分の売上金が確認できます
例) 「2023年5月 - 売上金」の場合は、6月末に振り込まれる予定の売上金が表示されます

4.前月の売上金を確認したい場合は「< 前月」ボタンより、次月の売上金を確認したい場合は「次月 >」ボタンより確認できます

`,
  },
  {
    id: '4',
    sectionId: '3',
    title: '売上金が反映されるタイミングについて',
    body: `
# 売上金が反映されるタイミングについて

予約が確定し、釣り体験が無事に開催されると、釣り体験の開催日の翌月に売上金が反映されます。

例）6月25に開催 → 6月26日に売上反映

※ システムの都合上、反映時間は多少前後する可能性があることにご注意ください.

`,
  },
  {
    id: '6',
    sectionId: '3',
    title: '売上金の支払いについて',
    body: `
# 売上金の支払いについて

## 売上の支払いスケジュール

ツリトリでの釣り体験開催による売上は開催月の翌月末に、ご登録いただいている口座宛にお振り込みいたします。なお、振込にかかる手数料は弊社が負担いたします。また、振込依頼人名は 「ヤマメ（カ」となります。また、末日が土・日・祝だった場合は、前営業日の平日15:00までにお振り込みいたします。

例: 7月1日~7月31日に開催された分の売上 → 8月31日 15:00 までにお振り込み

`,
  },
  {
    id: '2',
    sectionId: '4',
    title: 'プロフィール情報を更新する',
    body: `
# プロフィール情報を更新する

ツリトリ上で表示しているプロフィール情報を更新する手順を下記でご説明します。

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部の「ガイド情報」アイコンをクリックして、ガイド情報画面に移動します（スマホの場合は、画面上部のメニューアイコンをタップして表示されたメニューから、「ガイド情報」をタップします。）

3.更新したい項目の右上にある「編集」ボタンをクリックすることで、情報を編集することができるようになります。

4.情報を編集したら、「保存する」ボタンをクリックして、ガイド情報の更新を完了させます。

`,
  },
  {
    id: '3',
    sectionId: '4',
    title: 'ログイン方法',
    body: `
# ログイン方法

※ サービスのリリース後から利用可能となります。

1. guide.tusirtori.com/login にアクセスします
2. ログイン画面が表示されるので、「メールアドレス」と「パスワード」を入力します
3. 「同意して認証に進む」ボタンをクリックします
4. 認証が完了すると、管理画面のトップページに移動します
`,
  },
  {
    id: '4',
    sectionId: '4',
    title: '退会について',
    body: `
# 退会について

退会を希望される場合はツリトリ運営チームへ直接ご連絡いただくようお願いいたします。通常は10営業日以内に退会処理が実行され、処理が完了すると、ガイドへ退会処理完了のメール又はファックスが送信されます。また、売上金がある場合には通常通りのスケジュールで振り込み処理が実施されます。

関連記事: [売上金の支払いについて](${articleUrl('3', '6')})

※ 退会処理が完了すると、それまでのアカウントに紐づいたデータは失効し、使用できなくなります
※ またガイド管理画面の利用もできなくなりますので、確定申告などに必要なデータの取得漏れ等などには十分ご注意ください

## 退会ができない承認できない場合

確定している予約が残っている場合には、退会を処理することができません。確定している予約を開催してから退会処理の依頼をするか、予約をキャンセルしてから退会処理の依頼をいただきますようお願いいたします。

`,
  },
  {
    id: '1',
    sectionId: '5',
    title: '釣りプランの設定項目',
    body: `
# 釣りプランの設定項目

ガイドは自身の釣りプランで下記の項目を設定することが可能です。

- タイトル
- 画像
- 紹介文
- 特徴（「初心者でも簡単」、「手ぶらOK」、「中乗りさんあり」の中から複数選択可）
- 乗船タイプ（乗合・仕立て）
- 釣れる魚
- 集合時間
- 出船時間
- 帰港時間
- 予約締切日
- 予約可能期間
- キャンセルポリシー
- 料金

## タイトル

釣りプランのタイトルを設定します。
ツリトリのトップページやその他のページで表示されやすいように 15〜30 文字程度で設定することを推奨しています。

## 画像

釣りプランの画像を最大 20 枚まで登録することができます。釣りプランに表示される画像一覧には、ガイドのプロフィールで設定した画像も合わせて表示されます。

## 紹介文

釣りプランの紹介文を設定します。また、料金に含まれるモノやサービスもここで明記するようにしましょう。

## 特徴

ツリトリでは初心者も安心して乗れる船宿を見つけやすくするために、「**特徴**」という項目を設定しています。この項目では、下記の三つの特徴で該当するものを複数選択することができます。

- 初心者でも簡単
- 手ぶらOK
- 中乗りさんあり

## 乗船タイプ

**乗合**又は**仕立て**のいずれかを設定します。

## 釣れる魚

この釣りプランのターゲットとなる魚やよく釣れる魚の一覧を設定します。

## 集合時間

集合時間を設定します。集合場所については、ガイドのプロフィールより設定することができます。

関連記事: [プロフィール情報を更新する](${articleUrl('4', '2')})

## 出船時間

出船時間を設定します。

## 帰港時間

帰港時間を設定します。

## 予約締切日

予約締切日を下記いずれかの中から選択します。前日に設定する場合は、締切時間も指定することが可能です。ただし、ツリトリ上での予約受付時間は、現時点で 21:00 までとなっておりますので、その範囲内で設定していただく必要があります。

- 前日まで(締切時間も設定可)
- 2 日前まで
- 3 日前まで
- 4 日前まで
- 7 日前まで

関連記事: [予約制限について](${articleUrl('1', '4')})

## 予約可能期間

どくらい先の予約までを受け付けるのかを下記いずれかの中から選択します。

- 期間は特に設けない（何ヶ月先でも予約可能）
- 12 ヶ月先まで
- 9 ヶ月先まで
- 6 ヶ月先まで
- 3 ヶ月先まで
- 2 ヶ月先まで
- 1 ヶ月先まで

関連記事: [予約制限について](${articleUrl('1', '4')})

## キャンセルポリシー

キャンセルポリシーを下記のいずれかの中から選択します。キャンセルポリシーによって、ゲストがキャンセルした場合のキャンセル料の計算が行われます。詳しくは「[キャンセルポリシーについて](${articleUrl(
      '6',
      '2'
    )})」をご覧ください。

- 全額返金
- 緩め
- 普通
- 厳しめ
- 返金不可

## 料金

料金設定について詳しくは「[料金設定について](${articleUrl(
      '5',
      '5'
    )})」をご覧ください。

`,
  },
  {
    id: '2',
    sectionId: '5',
    title: '釣りプランを更新する',
    body: `
# 釣りプランを更新する

ツリトリ上で表示している釣りプランを更新する手順を下記でご説明します。

※ 更新以前に該当プランでの予約がある場合は、ゲストが予約リクエストした時点でのプラン内容が適用されます（システム上で保存されています）

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部の「プラン設定」アイコンをクリックして、釣りプラン画面に移動します（スマホの場合は、画面上部のメニューアイコンをタップして表示されたメニューから、「プラン設定」をタップします。）

3.釣りプラン画面で表示されている釣りプラン一覧より、更新したいプランをクリックするとプラン編集画面に移動します

4.プラン編集画面より、更新したい項目を編集します。
   それぞれの設定項目について詳しくは、「[釣りプランの設定項目](${articleUrl(
     '5',
     '1'
   )})」をご覧ください。

5.編集が完了したら、フォームの一番下にある「更新する」ボタンをクリックすることで、プランの更新が完了します

`,
  },
  {
    id: '3',
    sectionId: '5',
    title: '釣りプランを削除する',
    body: `
# 釣りプランを削除する

ツリトリ上で表示している釣りプランを削除する手順を下記でご説明します。

※ ツリトリ上で公開している釣りプランを非公開設定することで、ツリトリ上で表示されないようにすることも可能です
※ 一度削除したプランは元に戻すことができませんので、該当プランを本当に削除する必要があるか、ご一考をお願いいたします
※ 削除以前に該当プランでの予約がある場合は、ゲストが予約リクエストした時点でのプラン内容が適用されます（システム上で保存されています）

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部の「プラン設定」をクリックして、釣りプラン画面に移動します（スマホの場合は、画面上部のメニューアイコンをタップして表示されたメニューから、「プラン設定」をタップします。）

3.釣りプラン画面で表示されている釣りプラン一覧より、削除したいプランをクリックするとプラン編集画面に移動します

4.プラン編集画面のフォーム一番下にある「このプランを削除する」ボタンをクリックすることで、プランが削除されます。

`,
  },
  {
    id: '4',
    sectionId: '5',
    title: '釣りプランを追加する',
    body: `
# 釣りプランを追加する

ツリトリ上で表示する釣りプランを追加する手順を下記でご説明します。ツリトリでは、登録できる釣りプランの数に上限はありませんので、開催可能な釣りプランは全て登録することをお勧めします。

1.guide.tsuritori.jp/login よりガイド管理画面にログインします
   ログインについて詳しくは、「[ログイン方法](${articleUrl(
     '4',
     '3'
   )})」をご覧ください。

2.ログインが完了したら、画面上部の「プラン設定」をクリックして、釣りプラン画面に移動します（スマホの場合は、画面上部のメニューアイコンをタップして表示されたメニューから、「プラン設定」をタップします。）

3.釣りプラン画面の右上にある「+プランを登録」ボタンをクリックして、プラン登録画面に移動します

4.プラン登録画面で各項目を入力します
   それぞれの設定項目について詳しくは、「[釣りプランの設定項目](${articleUrl(
     '5',
     '1'
   )})」をご覧ください。

5.各項目を全て入力したら、フォームの一番下にある「登録する」ボタンをクリックすることで、プランの登録が完了します

`,
  },
  {
    id: '5',
    sectionId: '5',
    title: '料金設定について',
    body: `
# 料金設定について

料金は全て税込価格で設定してください。また、釣りプランを設定する際に「紹介文」には、料金に含まれるモノ及びサービスを明記するようにしましょう。

関連記事: [釣りプランの設定項目](${articleUrl('5', '1')})

## 制限事項

釣り体験では設定できる料金には、最低料金及び最高料金の制限があります。

### 最低料金

50円

### 最高金額

50万円

## 乗船タイプごとの料金設定

料金は「乗船タイプ」によって設定方法が異なります。

### 乗合の場合

一人当たりの乗船料を設定します。乗船料は、男性、女性、子ども（12 歳未満）でそれぞれ異なる料金を設定することも可能です。また、男性料金と女性料金のみを設定した場合、12歳未満の子どもは女性料金として料金が計算されます。

### 仕立ての場合

出船可能となる最低料金と最低料金の場合に乗船可能な人数の幅を設定します。また、設定した人数の幅を超えた場合に、一人追加ごとに加算する料金を設定することも可能です。一人追加ごとに加算される料金を設定しない場合は0円として設定されることにご注意ください。

例)
最低出船料金: 50,000 円
最低料金時の人数範囲: 1~5 名
人数範囲を一人超えるごとに: +10,000 円

※ 現時点では仕立ての料金設定で、男性、女性、子どもなど、属性ごとに料金を細かく設定することはできません

`,
  },
  {
    id: '1',
    sectionId: '6',
    title: 'ガイドの予約キャンセルについて',
    body: `
# ガイドの予約キャンセルについて

【重要】
※ 電話でのキャンセルにつきましては原則受け付けることができませんので、必ずメール又はファックスでご連絡ください。
※ キャンセルを依頼される場合は必ず、予約日の当日中にご連絡ください。キャンセル手続きを行われない場合、ゲストへの返金処理がされません。

悪天候や緊急事態宣言などの[酌量すべき事情](${articleUrl(
      '6',
      '4'
    )})に該当する場合は、開催が確定している予約を原則ガイドがキャンセルする必要があります。なお[酌量すべき事情](${articleUrl(
      '6',
      '4'
    )})に該当又は、ガイドの都合によるキャンセルの場合は、釣り体験料金としてゲストからお預かりしていた代金は全額返金されます。

関連記事: 酌量すべき事情について

## キャンセル方法

ガイドが予約をキャンセルするには、下記の情報をご記載の上、ツリトリ運営チームへメール又はファックスのいずれかでご連絡ください。

- 船宿名
- 日付
- キャンセルする釣りプラン※1
- ゲスト氏名※2
- キャンセル理由

※1 特定の釣りプランでの予約を全てキャンセルする場合はご入力ください。
※2 特定のゲストグループをキャンセルする場合はご入力ください。

ツリトリ船宿専用窓口: tsuritori@yamameinc.jp
ファックス: 045-330-4360

※ 電話でのキャンセルにつきましては、原則受け付けることができません

## ペナルティが発生するキャンセルについて

ガイドの都合によるキャンセルによって、ツリトリのプラットフォームの信用に影響が出るような事象が複数回発生した場合には、ガイドに対してキャンセルペナルティが発生する可能性があります。より具体的には[酌量すべき事情](${articleUrl(
      '6',
      '4'
    )})に該当する場合の除いて、年に 4 回以上ガイドからのキャンセルが発生した場合は、ペナルティの対象として、次回の振込金額から 5000 円が差し引かれるものとします。

`,
  },
  {
    id: '2',
    sectionId: '6',
    title: 'キャンセルポリシーについて',
    body: `
# キャンセルポリシーについて

予約確定後にゲストの都合によりキャンセルがされる場合には、ガイドが釣りプランで設定したキャンセルポリシーに基づいて返金対応がされます。下記でそれぞれのキャンセルポリシーについて説明していきます。

関連記事: [釣りプランの設定項目](${articleUrl('5', '1')})

## 全額返金

${cancellationPolicies('full-refund')}

## 緩め

${cancellationPolicies('flexible')}

## 普通

${cancellationPolicies('moderate')}

## 厳しめ

${cancellationPolicies('strict')}

## 返金不可

${cancellationPolicies('no-refund')}

`,
  },
  {
    id: '3',
    sectionId: '6',
    title: 'ゲストがキャンセルした場合',
    body: `
# ゲストがキャンセルした場合

ゲストがツリトリのシステムで予約をキャンセルすると、キャンセルされた予約情報は、ガイドが登録しているメールアドレス又はファックスに通知されます。

## キャンセル料

[酌量すべき事情](${articleUrl(
      '6',
      '4'
    )})の場合を除いて、キャンセル料は当該釣りプランに設定されているキャンセルポリシーに基づいて自動的に計算・支払いが行われます。なお、キャンセル料は売上金と同様の支払いスケジュールでまとめて振り込まれます。

関連記事: [売上金の支払いについて](${articleUrl('3', '6')})

`,
  },
  {
    id: '4',
    sectionId: '6',
    title: '酌量すべき事情について',
    body: `
# 酌量すべき事情について

酌量すべき事情とは、予約確定後に予期せぬ制御不能な事象が発生し、予約を完了することが不可能または違法となる場合の事象を指します。

酌量すべき事情の適用対象となる事象に影響を受けたゲストは、予約のキャンセルをツリトリ運営チームに依頼することで、状況に応じた返金やクーポン、その他の対価を受け取ることができます。酌量すべき事情の適用対象となる事象の影響を受けたガイドは、ペナルティ免除でキャンセルできます。

## 対象となる事象

「事象」という用語は、以下のように予約後に発生した状況、予約時に予期できなかった状況、又は予約の完了を妨げるか法的に禁止する状況を指します。

#### 自然災害

自然災害、天災、大規模にわたるライフライン途絶、火山噴火、津波、その他の激しい異常気象。

#### 緊急事態および流行病の宣言

政府が地域又は国家の緊急事態、流行病、感染病の大流行、及び公衆衛生上の緊急事態を宣言した場合。地域にすでに存在する病気は含まれません。

#### 政府による移動・渡航制限

政府機関による移動・渡航制限のため、開催地への移動が不可能になった場合。これには拘束力のない渡航勧告、或いは政府による同様の指示は含まれません。

#### 軍事活動及びその他の敵対行為

戦争、敵対行為、侵略、内戦、テロ、爆発、爆撃、反乱、暴動、政権奪取、市民暴動、内乱。

## 適用対象とならない場合

酌量すべき事情のもとでキャンセルできるのは、上記のいずれかの事象に当てはまる場合のみです。その他はすべて適用対象外となります。酌量すべき事情の適用対象外となる状況には以下が含まれます。予期せぬ疾病、病気、または怪我。陪審義務、出廷、軍隊派遣を含む政府が定める義務。移動・渡航に関する勧告など政府の指示（渡航禁止令に該当しないもの）。イベント参加のための予約におけるイベントのキャンセルまたは日程変更。適用対象となる事象（道路の通行止め、飛行機・列車・バス・フェリーのキャンセルなど）に関連しない交通の乱れ。このような状況下でゲストが予約をキャンセルする場合、返金額は当該予約に適用されるキャンセルポリシーによって決まります。また、同様の状況下でガイドが予約をキャンセルする場合にはペナルティのカウント対象となります。

関連記事: [ガイドの予約キャンセルについて](${articleUrl('6', '1')})

## 確認後のお手続き

酌量すべき事情が適用される予約のキャンセルは、原則ガイド側が行う必要がありますが、万が一酌量すべき事情に適用されているにもかかわらずガイドが手続きを行っていない場合は、ゲストがツリトリ運営チームに直接キャンセルを依頼することもできます。


`,
  },
  {
    id: '1',
    sectionId: '7',
    title: 'カレンダー情報を更新する',
    body: `
# カレンダー情報を更新する

【重要】※ 情報の齟齬をなくすために、電話又はそれ以外での受付は致しかねますので予めご了承ください

ツリトリで表示されるガイドのカレンダー情報を更新の依頼は以下のいずれかでお願いいたします

- メール
- ファックス

## 必要事項

カレンダーの更新の依頼をされる際には、本文に下記の情報をご入力いただくようお願いいたします。

- 船宿名
- 担当者名
- メールアドレス
- 更新したい日付（複数可）
- 更新内容
  - 休業日、割引適用日など

※ 割引を適用する場合は、「**割引プラン**」、「**割引率**」、「**割引枠**」もご入力ください。
※ 更新は通常1営業日以内に行われますが、状況によっては1営業日以内に対応できない可能性もございますので、予めご了承ください。

## 連絡先

ツリトリ船宿専用窓口: tsuritori@yamameinc.jp
ファックス: 045-330-4360

`,
  },
  {
    id: '2',
    sectionId: '7',
    title: '割引を適用する',
    body: `
# 割引を適用する

ツリトリでは、釣りプランに対して割引を適用することが可能です。割引を適用するには、下記の情報をご準備いただいた上で、[カレンダー情報の更新](${articleUrl(
      '7',
      '1'
    )})手順に従って申請していただく必要があります。基本的に割引は日毎に設定する必要があり、特定のプランを全て割引するというケースには現在対応していませんので、予めご了承ください。

- 割引を適用するプランのID
- 割引を適用する日付
- 割引率
- 割引枠

関連記事: [カレンダー情報を更新する](${articleUrl('7', '1')})

`,
  },
  {
    id: '1',
    sectionId: '8',
    title: 'メールなどが届かない場合',
    body: `
# メールなどが届かない場合

迷惑メールに振り分けられている可能性がございますので、一度迷惑メールフォルダで「@yamameinc.jp」ドメインからのメールをご確認ください。

また、受信側のフィルタ設定による可能性が高いので、以下をご確認ください。

- パソコンからのメールをまとめて拒否していないか
- URL 付きのメールをまとめて拒否していないか

## Gmail の場合

Gmail 受信トレイ内にある、プロモーションタブへ自動で振り分けられている可能性がございます。
プロモーションタブの確認、また合わせて迷惑メールフォルダもご確認ください。

## softbank の場合

@i.softbank.jp をお使いの場合、E メール（i）の迷惑メール設定によって、

受信ができない場合があります。

迷惑メール設定の確認や解除をお試しください。

キャリアごとの設定方法は、下記公式ホームページをご確認下さい

**au:**
https://www.au.com/support/service/mobile/trouble/mail/email/filter/recommend/auto/

**docomo:**
https://www.nttdocomo.co.jp/mydocomo/settings/

**softbank:**
https://www.softbank.jp/mobile/support/mail/antispam/mms/whiteblack/

上記の項目をご確認いただいたうえ、メールが届かない場合、ツリトリ運営チームへお問い合わせください。

`,
  },
]
