import styled from '@emotion/styled'
import { Stack, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { FC } from 'react'

import HelpSection from '~/components/molecules/helpSection'
import { ReactComponent as HelpLogo } from '~/components/svgs/help.svg'
import { sections } from '~/model/help/helpSection'

const StyledHelpLogo = styled(HelpLogo)({
  ['@media screen and (min-width: 0px) and (max-width: 840px)']: {
    width: '87px',
    height: '74px',
  },
  ['@media screen and (min-width: 841px)']: {
    width: '175px',
    height: '150px',
  },
})

const HelpTop: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 841px)')

  return (
    <Stack
      direction={'column'}
      maxWidth={isDesktop ? '700px' : '100%'}
      padding={isDesktop ? '32px 0 60px 0' : '24px 24px 50px 24px'}
      margin="0 auto"
      alignItems={'center'}
      spacing={isDesktop ? 3 : 2}
    >
      <StyledHelpLogo />
      <Stack direction={'column'} spacing={1.5}>
        <Typography variant="h1" align="center">
          ガイド向けヘルプセンター
        </Typography>
        <Typography
          variant={isDesktop ? 'subtitle2' : 'h6'}
          color="secondary.main"
          component="p"
        >
          このページではガイド向けのトピックを掲載しております。ゲスト向けのトピックを確認したい場合は、ゲスト向けヘルプセンターをご確認ください。
        </Typography>
      </Stack>
      <Box
        width="100%"
        height={'1px'}
        sx={{ backgroundColor: 'border.main' }}
      />
      {sections.map((section) => (
        <HelpSection {...section} />
      ))}
    </Stack>
  )
}

export default HelpTop
