import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import {
  FirestoreTackle,
  Tackle,
  TackleBase,
  TackleWrite,
} from '~/model/account/tackle'
import { getGuideTacklesPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getTackles = async (uid: string) => {
  const tacklesPath = getGuideTacklesPath(uid)
  const ref = collection(firestore, tacklesPath).withConverter(
    converter<FirestoreTackle>()
  )
  const q = query(ref, limit(100))
  const snaps = await getDocs(q)

  return snaps.docs.map((doc) => {
    const tackle: Tackle = { ...doc.data(), documentId: doc.id }
    return tackle
  })
}

export const getTackle = async (uid: string, tackleId: string) => {
  const tacklesPath = getGuideTacklesPath(uid)
  const ref = doc(firestore, tacklesPath, tackleId).withConverter(
    converter<FirestoreTackle>()
  )
  const snap = await getDoc(ref)

  if (snap.exists()) {
    const tackle: Tackle = { ...snap.data(), documentId: snap.id }
    return tackle
  } else {
    throw new Error('Document not found.')
  }
}

export const addTackle = async (uid: string, data: TackleBase) => {
  const tacklePath = getGuideTacklesPath(uid)
  const ref = doc(collection(firestore, tacklePath)).withConverter(
    converter<FirestoreTackle>()
  )

  const writeData: TackleWrite = {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  await setDoc(ref, writeData)
}

export const updateTackle = async (
  uid: string,
  tackleId: string,
  data: TackleBase
) => {
  const tacklesPath = getGuideTacklesPath(uid)
  const ref = doc(firestore, tacklesPath, tackleId).withConverter(
    converter<FirestoreTackle>()
  )

  const writeData = { ...data, updatedAt: serverTimestamp() }

  await setDoc(ref, writeData, { merge: true })
}
