import { FirebaseApp, getApps, initializeApp } from 'firebase/app'

import config from '~/config/firebase-env-config'

let app: FirebaseApp
if (getApps().length === 0) {
  app = initializeApp(config)
} else {
  app = getApps()[0]
}

export default app
