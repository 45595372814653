import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import SectionGuestContactMethod from '~/components/molecules/contactMethodSections/sectionGuestContactMethod'
import SectionReservationAddress from '~/components/molecules/contactMethodSections/sectionReservationAddress'
import SectionReservationPhone from '~/components/molecules/contactMethodSections/sectionReservationPhone'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { Guide } from '~/model/account/guide'
import { getGuide } from '~/repositories/guideRepository'

const AccountContactMethodBody: FC = () => {
  const [editMode, setEditMode] = useState<
    'phone' | 'address' | 'guest_contact'
  >()
  const isDesktop = useMediaQuery('(min-width: 841px)')
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [guide, setGuide] = useState<Guide>()

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const guide = await getGuide(currentUser.user.uid)
          setGuide(guide)
        } catch (e) {
          const message =
            e instanceof Error ? e.message : '情報の取得に失敗しました。'
          toggleAlert(true, 'error', message)
        }
      }
      complete()
    }
  }, [])

  if (guide) {
    return (
      <Stack
        flex={1}
        spacing={isDesktop ? '24px' : '16px'}
        divider={<Divider />}
      >
        <SectionReservationPhone
          phone={guide.phone}
          editing={editMode === 'phone'}
          disabled={editMode && editMode !== 'phone'}
          onClickMode={() =>
            setEditMode(editMode === 'phone' ? undefined : 'phone')
          }
          onUpdated={(newPhone) => {
            setEditMode(undefined)
            setGuide({ ...guide, phone: newPhone })
          }}
        />
        <SectionReservationAddress
          destinationType={guide.destinationType}
          email={guide.email}
          fax={guide.fax}
          editing={editMode === 'address'}
          disabled={editMode && editMode !== 'address'}
          onClickMode={() =>
            setEditMode(editMode === 'address' ? undefined : 'address')
          }
          onUpdated={(newDestinationType, newFax) => {
            setEditMode(undefined)
            setGuide({
              ...guide,
              destinationType: newDestinationType,
              fax: newFax,
            })
          }}
        />
        <SectionGuestContactMethod
          contactMethodList={guide.guestContactMethodList}
          editing={editMode === 'guest_contact'}
          disabled={editMode && editMode !== 'guest_contact'}
          onClickMode={() =>
            setEditMode(
              editMode === 'guest_contact' ? undefined : 'guest_contact'
            )
          }
          onUpdated={(newGuestContactMethodList) => {
            setEditMode(undefined)
            setGuide({
              ...guide,
              guestContactMethodList: newGuestContactMethodList,
            })
          }}
        />
      </Stack>
    )
  } else {
    return (
      <Typography
        variant="subtitle1"
        component="span"
        color="secondary.main"
        flex={1}
        textAlign="center"
      >
        このアカウントは現在準備中です
      </Typography>
    )
  }
}

export default AccountContactMethodBody
