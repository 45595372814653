import { Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  type: 'shared' | 'charter'
}

const BordingTypeLabel: FC<Props> = ({ type }) => {
  return (
    <Typography
      variant="h6"
      fontWeight={700}
      color="white"
      textAlign="center"
      component="span"
      borderRadius="24px"
      sx={{
        width: '72px',
        height: '24px',
        backgroundColor: type === 'shared' ? 'sky.main' : 'pink.main',
        lineHeight: '24px',
      }}
    >
      {type === 'shared' ? '乗合い' : '仕立て'}
    </Typography>
  )
}

export default BordingTypeLabel
