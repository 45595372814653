import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { Tackle } from '~/model/account/tackle'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  title: string
  guideTackles: Tackle[]
}

type Props = UseFormReturn<PlanBase> & OwnProps

const SelectTackleSection: FC<Props> = ({
  setValue,
  getValues,
  title,
  guideTackles,
}) => {
  const [selectedIdList, setSelectedIdList] = useState<string[]>(
    getValues('tackleList')
  )

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const documentId = event.target.name
    checked
      ? setSelectedIdList([...selectedIdList, documentId])
      : setSelectedIdList(selectedIdList.filter((id) => id !== documentId))
  }

  useEffect(() => {
    setValue('tackleList', selectedIdList)
  }, [selectedIdList])

  return (
    <SectionContainer title={title}>
      <FormGroup>
        {guideTackles.map((tackle) => (
          <FormControlLabel
            control={
              <Checkbox
                name={tackle.documentId}
                checked={selectedIdList.includes(tackle.documentId)}
                onChange={handleChange}
                color="success"
              />
            }
            label={
              <Typography
                variant="subtitle2"
                color="secondary.main"
                component="span"
              >
                {tackle.title}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </SectionContainer>
  )
}

export default SelectTackleSection
