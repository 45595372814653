import { TextField } from '@mui/material'
import { FC } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import SectionContainer from '~/components/atoms/sectionContainer'
import { PlanBase } from '~/model/plan/plan'

interface OwnProps {
  type: 'introduction' | 'notes'
  title: string
  placeholder?: string
}

type Props = UseFormReturn<PlanBase> & OwnProps

const InputMultilineTextSection: FC<Props> = ({
  type,
  title,
  placeholder,
  register,
  control,
  formState: { errors },
}) => {
  const introductionReg = register('introduction', { maxLength: 5000 })
  const notesReg = register('notes', { maxLength: 5000 })

  return (
    <SectionContainer title={title}>
      {type === 'introduction' && (
        <Controller
          name="introduction"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              {...{ error: Boolean(errors.introduction) }}
              {...introductionReg}
              inputRef={introductionReg.ref}
              variant="outlined"
              multiline={true}
              rows={5}
              sx={{
                ['& fieldset']: { borderRadius: '8px' },
                width: '100%',
              }}
              placeholder={placeholder}
            />
          )}
        />
      )}
      {type === 'notes' && (
        <Controller
          name="notes"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              {...{ error: Boolean(errors.notes) }}
              {...notesReg}
              inputRef={notesReg.ref}
              variant="outlined"
              multiline={true}
              rows={5}
              sx={{
                ['& fieldset']: { borderRadius: '8px' },
                width: '100%',
              }}
              placeholder={placeholder}
            />
          )}
        />
      )}
    </SectionContainer>
  )
}

export default InputMultilineTextSection
