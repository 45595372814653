import {
  collection,
  deleteField,
  doc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'

import { Access, AccessBase, FirestoreAccess } from '~/model/account/access'
import { CollectionPath } from '~/model/path'
import firestore from '~/utils/firestore'
import { converter } from '~/utils/firestoreConverter'

export const getAccess = async (uid: string) => {
  const ref = collection(firestore, CollectionPath.Accesses).withConverter(
    converter<FirestoreAccess>()
  )
  const snapshot = await getDocs(
    query(ref, where('guideUid', '==', uid), limit(1))
  )
  const doc = snapshot.docs[0]
  if (doc && doc.exists()) {
    const access: Access = { ...doc.data(), documentId: doc.id }
    return access
  } else {
    throw new Error('Document not found.')
  }
}

export const updateAccess = async (documentId: string, access: Access) => {
  const ref = doc(firestore, CollectionPath.Accesses, documentId).withConverter(
    converter<FirestoreAccess>()
  )

  const newAccess: AccessBase = {
    type: access.type,
    guideUid: access.guideUid,
    postalCode: access.postalCode,
    prefecture: access.prefecture,
    city: access.city,
    block: access.block,
    building: access.building,
    location: access.location,
    firstParking: access.firstParking,
    secondParking: access.secondParking,
    thirdParking: access.thirdParking,
    directionsByCar: access.directionsByCar,
    directionsByTrain: access.directionsByTrain,
  }

  await updateDoc(ref, {
    ...newAccess,
    updatedAt: serverTimestamp(),
  })
}

export const deleteOptionalParking = async (
  documentId: string,
  type: 'second_parking' | 'third_parking'
) => {
  const ref = doc(firestore, CollectionPath.Accesses, documentId).withConverter(
    converter<FirestoreAccess>()
  )

  if (type === 'second_parking') {
    await updateDoc(ref, {
      secondParking: deleteField(),
      updatedAt: serverTimestamp(),
    })
  }
  if (type === 'third_parking') {
    await updateDoc(ref, {
      thirdParking: deleteField(),
      updatedAt: serverTimestamp(),
    })
  }
}

export const deleteDirectionsByTrain = async (documentId: string) => {
  const ref = doc(firestore, CollectionPath.Accesses, documentId).withConverter(
    converter<FirestoreAccess>()
  )
  await updateDoc(ref, {
    directionsByTrain: deleteField(),
    updatedAt: serverTimestamp(),
  })
}
