import { Divider, Stack } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import SectionDisplayBankAccount from '~/components/molecules/bankAccountSections/sectionDisplayBankAccount'
import SectionInputBankAccount from '~/components/molecules/bankAccountSections/sectionInputBankAccount'
import SectionSelectBankAccountType from '~/components/molecules/bankAccountSections/sectionSelectBankAccountType'
import EditableSectionContainer from '~/components/molecules/editableSectionContainer'
import { useAlertContext } from '~/components/providers/alert'
import { useAuthContext } from '~/components/providers/auth'
import { BankAccountBase } from '~/model/bankAccount/bankAccount'
import {
  addBankAccount,
  getBankAccount,
  updateBankAccount,
} from '~/repositories/bankAccountRepository'

const AccountBankBody: FC = () => {
  const form = useForm<BankAccountBase>()
  const { currentUser } = useAuthContext()
  const { toggleAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [bankAccount, setBankAccount] = useState<BankAccountBase>()
  const [initialized, setInitialized] = useState(false)

  const exists = useMemo(() => Boolean(bankAccount), [bankAccount])

  const bankTitle = '銀行口座'
  const bankBranchTitle = '支店名 / 店名'
  const accountTypeTitle = '預金種目'
  const accountNumberTitle = '口座番号'
  const accountHolderTitle = '口座名義'

  useEffect(() => {
    if (currentUser) {
      const complete = async () => {
        try {
          const bankAccount = await getBankAccount(currentUser.user.uid)
          setBankAccount(bankAccount)
        } catch (e) {
          toggleAlert(true, 'error', e)
        }
        setInitialized(true)
      }
      complete()
    }
  }, [])

  const resetForm = useCallback(() => {
    if (bankAccount) {
      form.setValue('bankName', bankAccount.bankName)
      form.setValue('bankBranchName', bankAccount.bankBranchName)
      form.setValue('accountType', bankAccount.accountType)
      form.setValue('accountNumber', bankAccount.accountNumber)
      form.setValue('accountHolderName', bankAccount.accountHolderName)
    } else {
      form.reset()
    }
  }, [bankAccount])

  const onSubmit: SubmitHandler<BankAccountBase> = async (data) => {
    if (!currentUser) return
    setIsLoading(true)
    try {
      exists
        ? await updateBankAccount(currentUser.user.uid, data)
        : await addBankAccount(currentUser.user.uid, data)

      setBankAccount((prev) => (prev ? { ...prev, ...data } : data))
      setEditing(false)
      toggleAlert(true, 'success', '口座情報を更新しました！')
    } catch (e) {
      toggleAlert(true, 'error', e)
    }
    setIsLoading(false)
  }

  if (initialized) {
    return (
      <Stack flex={1} component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <EditableSectionContainer
          title="口座情報"
          editing={editing}
          loading={isLoading}
          onClickMode={() => {
            resetForm()
            setEditing(!editing)
          }}
        >
          {!editing && bankAccount && (
            <Stack spacing={2} divider={<Divider />}>
              <SectionDisplayBankAccount
                title={bankTitle}
                value={bankAccount.bankName}
              />
              <SectionDisplayBankAccount
                title={bankBranchTitle}
                value={bankAccount.bankBranchName}
              />
              <SectionDisplayBankAccount
                title={accountTypeTitle}
                value={
                  bankAccount.accountType === 'saving' ? '普通預金' : '当座預金'
                }
              />
              <SectionDisplayBankAccount
                title={accountNumberTitle}
                value={bankAccount.accountNumber}
              />
              <SectionDisplayBankAccount
                title={accountHolderTitle}
                value={bankAccount.accountHolderName}
              />
            </Stack>
          )}
          {editing && (
            <Stack spacing={2} divider={<Divider />}>
              <SectionInputBankAccount
                {...form}
                type="bankName"
                title={bankTitle}
                placeholder="〇〇銀行"
              />
              <SectionInputBankAccount
                {...form}
                type="bankBranchName"
                title={bankBranchTitle}
                placeholder="〇〇支店"
              />
              <SectionSelectBankAccountType
                {...form}
                title="預金種目"
                bankAccountType="saving"
              />
              <SectionInputBankAccount
                {...form}
                type="accountNumber"
                title={accountNumberTitle}
                placeholder="1234567"
              />
              <SectionInputBankAccount
                {...form}
                type="accountHolderName"
                title={accountHolderTitle}
                placeholder="ヤマメ タロウ"
              />
            </Stack>
          )}
        </EditableSectionContainer>
      </Stack>
    )
  } else {
    return <></>
  }
}

export default AccountBankBody
