export const BOAT_EQUIPMENT_TYPE = {
  toilet: 'toilet',
  restRoom: 'rest_room',
  airConditioner: 'air_conditioner',
  microwave: 'microwave',
  electricKettle: 'electric_kettle',
  refrigerator: 'refrigerator',
  freezer: 'freezer',
  powerSupply: 'power_supply',
  liveBox: 'live_box',
} as const

export type BoatEquipmentType =
  typeof BOAT_EQUIPMENT_TYPE[keyof typeof BOAT_EQUIPMENT_TYPE]

export const equipmentName = (type: BoatEquipmentType): string => {
  switch (type) {
    case 'toilet':
      return 'トイレ'
    case 'rest_room':
      return '休憩室'
    case 'air_conditioner':
      return 'エアコン'
    case 'microwave':
      return '電子レンジ'
    case 'electric_kettle':
      return '電気ポッド'
    case 'refrigerator':
      return '冷蔵庫'
    case 'freezer':
      return '冷凍庫'
    case 'power_supply':
      return '電動リール電源'
    case 'live_box':
      return 'イケス'
  }
}
