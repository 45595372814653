import { Stack, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { Control, useWatch } from 'react-hook-form'

import BasicButton from '~/components/atoms/basicButton'
import InputText from '~/components/atoms/inputText'
import { PlanBase } from '~/model/plan/plan'
import { SharedCategory } from '~/model/plan/shared'

interface OwnProps {
  index: number
  control: Control<PlanBase, any>
  onChangeCategory?: (category: SharedCategory) => void
  onRemoveCategory?: () => void
}

const InputSharedCategoryItem: FC<OwnProps> = ({
  index,
  control,
  onChangeCategory,
  onRemoveCategory,
}) => {
  const value = useWatch({
    control: control,
    name: `shared.categories.${index}`,
  })

  const onChangeName = (newName: string) => {
    const newCategory: SharedCategory = { ...value, name: newName }
    onChangeCategory?.(newCategory)
  }

  const onChangePrice = (newPrice: number) => {
    const newCategory: SharedCategory = { ...value, price: newPrice }
    onChangeCategory?.(newCategory)
  }

  return (
    <Stack spacing={1.5} width="100%">
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`ID${value.id}: 名前`}
        </Typography>
        <InputText
          size="small"
          placeholder="属性"
          value={value.name}
          onChange={(e) => onChangeName(e.target.value)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2" component="span" color="secondary.main">
          {`ID${value.id}: 料金`}
        </Typography>
        <InputText
          size="small"
          placeholder="料金"
          value={value.price}
          onChange={(e) => onChangePrice(parseInt(e.target.value) || 0)}
        />
      </Stack>
      <BasicButton
        title="削除する"
        color="error"
        sx={{ width: '120px', height: '40px' }}
        onClick={onRemoveCategory}
      />
    </Stack>
  )
}

export default InputSharedCategoryItem
